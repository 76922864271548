import React, { useEffect,useContext } from 'react';
import { Divider,Button } from 'antd';
import { LaptopOutlined,CloudServerOutlined,LoadingOutlined,CheckCircleOutlined,CloseCircleOutlined,SignalFilled } from '@ant-design/icons'
import axios from '@/utils/axios';
import { MyContext } from '@/utils/myContext';

const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const [status, setStatus] = React.useState(1);
    const [statusRemack, setStatusRemack] = React.useState('');
    const [msTime, setMsTime] = React.useState(0);
    let timer:any;
    const getConnStatus = () => {
        setStatus(1);
        setStatusRemack(retLang('Diagnosisinprogress') );
        axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
            timer  = setTimeout(() => {
                setStatus(2);
                setStatusRemack(retLang('connectedService'));
                clearTimeout(timer);
            }, 3000);
        }).catch((err:any) => {
            timer  = setTimeout(() => {
                setStatus(3);
                setStatusRemack(retLang('connectedServiceErr'));
                clearTimeout(timer);
            }, 3000);
        })
    }
    const getMsin = () => {
        let msNumber = 0;
        let msTimer = setInterval(() => {msNumber += 1}, 1);
        axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
            clearInterval(msTimer);
            setMsTime(msNumber);
        }).catch((err:any) => {
            clearInterval(msTimer);
            setMsTime(msNumber);
        })
    }
    useEffect(() => {
        getMsin();
        let int = setInterval(() => {
            getMsin();
        }, 5000);
        getConnStatus();
        return () => {
            clearTimeout(int);
        }
    }, [])
    return (
        <div className='diagnosis_box'>
            <div className="setting_list">
                <div className="setting_title">
                    {retLang('Diagnostics')}
                    <Button disabled={status===1} onClick={getConnStatus}>{retLang('Diagnose')}</Button>
                </div>
                <Divider />
                <div className='diagnosis_info'>
                    <div className="connStatus">
                        <LaptopOutlined />
                        <div className="connIco">
                            <Divider orientation="center" className='dividerCss'>
                                {
                                    status === 1 ? <LoadingOutlined /> :
                                    status === 2 ? <CheckCircleOutlined /> :
                                    <CloseCircleOutlined />
                                }
                            </Divider>
                        </div>
                        <CloudServerOutlined />
                    </div>
                    <div className="connStatusTest">
                        {statusRemack}  
                    </div>
                    <div className="delay" style={
                        msTime < 100 ? 
                        {color:'#52c41a'} 
                        : msTime < 200 ?
                        {color:'#faad14'}
                        : {color:'#f5222d'}
                    }><SignalFilled /><span className='ms-time'>{msTime}</span></div>
                </div>
            </div>
        </div>
    )
}

export default Element;