import React, { useState, useContext, useEffect } from 'react';
import { Select, Divider, Checkbox } from 'antd';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import moment from 'moment-timezone';
const opercode:any = operCode;
const { Option } = Select;
const Element  = (props: any) => {
    let {
        language,setLanguage,
        languageJson,setLanguageJson,
        retLang
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.updateLanguage && result.retcode === 200){
            // localStorage.setItem("language",language);
            
        }
    }, [receivedMessages]);
    const [languageList,setLanguageList] = useState([
        {name:'English',value:'en'},
        {name:'Español',value:'es-ES'},
        {name:'বাংলা',value:'bn-BD'},//新增
        {name:'عربى',value:'ar-SA'},
        {name:'Русский',value:'ru-RU'},
        {name:'Português',value:'pt-BR'},//新增
        {name:'日本語',value:'ja-JP'},
        {name:'Deutsch',value:'de-DE'},
        {name:'Français',value:'fr-FR'},//新增
        {name:'हिंदी',value:'hi-IN'}, //新增
        {name:'اردو',value:'ur-PK'}, //新增
        {name:'فارسی',value:'fa-IR'}, //新增
        {name:'Melayu',value:'ms-MY'}, //新增
        {name:'Bahasa Indonesia',value:'id-ID'},
        {name:'Tiếng Việt',value:'vi-VN'},
        {name:'한국어',value:'ko-KR'},
        {name:'Türkçe',value:'tr-TR'},
        {name:'Italiano',value:'it-IT'},
        {name:'แบบไทย',value:'th-TH'}, //新增
        {name:'Nederlands',value:'nl-NL'},
        {name:'中文 (简体)',value:'zh_CN'},
        {name:'中文 (繁體)',value:'zh_TW'},
    ]);
    const [timeZone,setTimeZone] = useState("");
    const [timeZoneList] = useState([
        {"value": "UTC-12:00", "label": "UTC-12:00 International Date Line West"},
        {"value": "UTC-11:00", "label": "UTC-11:00 Saipan Standard Time, American Samoa Standard Time"},
        {"value": "UTC-10:00", "label": "UTC-10:00 Hawaii-Aleutian Standard Time, Cook Islands Time"},
        {"value": "UTC-09:30", "label": "UTC-09:30 Marquesas Islands Time"},
        {"value": "UTC-09:00", "label": "UTC-09:00 Alaska Standard Time, Gambier Islands Time"},
        {"value": "UTC-08:00", "label": "UTC-08:00 Pacific Standard Time, Clipperton Island Time"},
        {"value": "UTC-07:00", "label": "UTC-07:00 Mountain Standard Time"},
        {"value": "UTC-06:00", "label": "UTC-06:00 Central Standard Time, Central America Time"},
        {"value": "UTC-05:00", "label": "UTC-05:00 Eastern Standard Time, Western Caribbean Standard Time"},
        {"value": "UTC-04:30", "label": "UTC-04:30 Venezuelan Standard Time"},
        {"value": "UTC-04:00", "label": "UTC-04:00 Atlantic Standard Time"},
        {"value": "UTC-03:30", "label": "UTC-03:30 Newfoundland Standard Time"},
        {"value": "UTC-03:00", "label": "UTC-03:00 Argentine Standard Time, Brasilia Standard Time"},
        {"value": "UTC-02:00", "label": "UTC-02:00 South Georgia and the South Sandwich Islands Time, Brasilia Island Time"},
        {"value": "UTC-01:00", "label": "UTC-01:00 Azores Time, Cape Verde Standard Time"},
        {"value": "UTC±00:00", "label": "UTC±00:00 Greenwich Mean Time, Western European Time"},
        {"value": "UTC+01:00", "label": "UTC+01:00 Central European Time, West Africa Time"},
        {"value": "UTC+02:00", "label": "UTC+02:00 Eastern European Time, Central Africa Time"},
        {"value": "UTC+03:00", "label": "UTC+03:00 Moscow Standard Time, East Africa Time"},
        {"value": "UTC+03:30", "label": "UTC+03:30 Iran Standard Time"},
        {"value": "UTC+04:00", "label": "UTC+04:00 Azerbaijan Standard Time, Samara Standard Time"},
        {"value": "UTC+04:30", "label": "UTC+04:30 Afghanistan Standard Time"},
        {"value": "UTC+05:00", "label": "UTC+05:00 Pakistan Standard Time, Yekaterinburg Standard Time"},
        {"value": "UTC+05:30", "label": "UTC+05:30 Indian Standard Time, Sri Lanka Time"},
        {"value": "UTC+05:45", "label": "UTC+05:45 Nepal Time"},
        {"value": "UTC+06:00", "label": "UTC+06:00 Bangladesh Standard Time, Bhutan Time, Omsk Standard Time"},
        {"value": "UTC+06:30", "label": "UTC+06:30 Myanmar Time, Cocos Islands Time"},
        {"value": "UTC+07:00", "label": "UTC+07:00 Vietnam Standard Time, Krasnoyarsk Standard Time"},
        {"value": "UTC+08:00", "label": "UTC+08:00 China Standard Time, Western Australian Standard Time, Irkutsk Standard Time"},
        {"value": "UTC+08:45", "label": "UTC+08:45 Australian Central Western Standard Time"},
        {"value": "UTC+09:00", "label": "UTC+09:00 Japan Standard Time, Korea Standard Time, Yakutsk Standard Time"},
        {"value": "UTC+09:30", "label": "UTC+09:30 Australian Central Standard Time"},
        {"value": "UTC+10:00", "label": "UTC+10:00 Australian Eastern Standard Time, Vladivostok Standard Time"},
        {"value": "UTC+10:30", "label": "UTC+10:30 Lord Howe Island Standard Time"},
        {"value": "UTC+11:00", "label": "UTC+11:00 Solomon Islands Time, Magadan Standard Time"},
        {"value": "UTC+11:30", "label": "UTC+11:30 Norfolk Island Time"},
        {"value": "UTC+12:00", "label": "UTC+12:00 New Zealand Standard Time, Fiji Time"},
        {"value": "UTC+12:45", "label": "UTC+12:45 Chatham Islands Standard Time"},
        {"value": "UTC+13:00", "label": "UTC+13:00 Tokelau Time, Phoenix Islands Time"},
        {"value": "UTC+14:00", "label": "UTC+14:00 Line Islands Time"}
    ])
    const [showTimeZone,setShowTimeZone] = useState<any>([]);
    useEffect (() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const offset = moment.tz(timeZone).format('Z');
        setTimeZone('UTC'+offset);
        let local: any = localStorage.getItem("language");
        let localLang: any = local || "en";
        if (localLang && localLang !== 'en') {
            axios.post('/dwapi/get/content/translation/', {
                content_key: "time_zone",
                content_type: "text/list",
                source_language_code: 'en',
                target_language_code: localLang,
                content: timeZoneList
            }).then((res: any) => {
                if (res.data.code === 200) {
                    let arr = [];
                    let data = res.data.data;
                    for (let i = 0; i < data.length; i++) {
                        let obj = data[i].replace(/'/g, '"');
                        arr.push(JSON.parse(obj));
                    }
                    setShowTimeZone([...arr]);
                }
            })
        }else{
            setShowTimeZone([...timeZoneList]);
        }
    }, [language]);
    const [isAutoTime,setIsAutoTime] = useState(true);
    const languageSelect:any = languageList.map((item:any,index:any) => {
        return (
            <Option value={item.value} key={index}>{item.name}</Option>
        )
    })
    const timeZoneSelect:any = showTimeZone.map((item:any,index:any) => {
        return (
            <Option value={item.value} key={index}>{item.label}</Option>
        )
    })
    const changeLanguage = (value:any) => {
        localStorage.setItem("language",value);
        setLanguage(value);
        let sendValue;
        if(value === "zh_CN"){
            sendValue = "zh-CN";
        }else if(value === "zh_TW"){
            sendValue = "zh-TW";
        }else if(value === "en"){
            sendValue = "en-US";
        }else{
            sendValue = value;
        }
        sendJsonMessage({"operate":"updateLanguage","opercode":opercode.updateLanguage,"language":sendValue});
        // window.location.reload();
    }
    const changeTimeZone = (value:any) => {
        setTimeZone(value);
    }
    const onAutoTime = (e:any) => {
        setIsAutoTime(e.target.checked);
    }
    return (
        <div className='language_box'>
            {/* <div className="setting_title">{retLang('LanguageRegion')}</div>
            <Divider /> */}
            <div className="content_item">
                <div className="content_title">{retLang('Language')}</div>
                <Select value={language} onChange={changeLanguage} size='large' style={{ width: '100%' }}>
                    {languageSelect}
                </Select> 
                <p>{retLang('setLanguageRem')}</p>
            </div> 
            <div className="content_item">
                <div className="content_title">{retLang('TimeZone')}</div>
                <Checkbox checked={isAutoTime} className='autoTime' onChange={onAutoTime}>Automatic time zone setting</Checkbox>
                <Select disabled={isAutoTime} value={timeZone} onChange={changeTimeZone} size='large' style={{ width: '100%' }}>
                    {timeZoneSelect}
                </Select> 
                <p>{retLang('setTimeZoneRem')}</p>
            </div> 
        </div>
    )
}

export default Element;