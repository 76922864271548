import React, { useCallback, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import deviceConnect from '@/assets/deviceImg/images_connect_devices.png';
import "./deviceConnection.scss"
import tipIco from "@/assets/icon/icon_tips.svg";
import { MyContext } from '@/utils/myContext';
import { ReadyState } from 'react-use-websocket';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"

const opercode:any = operCode;

const Element = () => {
    const {retLang} = useContext<any>(MyContext);
    const navigate = useNavigate();

    const {receivedMessages,sendJsonMessage,readyState} = useCustomWebSocket();
    useEffect (() => {
        if(readyState === ReadyState.OPEN){
            sendJsonMessage({"operate":"ConnectionStatus","opercode":opercode.ConnectionStatus});
        }
    }, [readyState]);
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.ConnectionStatus && result.retcode !== 201){
            navigate('/dashboard/ConnectStatus',{state:{connectStatus: result.retcode}});
        }
    }, [receivedMessages,navigate]);
    const goFunction = useCallback(() => {
        navigate('/dashboard/index');
        // navigate('/dashboard/ConnectStatus',{state:{connectStatus: '200'}});
    },[navigate]);
    return (
        <div className='deviceConnection'>
            <img className='connectImg' src={deviceConnect} alt="" />
            <p className='connectTip'>
                <img src={tipIco} alt="" />
                <span>{retLang('connectDevice')} </span>
            </p>
            <div className="oper-btn">
                <Button type='primary' onClick={goFunction}>{retLang('Dashboard')}</Button>
            </div>
        </div>
    )
}

export default Element;