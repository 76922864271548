import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import account_btn from '@/assets/loginIco/button_account_login.svg'
import facebook_btn from '@/assets/loginIco/button_facebook_login.svg'
import email_btn from '@/assets/loginIco/button_email_login.svg'
import useFacebookLogin from './useFacebookLogin';
import google_btn from '@/assets/loginIco/button_google_login.svg'

import useCustomWebSocket from '@/utils/websocket';
import { MyContext } from '@/utils/myContext';
import "./loginType.scss"
declare let google: any;

const LoginType = () => {
    const {
        retLang,domain,ipInfo
    } = useContext(MyContext);
    const {sendJsonMessage} = useCustomWebSocket();
    // const { status, authResponse, login } = useFacebookLogin('782171669510509');
    const navigate = useNavigate();
    useEffect(() => {
        sendJsonMessage({"operate":"GetComputerHardWare","opercode":999});
    },[])

    const googleClient = () => {
        google.accounts.id.initialize({
            client_id: '144140810799-jo6n90fb97b2iecvd8db0sn7q3otsb33.apps.googleusercontent.com',
            callback: handleCredentialResponse
        });
        google.accounts.id.prompt();
    }
    const handleCredentialResponse = (response:any): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            // response.credential;
            // 在这里，你可以把 credential 发送到你的后端服务器进行验证
            // 如果验证成功，你应该调用 resolve()。如果出现错误，你应该调用 reject()
            resolve();
          });
    };
    const facebookClient = () => {
        
    }
    const goAccount = () => {
        navigate('/login/loginAccount');
    }
    const goEmail = () => {
        navigate('/login/loginEmail');
    }

    useEffect(() => {
        
    }, [])
    return (
        <div>
            <div className="loginType" style={{height:"55px"}}>
                <div className="type-item" onClick={goAccount}><img src={account_btn} alt="" /></div>
                <div className="type-item" onClick={goEmail}><img src={email_btn} alt="" /></div>
                {/* <div className="type-item" onClick={googleClient}> <img src={google_btn} alt="" /> </div> */}
                {/* <div className="type-item" onClick={false?facebookClient:login}><img src={facebook_btn} alt="" /></div> */}
            </div>
            <p className="remark">{retLang('selectLoginType')} </p>
            <p className="remark" style={{fontSize:'14px',height:"20px"}}>
                <a target='_blank' href={`https://account.${domain}/user-register`}>
                    {(ipInfo && ipInfo?.country!=="CN" && ipInfo?.type!=="hosting")?retLang('Signupbetaaccount'):retLang('SignUp')}
                </a>
            </p>
        </div>
    )
}

export default LoginType;