import React, { useEffect, useContext } from 'react';
import "./tutorials.scss";
import { MyContext } from '@/utils/myContext';
import { Skeleton } from 'antd';
const Element  = (props: any) => {
    const { retLang,domain,language } = useContext(MyContext);
    const [loading,setLoading] = React.useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        },1000)
    },[])
    const filterLanguage = (lan:any) => {
        if(lan === "zh_TW"){
            return "zh-TW"
        }else{
            return lan
        }
    }
    return (
        <div className='tutorialsView'>
            <div className="hide_box" style={{display:loading?"block":"none",width:"100%"}}>
                <div className="loading-tutorials">
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                </div>
            </div>
            <iframe width={"100%"} style={{left:loading?"-9999px":"0",opacity:loading?"0":"1",}} src={`https://www.${domain}/${language?filterLanguage(language):'en'}/tutorial`}></iframe>
        </div>
    )
}

export default Element;