import React, { useState, useContext, useEffect } from 'react';
import { Divider,Input,Progress } from 'antd';
import { FolderOpenFilled,ClearOutlined } from '@ant-design/icons';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
import harddiskIcon from '@/assets/deviceImg/harddisk.png';
const opercode:any = operCode;
const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    const [operateKey,setOperateKey] = useState<any>(null);
    useEffect (() => {
        sendJsonMessage({operate:"checkSoftwarePath",opercode:opercode.checkSoftwarePath});
    }, []);
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.checkSoftwarePath && result.retcode === 200){
            if(result.softwarepath && result.softwarepath.length > 0){
                setPathList(result.softwarepath);
            }else if(result.DiskInfo && result.DiskInfo.length > 0){
                setHarddriveList(result.DiskInfo);
            }
        }
        if(result.opercode === opercode.selectSavePath && result.retcode === 200){
            let pathListTemp = [...pathList];
            pathListTemp.forEach((item:any) => {
                if(item.key === operateKey){
                    item.value = result.operate_info;
                }
            })
            setPathList(pathListTemp);
        }
    }, [receivedMessages]);
    const [pathList,setPathList] = useState([
        // {"name":"Work Path","key":"WorkPath","value":"F:\\makebrowerSelf\\.\\Win32\\Release\\","selected":"0"},
        // {"name":"Cache Path","key":"CachePath","value":"C:\\Users\\susim\\AppData\\Local\\Temp\\","selected":"0"},
        // {"name":"Firmware Path","key":"FirmwarePath","value":"D:\\Firmware\\","selected":"1"}
    ]);
    const [harddriveList,setHarddriveList] = useState([
        // {"Disk":"C","freeSizeGB":"81GB","TotalSizeGB":"255GB"},
        // {"Disk":"E","freeSizeGB":"256GB","TotalSizeGB":"511GB"},
        // {"Disk":"F","freeSizeGB":"1294GB","TotalSizeGB":"1862GB"}
    ]);
    const checkPath = (key:any) => {
        setOperateKey(key);
        sendJsonMessage({operate:key,opercode:opercode.selectSavePath});
    }
    return (
        <div className='fileAddress_box'>
            <div className="setting_title">{retLang('GlobalDefaultPath')}</div>
            <Divider />
            {
                pathList.map((item:any,index) => {
                    return (
                        <div className="content_item" key={index}>
                            <div className="content_title">{item.name}</div>
                            <Input value={item.value} disabled suffix={
                                item.selected==='1'?
                                <FolderOpenFilled onClick={() => checkPath(item.key)} />
                                :item.selected==='2'?<ClearOutlined onClick={() => checkPath(item.key)} />
                                :null
                            }/>
                            <p>{item.tip}</p>
                        </div>
                    )
                })
            }
            <div className="content_item">
                <div className="content_title">{retLang('DiskInformation')}</div>
                <div className="harddrive_list">
                    {
                        harddriveList.map((item:any,index) => {
                            let freeSizeNum = item.freeSizeGB.replace(/[^0-9]/ig,"");
                            let TotalSizeNum = item.TotalSizeGB.replace(/[^0-9]/ig,"");
                            let usedSizeNum = TotalSizeNum - freeSizeNum;
                            let usedSizePercent = parseInt((usedSizeNum/TotalSizeNum*100).toFixed(0));
                            return (
                                <div className="harddrive_item" key={index}>
                                    <div className="harddrive_icon">
                                        <img src={harddiskIcon} alt=""/>
                                    </div>
                                    <div className="harddrive_content">
                                        <div className="harddrive_title">{retLang('Disk')} ({item.Disk})</div>
                                        <Progress 
                                        percent={usedSizePercent}
                                        strokeColor={{'0%': '#529AFF','100%': '#529AFF'}}
                                        size={[200, 16]}
                                        showInfo={false} />
                                        <div className="harddrive_text">{item.freeSizeGB} / {item.TotalSizeGB}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Element;