import React, { useContext, useEffect, useState } from 'react';
import { Divider, Button } from 'antd';
import { UserOutlined,CheckCircleFilled } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import './ordersCom.scss';
const Element  = (props: any) => {
    let {
        retLang,
        userInfo,
        filterType,
        language,
        setIsModalOpen,
        setIsShowPurchase
    } = useContext(MyContext);
    
    const [orderList,setOrderList] = useState<any>({});
    const [privilegesList,setPrivilegesList] = useState<any>([]);

    const getOrders = () => {
      axios.post('/client/new/order/userAlreadyPurchasedPackage').then((res:any) => {
        if(res.data.code === 200){
          setOrderList(res.data.data);
        }
      })
    }
    const selectPlan = () => {
      setIsModalOpen(false);
      setIsShowPurchase(true)
    }
    useEffect(() => {
        // getOrders();
        if(userInfo.userAttr === 1 || userInfo.userAttr === 2 || userInfo.userAttr === 3){
          setPrivilegesList([
            {name:retLang('Unlimitedphonefeatures'),status:1},
            {name:retLang('UnlimitedIMEIlookup'),status:1},
            {name:retLang('ViewTestpoints'),status:1},
            {name:retLang('MobileDetailsView'),status:1},
            {name:retLang('customersupportbylivechat'),status:1},
          ])
        }else{
          setPrivilegesList([
            {name:retLang('Unlimitedphonefeatures'),status:0},
            {name:retLang('FreeIMEIcheckingservice'),status:1},
            {name:retLang('UnlimitedIMEIlookup'),status:0},
            {name:retLang('ViewTestpoints'),status:1},
            {name:retLang('MobileDetailsView'),status:1},
            {name:retLang('customersupportbylivechat'),status:0},
          ])
        }
    },[]);
    return (
        <div className='orders_box'>
            <div className="setting_list">
                <div className="setting_title">{retLang('Plan')}</div>
                <Divider />
                <div className="orderCard">
                    <div className="card_header">
                      <div className='user_data'>
                        <div className="user_avatar"></div>
                        <div className="user_info">
                          <div className="user_plan">{filterType(userInfo.userAttr) } {retLang('Plan')}</div>
                          <div className="user_valid">
                            {
                              userInfo?.expireDateStr?<span>
                                {retLang('Validuntil')} {(language==='zh_CN' || language==='zh_TW')?userInfo.expireDateStr2:userInfo.expireDateStr}
                              </span>:
                              <span>{retLang('Validuntil')} --</span>
                            }
                          </div>
                        </div>
                      </div>
                      <Button className='order_select_plan' onClick={selectPlan}>{retLang('SelectPlan')}</Button>
                    </div>
                    <div className="card_body">
                      <Divider orientation="left" plain>
                        <span style={{color:"#fff"}}>{retLang('Privileges')} </span>
                      </Divider>
                      <div className="privileges_list">
                        {
                          privilegesList.map((item:any,index:number) => {
                            return (
                              <div className="privileges_item" key={index}>
                                <div className={`privileges_icon ${item.status===0 && 'noSupport'}`}><CheckCircleFilled /></div>
                                <div className="privileges_name">{item.name}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                      {
                        userInfo.userAttr === 0 && <div className="shengjiRemark">{retLang('Upgradetopaidplan')}</div>
                      }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Element;