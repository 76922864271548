import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import importIco from "@/assets/icon/button_import.svg";
import refreshIco from "@/assets/icon/button_list_refresh.svg";
import deleteIco from "@/assets/icon/button_delete.svg";
import "./firmwareList.scss"
const Element  = (props: any) => {
  useEffect(() => {
    
  })
  interface DataType {
    key: React.Key;
    model: string;
    name: string;
    systemOnChip: string;
    size: string;
    status: string;
  }
  const columns: ColumnsType<DataType> = [
    {title: 'Model',dataIndex: 'model'},
    {title: 'Name',dataIndex: 'name'},
    {title: 'System-On-Chip',dataIndex: 'systemOnChip',width:180},
    {title: 'Size',dataIndex: 'size'},
    {title: 'Status',dataIndex: 'status'},
  ];
  const data: DataType[] = [
    {
      key:"1",
      model: "Xiaomi Mi 5",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "256GB",
      status: "Downloaded"
    },
    {
      key:"2",
      model: "Xiaomi Mi 6",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "5.98GB",
      status: "Downloaded"
    },
    {
      key:"3",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"4",
      model: "Xiaomi Mi 8",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "8.14GB",
      status: "Downloaded"
    },
    {
      key:"5",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"6",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"7",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"8",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"9",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
    {
      key:"10",
      model: "Xiaomi Mi 7",
      name: "Sagit",
      systemOnChip: "Snapdragon 450",
      size: "6.11GB",
      status: "Downloaded"
    },
  ]
  return (
    <div className='firmwareList'>
      <div className="opertion">
        {/* <Button type="primary" size='small'><img src={importIco} alt="" /> 导入本地固件</Button>
        <Button type="primary" size='small' disabled><img src={deleteIco} alt="" /> 删除</Button>
        <Button type="primary" size='small'><img src={refreshIco} alt="" /> 刷新</Button> */}
      </div>
      <div className="firmwareTable">
        <Table
          scroll={{ y: "calc(100vh - 385px)" }}
          rowSelection={{
            type: "checkbox"
          }}
          pagination={{ position:[]}}
          columns={columns}
          dataSource={data}
        />
      </div>
      <div className="logPanel">
        <p>Welcome susu@gmail.com</p>
        <p>Certificate Valid until:2023-08-10 06:53:18</p>
        <br />
        <p>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^</p>
      </div>
    </div>
  )
}

export default Element;