import React, { useContext,useState } from 'react';
import { Divider,Button,Switch } from 'antd';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
const Element  = (props: any) => {
    let {
        retLang,
        userInfo,
        getUserInfo
    } = useContext(MyContext);
    const [noticeSwitch,setNoticeSwitch] = useState(userInfo.noticeSwitch===1?true:false);
    const [guideSwitch,setGuideSwitch] = useState(userInfo.guideSwitch===1?true:false);
    const onChangeNotice = (checked:boolean) => {
        setNoticeSwitch(checked);
        let noticeSwitch = checked?1:0;
        updateMerchantInfo({noticeSwitch});
    }
    const onChangeLaunch = (checked:boolean) => {
        setGuideSwitch(checked);
        let guideSwitch = checked?1:0;
        updateMerchantInfo({guideSwitch});
    }
    const updateMerchantInfo = (data:any) => {
        axios.post('/client/new/userinfo/updateMerchantInfo',{
            ...data
        }).then((res:any) => {
            if(res.data.code === 200){
                getUserInfo();
            }
        })
    }
    return (
        <div className='notifications_box'>
            <div className="setting_list">
                <div className="setting_title">{retLang('Notifications')}</div>
                <Divider />
                <div className="notifications_item">
                    <div className='notifications_label'>
                    {retLang('AnnouncementNotice')}
                    </div>
                    <Switch checked={noticeSwitch} onChange={onChangeNotice} />
                </div>
                <div className="notifications_item">
                    <div className='notifications_label'>
                        {retLang('LaunchGuide')}
                    </div>
                    <Switch  checked={guideSwitch} onChange={onChangeLaunch} />
                </div>
            </div>
        </div>
    )
}

export default Element;