import React, { useContext } from 'react';
import { Divider } from 'antd';
import { MyContext } from '@/utils/myContext';

import LanguageCom from './generalTemplate/languageCom'
import FilesAddress from './generalTemplate/filesAddress'
const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    return (
        <div className='general_box'>
            <div className="setting_list">
                <div className="setting_title">{retLang('General')}</div>
                <Divider />
                <LanguageCom />
                <FilesAddress />
            </div>
        </div>
    )
}

export default Element;