import React, { useEffect, useRef } from 'react';
import axios from '@/utils/axios';
const Element = (props: any) => {
    const htmlRef = useRef<any>(null);
    useEffect(() => {
        let local: any = localStorage.getItem("language");
        let localLang: any = local || "en";
        if (localLang && localLang !== 'en') {
            if(htmlRef.current){
                axios.post('/dwapi/get/content/translation/', {
                    content_key: "client_privacy_policy",
                    content_type: "html",
                    source_language_code: 'en',
                    target_language_code: localLang,
                    content: htmlRef.current.innerHTML
                }).then((res: any) => {
                    if (res.data.code === 200) {
                        try{
                            htmlRef.current.innerHTML = res.data.data;
                        }catch(e){}
                    }
                })
            }
        }
    }, [])
    return (
        <div ref={htmlRef} className='word_text'>
            <div className="container">
                <div className="block content-block">
                    <h2 className="h1">Privacy policy</h2>
                    <div className="wys">
                        <p>EVONDT PRIVACY POLICY</p>
                        <p><strong>When you use our services, you’re trusting us with your information. We understand this is a big responsibility and will work hard to protect your information and put you in control.</strong></p>
                        <p>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>
                        <p>Effective 14 September 2022</p>
                        <p></p>
                        <h2><strong>A: Commitment and general principles</strong></h2>
                        <p>
                            Oslo Tech Pty Ltd trading as Evondt (ABN 18 641 506 087) (“Evondt” or “We”) undertakes to comply with Australian laws and the highest standard of data protection. We are compliant with the Australian Privacy Principles in the Privacy Act 1988 (Cth) in relation to the handling of personal information. A full version of the Australian Privacy Principles, contained in Schedule 1 of the Privacy Act, can be found at the Office of the Australian Information Commissioner’s website.
                        </p>
                        <p>
                            We do not have access to or control over user data accessible by our applications: files and data are stored and processed on our users’ storage devices and are not uploaded to third parties.<br />
                            We only collect personal data that are relevant and are limited to the extent necessary.<br />
                            Our access to personal data is necessary to provide you with effective customer service, license management, dispute management (refunds) and timely information about the product you have purchased.<br />
                            Generally, this information includes name, email address, type and date of purchase, license type and activation information, as well as communications with our support team, session metadata.<br />
                            We generally store data for 90 days. We may waive this 90-day time limit in certain cases, specifically, but not limited to, for data collected in connection with ongoing complaint handling or for data needed to correct a malfunction during the time of the correction EVONDT. never access or store sensitive payment information such as your credit card number.
                        </p>
                        <p>&nbsp;</p>
                        <h2><strong>B: Purposes of data processing</strong></h2>
                        <p>
                            We only collect personal data for our customers’ services. We only collect personal data if you purchase an EVO Tool license, open a ticket or actively subscribe to our newsletter.
                        </p>
                        <p>
                            In accordance with the needs of all our current and prospective customers, we may use your information completely anonymously for product development opportunities, internal statistics relevant to customer satisfaction (such as general trends or preferences), general development of our products and services, and other business-related traffic information.
                        </p>
                        <p>
                            Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.
                        </p>
                        <p>&nbsp;</p>
                        <h2><strong>C: User management and right to information</strong></h2>
                        <p>
                            Upon request, you may – at any time – have access to your personal data, in particular to view, update or delete them. To do this, please send us your request electronically.
                        </p>
                        <p>
                            Evondt will not charge any fee for your access request, but may charge an administrative fee for providing a copy of your personal data. In order to protect your personal data we may require identification from you before releasing the request information.
                        </p>
                        <p>&nbsp;</p>
                        <h2><strong>D: Non-disclosure of information</strong></h2>
                        <p>
                            Unless otherwise required by law or legal order, personal data will not be disclosed to any third parties other than the partners identified below and will only be disclosed for the purposes set out above.
                        </p>
                        <p>&nbsp;</p>
                        <h2><strong>E: Safety and confidentiality</strong></h2>
                        <p>
                            The web browser and the servers of EVO Tool, as well as all the communications between our software products and servers are protected by high-level SSL encryption. Beyond that, only employees directly involved in customer service and analysis have access to your personal data. You acknowledge and accept that Evondt shall not be held liable for any direct or indirect damage resulting from events such as, but not limited to, malicious third-party intervention or any technical failure.<br />
                            If you leave a comment on our site, you may choose to use cookies to save your name, email address and website address. This is for your convenience by not having to fill in the relevant content again when you comment. These cookies are kept for one year.<br />
                            If you visit our login page, we will set a temporary cookie to confirm that your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.<br />
                            When you log in, we also set a number of cookies to save your login information and screen display options. Login cookies are kept for two days, while screen display options cookies are kept for one year. <br />
                            If you select “Remember me”, your login status will be kept for two weeks. If you log out of your account, the cookies used to log in wille removed.<br />
                            If you edit or publish an article, we store an additional cookie in your browser. This cookie contains no personal data but only the ID of the article you just edited. This cookie is kept for one day.<br />
                        </p>
                        <p>&nbsp;<a id="section-6"></a></p>
                        <h2><strong>F: Anonymous data and cookie policy</strong></h2>
                        <p>
                            Evondt uses cookies to improve the service and analyse the use of website. Evondt is committed to respect your personal data and to inform you transparently on how the data are collected.
                        </p>
                        <p>If you leave a comment on our site, you may choose to use cookies to save your name, email address and website address. This is for your convenience by not having to fill in the relevant content again when you comment. These cookies are kept for one year. </p>
                        <p>If you visit our login page, we will set a temporary cookie to confirm that your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser. </p>
                        <p>When you log in, we also set a number of cookies to save your login information and screen display options. Login cookies are kept for two days, while screen display options cookies are kept for one year. If you select “Remember me”, your login status will be kept for two weeks. If you log out of your account, the cookies used to log in will be removed. </p>
                        <p>If you edit or publish an article, we store an additional cookie in your browser. This cookie contains no personal data but only the ID of the article you just edited. This cookie is kept for one day. </p>

                        <h2><strong>G:Embedded content from other websites</strong></h2>
                        <p>Articles on this site may contain embedded content (eg, videos, images, articles, etc.). Embedded content from other sites behaves the same as if you were visiting those other sites directly. </p>
                        <p>These sites may collect data about you, use cookies, embed additional third-party trackers, and monitor your interactions with these embedded content, including tracking you when you have an account with these sites and are logged into those sites Interaction with embedded content. </p>

                        <h2><strong>H: <em>How to manage existing cookies</em> </strong></h2>
                        <p>All modern internet browsers have settings that allow you to block first- or third-party cookies, or both. You can also easily delete all or selected cookies. </p>

                        <h2><strong>I: Policy Updates</strong></h2>
                        <p>This Policy may change from time to time and is available on our website.</p>

                        <h2><strong>J: Privacy Policy Complaints and Enquiries</strong></h2>
                        <p>If you have any queries or complaints about this Policy please contact us at:</p>
                        <p>Oslo Tech Pty Ltd</p>
                        <p>L2, 3-5 Bennett Street</p>
                        <p>East Perth WA 6004</p>
                        <p>Support@evondt.com</p>
                        <p>+610415748888</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Element;