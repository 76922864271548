import React, { useEffect,useState,useContext } from 'react';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
import { CloseCircleOutlined } from '@ant-design/icons';
import deviceConnect from '@/assets/deviceImg/images_connect_devices.png';
import connectSuccess from '@/assets/deviceImg/images_connect_success.png';
const opercode:any = operCode;

import "./unlock-guide.scss"
const Element  = (props: any) => {
    const {retLang,connectTip} = useContext<any>(MyContext);
    const {receivedMessages} = useCustomWebSocket();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConnect, setIsConnect] = useState<any>(null);
    const [width, setWidth] = useState<any>('0px');
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.operate){
            if(result.retcode === 341){
                setIsConnect(1);
            }else if(result.retcode === 342){
                setIsConnect(2);
            }else if(result.retcode === 343){
                setIsConnect(3);
            }
        }
        if(result.opercode === opercode.stop){
            setIsConnect(null);
        }
    }, [receivedMessages]);
    useEffect(() => {
        if(isConnect === 1){
            setIsModalOpen(true);
        }else if(isConnect === 2){
            setIsModalOpen(true);
            setTimeout(() => {
                setIsConnect(null);
            }, 3000);
        }else if(isConnect === 3){
            setIsModalOpen(true);
            setTimeout(() => {
                setIsConnect(null);
            }, 3000);
        }else{
            setIsModalOpen(false);
        }
    }, [isConnect])
    useEffect(() => {
        if(isModalOpen && isConnect === 2){
            setWidth('100%');
        }
        if(isModalOpen && isConnect === 3){
            setWidth('100%');
        }
        if(!isModalOpen){
            setWidth('0px');
        }
    }, [isModalOpen])
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsConnect(null);
    }
    const filterContent = () => {
        if(isConnect === 1){
            return deviceConnect;
        }else if(isConnect === 2){
            return connectSuccess;
        }else if(isConnect === 3){
            return deviceConnect;
        }
    }
    return (
        <div className='unlockGuide'>
            {
                isModalOpen && <div className='guideTipBox'>
                    <CloseCircleOutlined className='guideTipclose' onClick={handleCancel} />
                    <div className='guideTipTitle'>
                        {
                            isConnect === 1?<span>{retLang('guideTip1')}</span>:
                            isConnect === 2?<span>{retLang('guideTip2')}</span>:
                            isConnect === 3?<span>{retLang('guideTip3')}</span>:""
                        }
                    </div>
                    <div className="guideContext">
                        {/* {
                            connectTip && <div className="guideTipSubtitle" style={{color:""}}>
                                <span>{connectTip}</span>
                            </div>
                        } */}
                        <img src={filterContent()} alt="" />
                    </div>
                    <div className="autoClose" style={{width:width}}></div>
                </div>
            }
            
        </div>
    )
}

export default Element;