import React, { useEffect, useRef } from 'react';
import axios from '@/utils/axios';
const Element = (props: any) => {
    const htmlRef = useRef<any>(null);
    useEffect(() => {
        let local: any = localStorage.getItem("language");
        let localLang: any = local || "en";
        if (localLang && localLang !== 'en') {
            if(htmlRef.current){
                axios.post('/dwapi/get/content/translation/', {
                    content_key: "client_terms_and_conditions",
                    content_type: "html",
                    source_language_code: 'en',
                    target_language_code: localLang,
                    content: htmlRef.current.innerHTML
                }).then((res: any) => {
                    if (res.data.code === 200) {
                        try{
                            htmlRef.current.innerHTML = res.data.data;
                        }catch(e){}
                    }
                })
            }
        }
    }, [])
    return (
        <div ref={htmlRef} className='word_text'>
            <div className="container">
                <div className="block content-block">
                    <h2 className="h1">Terms & Conditions</h2>
                    <div className="wys">
                        <h2><strong>1. Accepting and modifying the General Terms and Conditions</strong></h2>
                        <ol>
                            <li>Websites, products and services managed and administered by Oslo Tech Pty Ltd trading as Evondt (ABN 18 641 506 087) (“Evondt”), as well as the use of software developed and/or distributed by Evondt are subject to this General Terms and Conditions (‘General Terms and Conditions’). By downloading, installing or using a software provided by Evondt. the User acknowledges that he or she has read, understood and agreed to this General Terms and Conditions. </li>
                            <li>These terms and conditions may be amended from time to time to maintain compliance with the law and to reflect any changes to the way Evondt operate the website and the way we expect users to behave on the website. Evondt will notify users by email of these Terms and Conditions or post a notice on the website. As a result, users should check the website frequently to keep informed of any modifications from time to time.</li>
                            <li>Intellectual Property Rights: The text, images, videos appearing on and general contents of evondt.com are the property of Evondt and are protected by Australian intellectual property laws. Evondt reserves all rights in relation to those property.</li>
                        </ol>
                        <h2><strong>2. Orders</strong></h2>
                        <ol>
                            <li>The ordering process is managed by Evondt if a product is directly purchased via Evondt. </li>
                        </ol>
                        <h2><strong>3. Services</strong></h2>
                        <ol>
                            <li>Users subscribed to the services of evondt.com are entitled to download and use the software and programs which they purchased. </li>
                            <li>In the event of non-compliance with the instructions or restrictions on the use of the Services, or failure to comply with these Terms and Conditions and/or the End Users Licence Agreement, Evondt reserves the right to suspend or close any user account, deny access to its servers and terminate its services with no compensation. </li>
                        </ol>
                        <h2><strong>4. Limitation of Liabilities</strong></h2>
                        <ol>
                            <li>Nothing in this Terms and Conditions excludes, restricts or modifies any term, condition, warranty, guarantee, right or remedy (including but not limited to a consumer guarantee under the Australian Consumer Law which cannot lawfully be excluded, restricted or modified.
                            </li>
                            <li>Evondt is responsible to the extent of its resources for ensuring the availability of its website and the means to download resources, programs and services for its customers.
                            </li>
                            <li>Evondt shall not be liable for any downtime or inability to access its products or services, including customer service and downloading or activating Evondt products, if the above is not created or attributed to by the negligence of Evondt.</li>
                            <li>Evondt shall not be liable for any suspension of all or part of the features of its sites if it occurs due to actions beyond the control of Evondt or any affiliated third party.</li>
                            <li>By subscribing to the services of Evondt, the Users acknowledge and accept that within the framework of this regulation, Evondt, its Directors, officers, agents, employees, its affiliated companies or companies involved in the maintenance, production or subcontracting of the services shall not be liable for any action, claims, direct, indirect or incidental damages, or for moral damages, costs, losses, loss of sales, expenses including legal costs or any other liability, which are likely to occur during the use of the services or, on the contrary, if the sites or their content cannot be used.</li>
                            <li>Evondt shall not be liable for any damages or viruses that may infect the User’s computer or hardware after he has accessed or used the Evondt websites or downloaded any content from them.</li>
                            <li>Evondt shall not be liable for any loss or damage suffered by the User or any third party due to the unauthorised and/or unlawful use of Evondt’s products and software.</li>
                        </ol>
                        <h2><strong>5. Response time/support</strong></h2>
                        <ol>
                            <li>The response time to requests for information, support and assistance regarding the use and/or payment of services, software or any other products provided by Evondt may take up to three (3) working days.
                            </li>
                            <li>The Evondt customer service team will do its best to minimise delay in responding to enquiries. The current average response time of Evondt is around 12 hours, not including weekends.
                            </li>
                        </ol>
                        <h2><strong>6. Disclaimer</strong></h2>
                        <p>Without limitation of the scope of article above, all contents, information and programs on the websites of Evondt is provided ‘as is’ subject to their availability and without any legal or contractual warranties.
                        </p>
                        <p>Whilst every care has been taken to ensure Evondt provides excellent products, Evondt does not warrant that its servers, web pages or their contents meet the User’s expectations or operate in an uninterrupted, convenient, secure or error-free way.</p>
                        <p>Any advice or information, whether written or oral, obtained in connection with the use of Evondt’s services and products does not constitute any warranty.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Element;