import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Checkbox, Modal } from 'antd';
import './loginAccount.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LeftOutlined,LockFilled,UserOutlined,CloseCircleOutlined  } from '@ant-design/icons';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import LicensingAgreement from '@/views/Component/agreement/LicensingAgreement';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import { io } from "socket.io-client";
import { Turnstile } from '@marsidev/react-turnstile'

const opercode:any = operCode;

const LoginAccount = () => {
  let {
    socket,
    retLang,
    domain,
    connectSocketio,
    sdkVersion,
    ipInfo
} = useContext(MyContext);
  const navigate = useNavigate();
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turnToken, setTurnToken] = useState<any>(null);
  const turnstile = useRef<any>(null);
  const [turnKey, setTurnKey] = useState<any>(Math.random());

  const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
  
  const [form] = Form.useForm();
  useEffect(() => {
    sendJsonMessage({"operate":"GetComputerHardWare","opercode":999});
    
    if(localStorage.getItem("rememberData")){
      let locData:any = localStorage.getItem("rememberData");
      const locAccount = JSON.parse(locData);
      form.setFieldsValue(locAccount);
      if(locAccount && locAccount.remember){
        setAgreementChecked(true);
      }
    }
  },[])
  //启动
  useEffect (() => {
    let result:any = { ...receivedMessages };
    if(result.retcode === 200 && result.opercode === opercode.loginaccount){
      
    }
  }, [receivedMessages,navigate]);

  const openAgreement = (e:CheckboxChangeEvent) => {
    let val = e.target.checked
    setIsModalOpen(val);
    setAgreementChecked(val)
  };
  const handleOk = () => {
    setAgreementChecked(true);
    setIsModalOpen(false);
  }
  const handleClose = () => {
    setAgreementChecked(false);
    setIsModalOpen(false);
  }
  const WidGet = () => {
    try{
      return (
        <Turnstile
          key={turnKey}
          ref={turnstile}
          siteKey='0x4AAAAAAAGzzbSBwuTxJ7vA'
          onSuccess={getToken}
          onExpire={() => {
            
          }}
          onError={() => {
            
          }}
        />
      )
    }catch(err){

    }
  }
  const getToken = (token:string) => {
    setTurnToken(token);
  }
  const accountLogin = (data : any) => {
    if(!agreementChecked){
      setIsModalOpen(true);
      return;
    }
    onVerify(null);
  }
  const onVerify = (token:any) => {
    const computerData = JSON.parse(localStorage.getItem("computerData") || "{}");
    setLoading(true);
    var obj = {
      loginAccount:form.getFieldValue("username"),
      password:form.getFieldValue("password"),
      deviceUnique:computerData.computerID || null,
      clientVersion:sdkVersion,
    }
    const formData = form.getFieldsValue();
    axios.post('/client/new/login',obj).then((res:any) => {
      setLoading(false);
      if(res.data.code === 200){
        if(formData.remember){
          localStorage.setItem('rememberData',JSON.stringify({
            username:formData.username,
            password:formData.password,
            remember: true
          }))
        }else{
          localStorage.setItem('rememberData',JSON.stringify({
            remember: false
          }));
        }
        socket.disconnect();
        localStorage.setItem("accountData",JSON.stringify(res.data.data))
        localStorage.setItem("launch","1")
        connectSocketio();
        
        navigate('/Launch');
        sendJsonMessage(res.data.data);
      }else{
        setTurnToken(null);
        turnstile.current.reset();
      }
    }).catch((err:any) => {
      setLoading(false);
    })
  }
  const goBack = () => {
    navigate('/login/loginType');
  }
  const goEmail = () => {
    navigate('/login/loginEmail');
}
    return (
        <div className="loginAccount">
          {/* <div className="goBack" onClick={ goBack }><LeftOutlined /> {retLang("goBack")}</div> */}
          <Form
          colon={false}
          name="basic"
          form={form}
          style={{ maxWidth: 600 }}
          onFinish={accountLogin}
          autoComplete="off"
          requiredMark={false}
          >
            <Form.Item style={{display:"none"}}>
              <Input readOnly={true} autoComplete='off'></Input>
            </Form.Item>
            <Form.Item
              name="username"
            >
              <Input prefix={ <UserOutlined className='iconFont' autoComplete="new-password" />} />
            </Form.Item>
            <Form.Item
              name="password"
            > 
              <Input.Password prefix={<LockFilled className='iconFont' />} autoComplete="new-password" />
            </Form.Item>
            <p className='findPassword'><a target='_blank' href={`https://account.${domain}/reset-password`}>{retLang('Forgotyourpassword')}</a></p>
            <Form.Item name="remember" valuePropName="checked" className='remember'>
              <Checkbox>{retLang("RememberMe")}</Checkbox>
            </Form.Item>
            <Form.Item name="agreement" className='remember agreement'>
              <Checkbox checked={agreementChecked} onChange={openAgreement}><span className='checkbox_text'>{retLang("EndUserLicenceAgreement")}</span></Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" loading={loading} block>
                {retLang("Login")}
              </Button>
            </Form.Item>
            <p className="loginRemark">{retLang('OrYoucan')} <span onClick={goEmail}>{retLang('loginwithyouremail')}.</span></p>
            {/* {WidGet()} */}
            <p className="remark" style={{fontSize:'14px'}}>
              <a target='_blank' href={`https://account.${domain}/user-register`}>{
                (ipInfo && ipInfo?.country!=="CN" && ipInfo?.type!=="hosting")?retLang('Signupbetaaccount'):retLang('SignUp')
              }</a>
            </p>
          </Form>
          <Modal title=" "
          closeIcon={<CloseCircleOutlined />}
          width={600}
          open={isModalOpen} 
          onOk={handleOk} 
          onCancel={handleClose}
          okText={retLang("Accept")}
          cancelText = {retLang("Reject")}
          centered={true}
          >
            <LicensingAgreement></LicensingAgreement>
          </Modal>
        </div>
    )
}

export default LoginAccount;