import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import "./deviceInformation.scss"
const Element  = (props: any) => {
  const pathName = useLocation();
  useEffect(() => {
    
  },[pathName])
  return (
    <div className='infomation'>
      <Outlet /> 
    </div>
  )
}

export default Element;