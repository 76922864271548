import React, {  } from 'react';
import {  } from 'react-router-dom';
import "./toolBox.scss"
import logo from "@/assets/logo_128.png"
import FooterCom from "@/views/Component/footerMenu/footerMenu";
const Element  = (props: any) => {
  
  return (
    <div className='toolBox'>
      <div className="toolList">
        <div className="toolCenter">
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>ICCID checker</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Calc</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Mac Address Check</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Imei Check</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Phone Blacklist Check</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Lookup</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Check icloud</p>
          </div>
          <div className="toolItem">
            <img src={logo} alt="" />
            <p>Imei esn converter</p>
          </div>
        </div>
      </div>
      <FooterCom />
    </div>
  )
}

export default Element;