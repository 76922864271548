import React, { useContext,useState,useCallback, useEffect } from 'react';
import { Input,notification } from 'antd';
import { CopyOutlined,LeftOutlined,RightOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import './share.scss';
import { 
    FBShareBtn,
    TwitterShareBtn,
    SkypeShareBtn,
    TelegramShareBtn,
    VKShareBtn,
    LineShareBtn,
    LinkedInShareBtn,
    WhatsAppShareBtn,
    RedditShareBtn,
    PinterestShareBtn,
    TumblrShareBtn,
 } from "dv-social-share";

const Element  = (props: any) => {
    const { shareRemark, btnShareLink } = props;
    let {
        retLang,
        getUserInfo,
    } = useContext(MyContext);
    const [api, contextHolder] = notification.useNotification();
    const [shareLink,setShareLink] = useState('');
    const [sharePage,setSharePage] = useState(1);
    const [shareMargin,setShareMargin] = useState(0);

    useEffect (() => {
        getUserInfo();
        axios.post('/client/invite/getAgentInviteUserUrl').then((res:any) => {
            if (res.data.code === 200) {
                setShareLink(res.data.data);
            }
        })
    }, []);
    useEffect (() => {
        if(sharePage === 1){
            setShareMargin(0);
        }else if(sharePage === 2){
            setShareMargin(472);
        }else if(sharePage === 3){
            setShareMargin(552);
        }
    }, [sharePage]);
    const copyLog = useCallback(() => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareLink);
        } else {
            document.execCommand('copy');
        }
        api["success"]({
            message: retLang('Copiedtoclipboard')
        });
    },[api,shareLink])
    const shareText = shareRemark;
    const handlerPage = (type:any) => {
        if(type === 'left'){
            setSharePage((page:any) => (page-1));
        }else if(type === 'right'){
            setSharePage((page:any) => (page+1));
        }
    }
    return (
        <div className='share_box'>
            {contextHolder}
            <div className="share_item">
                <div className='scroll_main'>
                    <div className="scrollLeft scrollBtn" style={{display:sharePage!==1?"block":"none"}} onClick={()=>handlerPage('left')}><LeftOutlined /></div>
                    <div className="scrollRight scrollBtn" style={{display:sharePage!==2?"block":"none"}} onClick={()=>handlerPage('right')}><RightOutlined /></div>
                    <div className="shareScroll">
                        <div className='shareContent' style={{marginLeft:`-${shareMargin}px`}}>
                            <div className='shareAppItem'>
                                { FBShareBtn({ url: btnShareLink, quote: shareText, openInNewTab: true, }) }
                                <p>Facebook</p>
                            </div>
                            <div className='shareAppItem'>
                                { TwitterShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Twitter</p>
                            </div>
                            <div className='shareAppItem'>
                                { TelegramShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Telegram</p>
                            </div>
                            <div className='shareAppItem'>
                                { SkypeShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Skype</p>
                            </div>
                            <div className='shareAppItem'>
                                { VKShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>VK</p>
                            </div>
                            <div className='shareAppItem'>
                                { WhatsAppShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>WhatsApp</p>
                            </div>
                            <div className='shareAppItem'>
                                { LineShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Line</p>
                            </div>
                            <div className='shareAppItem'>
                                { LinkedInShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>LinkedIn</p>
                            </div>
                            <div className='shareAppItem'>
                                { RedditShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Reddit</p>
                            </div>
                            <div className='shareAppItem'>
                                { PinterestShareBtn({ url: btnShareLink, media: "https://www.evondt.com/logo-text-white@2x.png", openInNewTab: true, }) }
                                <p>Pinterest</p>
                            </div>
                            <div className='shareAppItem'>
                                { TumblrShareBtn({ url: btnShareLink, title: shareText, openInNewTab: true, }) }
                                <p>Tumblr</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_text">
                    <Input value={shareLink} style={{width:'410px'}} readOnly suffix={
                        <div className='iconClass' style={{display:'flex'}}>
                            <CopyOutlined onClick={copyLog} />
                        </div>
                    } />
                </div>
            </div>
        </div>
    )
}

export default Element;