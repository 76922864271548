import React, { useContext, useEffect, useState } from 'react';
import logo from '@/assets/LOGO.svg'
import tipIco from "@/assets/icon/icon_tips.svg";
import './noService.scss'
import { Button,Checkbox,Modal } from 'antd';
import { DownloadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import { useNavigate } from 'react-router-dom';
import axios from '@/utils/axios';
import PrivacyPolicy from '@/views/Component/agreement/privacyPolicy';
import TermsConditions from '@/views/Component/agreement/termsConditions';

var userAgent = navigator.userAgent.toLowerCase();
const Element  = (props: any) => {
    const navigate = useNavigate();
    const [isCheckAgree, setIsCheckAgree] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeName, setAgreeName] = useState<any>(null);
    const {connectCode,retLang,domain} = useContext<any>(MyContext);
    const download = async () => {
        const res = await axios.post('/wapi/v1/merchant/installer/query');
        let data = res.data.data;
        if(res.data.code === 200){
            let logRes = await axios.post('/wapi/v1/merchant/clientDownloadPreW',{fileName:data.fileName,versionDesc:data.versionDesc});
            if(logRes.data.code === 200){
                window.location.href = `https://dl.${domain}/filedownload/${data.fileName}`;
            }
        }
    }
    useEffect(() => {
        try{
            const link = document.createElement('a');
            link.href = 'evotool://';
            link.click();
        }catch(e){
          
        }
    }, [])
    useEffect(() => {
        if(connectCode === 'Open'){
            navigate('/dashboard/deviceConnection');
        }
    }, [connectCode])
    const openBrowser = () => {
        try{
            const link = document.createElement('a');
            link.href = 'evotool://';
            link.click();
        }catch(e){
          
        }
    }
    const handleOk = () => {
        setIsCheckAgree(true);
        setIsModalOpen(false);
      }
      const handleClose = () => {
        setIsCheckAgree(false);
        setIsModalOpen(false);
      }
    const checkAgree = (e:any) => {
        setIsCheckAgree(e.target.checked);
        if(e.target.checked){
            seeAgree(1);
        }
    }
    const seeAgree = (type:number) => {
        setAgreeName(type);
        setIsModalOpen(true);
    }
    return (
        <div className='noService'>
            <div className="serviceContent">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <p className='connectTip'>
                    <img src={tipIco} alt="" />
                    <span>{retLang('noServiceTip1')} </span>
                </p>
                <p>{retLang('noServiceTip2')}</p>
                <div className='ReadAgreement'>
                    <Checkbox checked={isCheckAgree} onChange={checkAgree}></Checkbox>
                    <span className='agreeText'>
                        {retLang('Agreetoaccept')} 
                        <span className='isAgree' onClick={() => seeAgree(1)}> {retLang('Privacypolicy')} </span> {retLang('and')} 
                        <span className='isAgree' onClick={() => seeAgree(2)}> {retLang('TermsConditions')} </span>
                    </span>
                </div>
                <div className="download_btn">
                    <Button type="primary" disabled={!isCheckAgree} icon={<DownloadOutlined />} size="large" onClick={download}>{retLang('Install')}</Button>
                    <Button type="primary" size="large" onClick={openBrowser}>{retLang('OpenEvondt')}</Button>
                </div>
            </div>
            <Modal title=" " 
            closeIcon={<CloseCircleOutlined />}
            width={600}
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleClose}
            okText={retLang("Accept")}
            cancelText = {retLang("Reject")}
            centered={true}
            >
                {
                    agreeName === 1 ? <PrivacyPolicy /> 
                    : agreeName === 2 ? <TermsConditions />
                    : null
                }
            </Modal>
        </div>
    )
}

export default Element;