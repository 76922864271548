import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'antd';
// import {RightOutlined} from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import connectSuccess from '@/assets/deviceImg/images_connect_success.png';
import connectFaild from '@/assets/deviceImg/images_connect_faild.png';
import "./connectStatus.scss"
import tipIco from "@/assets/icon/icon_tips.svg";
import tipFiledIco from "@/assets/icon/icon_filed_tips.svg";
const Element = () => {
    const {retLang} = useContext<any>(MyContext);
    const navigate = useNavigate();

    const status = useLocation().state;
    const isConnect = status.connectStatus===200;

    const next = useCallback(() => {
        navigate('/dashboard');
    },[navigate]);

    const goBack = useCallback(() => {
        navigate('/dashboard/deviceConnection');
    },[navigate]);

    const goFunction = useCallback(() => {
        navigate('/dashboard/index');
    },[navigate]);

    const showButton = () => {
        if(isConnect){
            return (
                <div>
                    <Button onClick={next} type="primary">{retLang('Next')}</Button>
                </div>
            )
        }else{
            return (
                <div>
                    <Button onClick={goBack} type="primary">{retLang('retry')}</Button>
                </div>
            )
        }
        
    }
    return (
        <div className='connectSuccess'>
            <img className='connectImg' src={isConnect?connectSuccess:connectFaild} alt="" />
            <p className='connectTip'>
                <img src={isConnect?tipIco:tipFiledIco} alt="" />
                <span className={isConnect?'':'connectFiled'}>{isConnect?retLang('Connectionexception2'):retLang('Connectionexception')} </span>
            </p>
            <div className="connectOper">
                {showButton()}
                <div className="oper-btn">
                    <Button type='primary' onClick={goFunction}>{retLang('Dashboard')}</Button>
                </div>
            </div>
        </div>
    )
}

export default Element;