import React, { useContext,useEffect,useState,useRef } from 'react';
import { Divider,Button,Card, Modal,Input,notification } from 'antd';
import { LaptopOutlined,CloseOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import { useNavigate } from 'react-router-dom';
import DeviceTip from './deviceCom/devicetip';

import axios from '@/utils/axios';
const { confirm } = Modal;
const Element  = (props: any) => {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    let {
        retLang
    } = useContext(MyContext);
    const password = useRef<any>(null);
    const [deviceList, setDeviceList] = useState([
        
    ]);
    const [currentdevices, setCurrentdevices] = useState([
            
    ]);

    useEffect(() => {
        getDeviceList();
    }, [])

    const getDeviceList = () => {
        axios.get('/client/new/getDeviceLoginList').then((res:any) => {
            
            setCurrentdevices(res.data.data.filter((item:any) => {
                return item.currentDeviceFlag === 1;
            }));
            setDeviceList(res.data.data.filter((item:any) => {
                return item.currentDeviceFlag === 0;
            }));
        }).catch((err:any) => {
            setDeviceList([
                
            ]);
        })
    }
    const changePassword = (e:any) => {
        password.current = e.target.value;
    }
    const deleteDevice = (id:any) => {
        let remark = '';
        if(id === null){
            remark = `${retLang('logoffDevice')} ${deviceList.length} ${retLang('Devices')}`;
        }else{
            remark = retLang('entPasswordlogoff');
        }
        confirm({
            title: remark,
            icon:null,
            content: (
                <div className='delete_device_box' style={{marginRight:"-34px"}}>
                    <div className="delete_device_title">{retLang('Password')}</div>
                    <div className="delete_device_input">
                        <Input.Password onChange={changePassword} />
                    </div>
                </div>
            ),
            okText: retLang('Deregister'),
            cancelText: retLang('Cancel'),
            okButtonProps: {
                style: {
                    background: '#4990cb',
                    borderColor: '#4990cb',
                    color: '#fff',
                    padding: '7px 30px',
                    height: 'auto',
                }
            },
            cancelButtonProps: {
                type: 'text',
                style: {
                    color: '#fff',
                    background: 'none',
                    border: 'none',
                    padding: '7px 30px',
                    height: 'auto',
                }
            },
            className: "edit_info_modal",
            onOk() {
                axios.post('/client/new/delDeviceLogin',{
                    id,
                    loginPassword:password.current
                }).then((res:any) => {
                    if(res.data.code === 200){
                        getDeviceList();
                    }
                }).catch((err:any) => {
        
                })
            },
            onCancel() {
                
            },
        });
    }
    return (
        <div className='deviceMag_box'>
            {contextHolder}
            <div className="setting_list">
                <div className="setting_title">{retLang('Devices')}</div>
                <Divider />
                <div className="content_item">
                    <p className="devices_remark">{retLang('devices_remark1')}</p>
                    <p className="devices_remark">{retLang('devices_remark2')}</p>
                    <div className="content_item_title">{retLang('Currentdevices')}</div>
                    {
                        currentdevices.map((item:any,index:any) => {
                            return (
                                <div className="content_item_list_item" key={index}>
                                    <div className="content_show_info">
                                        <div className='device_content_l'>
                                            <div className="device_icon">
                                                <LaptopOutlined />
                                            </div>
                                            <div className="device_info">
                                                <div className="device_info_title">{item.operatingSystem} {item.operatingSystemBuildVersion} {item.computerName}</div>
                                                <div className="device_info_text">{retLang('Lastlogin')}: {item.updateTime}</div>
                                            </div>
                                        </div>
                                        <div className="device_content_r">
                                            {
                                                
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="content_item">
                    {deviceList.length > 0 && <div className="content_item_title">{retLang('Otherdevices')}</div>}
                    {
                        deviceList.map((item:any,index:any) => {
                            return (
                                <div className="content_item_list_item" key={index}>
                                    <div className="content_show_info">
                                        <div className='device_content_l'>
                                            <div className="device_icon">
                                                <LaptopOutlined />
                                            </div>
                                            <div className="device_info">
                                                <div className="device_info_title">{item.operatingSystem} {item.operatingSystemBuildVersion} {item.computerName}</div>
                                                <div className="device_info_text">{retLang('Lastlogin')}: {item.updateTime}</div>
                                                <div className='device_info_region'>{item.country} {item.city} {item.region}</div>
                                            </div>
                                        </div>
                                        <div className="device_content_r">
                                            {
                                                <CloseOutlined onClick={() => deleteDevice(item.id)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="content_item">
                    <div className="content_item_title">{retLang('logoutAllKnownDevice')}</div>
                    <div className="content_item_text">{retLang('logoutAllKnownDeviceRemark')}</div>
                    <div className="content_item_btn">
                        <Button type='primary' disabled={deviceList.length === 0} onClick={() => deleteDevice(null)}>{retLang('logoutAllKnownDevice')}</Button>
                    </div>
                </div>
                <div className="content_item">
                    <div className="content_item_title">{retLang('Pleasenote')}</div>
                    <div className="content_item_text">
                        <DeviceTip />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Element;