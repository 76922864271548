import React, { useContext, useEffect, useState } from 'react';
import { Divider, Button, Alert, Empty } from 'antd';
import { MyContext } from '@/utils/myContext';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import NoServiice from '@/views/Device/noService/noService'
const opercode:any = operCode;
import "./driverCom.scss"
const Element  = (props: any) => {
    let {
        retLang,
        connectCode
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    const [driverList,setDriverList] = useState<any>([
        // {"name":"Qualcomm Driver installed","key":"InstallQualcommDriver","value":"1"},
        // {"name":"Mediatek Driver installed","key":"InstallMediatekDriver","value":"0"},
        // {"name":"Spreadtrun Driver installed","key":"InstallSpreadtrunDriver","value":"0"}
    ]);
    const [isNotInstall,setIsNotInstall] = useState(false);
    useEffect (() => {
        sendJsonMessage({"operate":"check_driver","opercode":opercode.check_driver});
    }, []);
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.check_driver && result.retcode === 200){
            if(result.check_driver && result.check_driver.length > 0){
                setDriverList([...result.check_driver]);
            }
        }
    }, [receivedMessages]);
    useEffect (() => {
        let open = false;
        if(driverList.length > 0){
            driverList.map((item:any) => {
                if(item.value === "0"){
                    open = true;
                }
            })
            setIsNotInstall(open);
        }
    }, [driverList]);
    const install = (key:any) => {
        sendJsonMessage({"operate":key,"opercode":opercode.install_driver});
        setDriverList(
            driverList.map((item:any) => {
                if(item.key === key){
                    item.value = "2";
                }
                return item;
            })
        );
    }
    return (
        <div className='driver_box'>
            <div className="setting_list">
                {/* retLang('DriverManagement') */}
                <div className="setting_title">{retLang('Driverdetection')}</div>
                <Divider />
                {
                    isNotInstall && <div style={{marginBottom:"20px"}}>
                        <Alert
                            message={retLang("Necessarydrivernotdetected")}
                            description={retLang("NodriverWarigin")}
                            type="warning"
                            showIcon
                            />
                    </div>
                }
                {
                    connectCode === "Open"?<div>
                        {
                            driverList.length === 0?
                            <Empty description={false} style={{marginTop:"30px",opacity:0.6}} />:
                            driverList.map((item:any,index:any) => {
                                return (
                                    <div className="driver_item" key={index}>
                                        <span className='driver_name'>{item.name}</span>
                                        <span className='driver_install'>
                                            <Button loading={item.value==='2'} onClick={() => install(item.key)}>
                                                {
                                                    item.value === "1"?
                                                    retLang('reinstall')
                                                    :item.value === "0"?
                                                    retLang('Install')
                                                    :item.value === "2"?
                                                    retLang('Installing')
                                                    :retLang('Install')
                                                }
                                            </Button>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>:
                    <div>
                        <NoServiice />
                    </div>
                }
                
            </div>
        </div>
    )
}

export default Element;