import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useLocation } from 'react-router-dom';
import { useNavigationType, createRoutesFromChildren, matchRoutes, } from 'react-router';
import "./style/global.scss"
import "./utils/livechat.js"
import "./utils/analytics.js"
import "./utils/googleAnalytics.js"
import * as Sentry from "@sentry/react";

setTimeout(() => {
  Sentry.init({
    dsn: "https://8d2280e46b57616f8c349d57c37adf49@sentry.evondt.systems/6",
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText:false,
        blockAllMedia:false,
      }),
    ],
    release: 'client-production-1.0.2',
    environment: 'production',
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    profilesSampleRate: 1.0, // Capture 100% of the profiles, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}, 5000);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
