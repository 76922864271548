import React, { useCallback, useEffect, useState, useContext,useRef } from 'react';
import "./unlockIndex.scss"
import { Button, Select, notification, Modal, Tag, Badge, Image,Tooltip } from 'antd';
import { MyContext } from '@/utils/myContext';
import { ExclamationCircleFilled,LockFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Common from '../block/common';
import Function from '../block/function';
import Console from "../block/console";
import Progress from "../block/progress";
import FunctionOthers from '../block/function-others';
import UnlockGuide from '../block/unlock-guide';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import axios from '@/utils/axios';
import { decryptAES } from '@/utils/crypotoJS';

import newIcon from '@/assets/icon/new.png';
import hotIcon from '@/assets/icon/hot.png';
import no1Icon from '@/assets/icon/one.png';

const { confirm } = Modal;
const opercode:any = operCode;

const Element  = (props: any) => {
    let { defaultBrand,setDefaultBrand,
        defaultModel,setDefaultModel,
        defaultPort,setDefaultPort,
        sendPort,setSendPort,
        flash,setFlash,
        setReset,
        tools,setTools,
        flashType,setFlashType,
        flashKey,setFlashKey,
        flashLock,setFlashLock,
        operateName,setOperateName,
        flashName,setFlashName,
        translateFunItem,
        setIsShowPurchase,
        setOthers,
        preloader,setPreloader,
        filePath,
        gptmap,
        language,
        gptdata,setGptdata,
        retLang,
        availability,
        menuCheckId,
        setFlashExistence,
        setToolExistence,
        chipFilePath,
        connectCode,
        setNoServiceOpen,
        domain,
        activeKey,
        setFunRemark,
        operateLoading, setOperateLoading,
        setPurchaseType,
        availableForFree,
    } = useContext(MyContext);
    const navigate = useNavigate();
    const {sendJsonMessage,receivedMessages,readyState} = useCustomWebSocket();
    const [brandList,setBrandList] = useState<any>(null);
    const [modelList,setModelList] = useState<any>(null);
    const [portList,setPortList] = useState([]);
    const [btnStr,setBtnStr] = useState<any>(null);
    const [showPoint,setShowPoint] = useState(false);
    const [showPointImage,setShowPointImage] = useState(false);
    const [pointList,setPointList] = useState<any>(null);
    const [brandTag,setBrandTag] = useState<any>("model");
    const localGptDataRef = useRef<any>(null);

    const consoleRef = useRef<any>(null);

    const clearAllData = () => {
        setGptdata([]);
        setReset(null);
        setTools(null);
        setFlash(null);
    }
    // change brand 
    const onChangeBrand = useCallback((val:any) => {
        clearAllData();
        setDefaultBrand(val);
    },[setDefaultBrand]);
    // change model
    const onChangeModel = useCallback((val:any,option:any) => {
        clearAllData();
        setDefaultModel(val);
        setShowPoint(option.point===1?true:false);
    },[defaultBrand,setDefaultModel]);

    const onChangeProt = useCallback((val:any) => {
        setDefaultPort(val);
        const regex = /\(([^)]+)\)/;
        const match = val.match(regex);
        if(match){
            setSendPort(match[1]);
        }else{
            setSendPort(val);
        }
    },[setDefaultPort]);

    const onGetport = useCallback(() => {
        sendJsonMessage({"operate":"GetCommPort","brand":defaultBrand,"opercode":opercode.GetCommPort});
    },[defaultBrand])
    const onSearch = useCallback((val:any) => {
        
    },[]);
    const onSearchModel = useCallback((val:any) => {
        if(val && val !== ''){
            let isSupport = 2;
            for(let i=0; i<modelList.length; i++){
                if(modelList[i].Device.includes(val)){
                    isSupport = 1;
                    break;
                }
            }
            axios.post('/client/new/brandModelQuery',{brand:defaultBrand,model:val,supportStatus:isSupport}).then((res:any) => {});
        }
    },[modelList]);

    useEffect(() => {
        if(menuCheckId === 4){
            clearAllData();
            getBrandList(brandTag);
        }else if(menuCheckId === 6){
            clearAllData();
            getBrandList("Utility tools");
        }
    },[brandTag,menuCheckId]);
    
    useEffect(() => {
        let result:any = { ...receivedMessages };
        //获取端口列表,发送获取品牌请求
        if(result.opercode === opercode.GetCommPort && result.UartList){
            let portList:any = [];
            for(var i=0; i<result.UartList.length; i++){
                portList.push({port:result.UartList[i]});
            }
            setPortList(portList);
        }
        // 操作状态
        if(result.opercode === opercode.operate){
            if(result.retcode === 201){
                setBtnStr('STOP');
            }
            if(result.retcode === 105){
                localGptDataRef.current = null;
            }
            if(result.retcode === 200) {
                setOperateLoading(null);
                if(localGptDataRef.current === null) return;
                setGptdata(localGptDataRef.current);
                localGptDataRef.current = null;
            }
            if(result.retcode >= 400 && result.retcode < 800){
                setOperateLoading(null);
                if(localGptDataRef.current === null) return;
                setGptdata(localGptDataRef.current);
                localGptDataRef.current = null;
            }
        }
        if(result.opercode === opercode.stop){
            // setBtnStr(null);
        }
    },[receivedMessages]);

    useEffect(() => {
        if(activeKey !== '1'){
            setBtnStr('STOP');
        }else{
            setBtnStr(null);
        }
    },[activeKey]);

    useEffect(() => {
        let result:any = { ...receivedMessages };
        if(result.opercode === opercode.operate){
            if(result.retcode === 200 || result.retcode >= 400){
                if(activeKey !== '1'){
                    setBtnStr('STOP');
                }else{
                    setBtnStr(null);
                }
            }
        }
        if(result.opercode === opercode.stop){
            if(activeKey !== '1'){
                setBtnStr('STOP');
            }else{
                setBtnStr(null);
            }
        }
    },[activeKey,receivedMessages]);

    useEffect(() => {
        if(brandList && brandList.length > 0 && defaultBrand){
            getModeList(defaultBrand);
            onGetport();
        }else{ 
            setModelList([]);
        }
    },[brandList,defaultBrand]);

    useEffect(() => {
        if(modelList && modelList.length>0 && defaultModel){
            getFunctionList(defaultBrand,defaultModel);
        }
    },[modelList,defaultModel]);

    const getBrandList = useCallback((tag:any) => {
        setBrandList(null);
        setModelList(null);
        setDefaultModel(null);
        setDefaultBrand(null);
        axios.post('/client/new/getSupportBrandList',{tags:tag}).then((res:any) => {
            if(res.data.code === 200){
                let brandData:any = res.data.data.list;
                let arr:any = [];
                for(let i=0; i<brandData.length; i++){
                    arr.push({Brand:brandData[i]});
                }
                setTimeout(() => {
                    if(arr.length === 0){
                        setBrandList([]);
                        setDefaultBrand(null);
                    }else{
                        setDefaultBrand(arr[0].Brand);
                        setBrandList(arr);
                    }
                }, 200);
            }
        }).catch((err:any) => {
            setBrandList([]);
            setDefaultBrand(null);
            setFlash([]);
            setReset([]);
            setTools([]);
        });
    },[]);
    
    const getModeList = useCallback((brand:any) => {
        setModelList(null);
        setDefaultModel(null);
        axios.post('/client/new/getSupportModleListByBrand',{brand:brand}).then((res:any) => {
            if(res.data.code === 200){
                let modelData:any = res.data.data.list;
                let arr:any = [];
                for(let i=0; i<modelData.length; i++){
                    if(modelData[i].misc_models.includes(',')){
                        let misc_models = modelData[i].misc_models.split(',');
                        for(let j=0; j<misc_models.length; j++){
                            arr.push({
                                Device:`${modelData[i].model} [${misc_models[j].replace(/^\s+|\s+$/g,"")}]`,
                                tags:modelData[i].tags || [],
                                test_point:modelData[i].test_point
                            });
                        }
                    }else if(modelData[i].misc_models === ''){
                        arr.push({
                            Device:`${modelData[i].model}`,
                            tags:modelData[i].tags || [],
                            test_point:modelData[i].test_point
                        });
                    }else{
                        arr.push({
                            Device:`${modelData[i].model} [${modelData[i].misc_models}]`,
                            tags:modelData[i].tags || [],
                            test_point:modelData[i].test_point
                        });
                    }
                }
                setTimeout(() => {
                    setModelList(arr);
                    if(arr.length > 0){
                        setDefaultModel(arr[0].Device);
                        setShowPoint(arr[0].test_point===1?true:false);
                    }else{
                        setFlash([]);
                        setReset([]);
                        setTools([]);
                    }
                }, 200);
            }
        }).catch((err:any) => {
            
        });
    },[]);
    const getFunctionList = useCallback((brand:any,model:any) => {
        let filterModel = model.replace(/\s+\[.*?\]/, '');
        axios.post('/client/new/getFunctionListByBrandAndModel',{brand:brand,model:filterModel}).then(async (res:any) => {
            if(res.data.code === 200){
                let accountData:any = localStorage.getItem('accountData');
                let key:any = JSON.parse(accountData).loginKey;
                let iv = 'bMLBHDWfaKnJSuzX';
                let funData:any = res.data.data;
                const data = JSON.parse(decryptAES(funData,key,iv));
                const funList = data.function_key;
                let flashArr:any = [];
                let resetArr:any = [];
                let toolsArr:any = [];
                consoleRef.current.clearLog();
                consoleRef.current.handleMessage(`CPU: ${data.cpu || ''}`);
                try{
                    if(data.cpu.includes('Mediatek') && brand !== 'VIVO'){
                        setOthers([{name:'Preloader To Brom',Values:'0',label:'Preloader'}]);
                        setPreloader('0');
                    }else{
                        setOthers([]);
                        setPreloader('0');
                    }
                }catch(err){}
                try{
                    if(data.tip && data.tip !== '0' && data.tip !== ''){
                        consoleRef.current.handleMessage(data.tip,103);
                    }
                }catch(err){}
                // let remarkObj:any = {};
                if(funList){
                    for(let i=0; i<funList.length; i++){
                        // remarkObj[funList[i].function_key] = funList[i].description;
                        if(funList[i].sort_id < 100){
                            flashArr.push({
                                name:funList[i].name,
                                label:funList[i].function_key,
                                description:funList[i].description,
                                permission:funList[i].having_permission,
                                type: filterFlashType(funList[i].sort_id),
                                function_id:funList[i].function_id,
                                function_key:funList[i].function_key,
                            });
                        }else if(funList[i].sort_id < 300 && funList[i].sort_id >= 200){
                            resetArr.push({
                                name:funList[i].name,
                                label:funList[i].function_key,
                                description:funList[i].description,
                                permission:funList[i].having_permission,
                                function_id:funList[i].function_id,
                                function_key:funList[i].function_key,
                            });
                        }else if(funList[i].sort_id < 1000 && funList[i].sort_id >= 300){
                            toolsArr.push({
                                name:funList[i].name,
                                label:funList[i].function_key,
                                description:funList[i].description,
                                permission:funList[i].having_permission,
                                function_id:funList[i].function_id,
                                function_key:funList[i].function_key,
                            });
                        }
                    }
                    // setFunRemark(remarkObj);
                }
                setTimeout(() => {
                    try{
                        let sendData = JSON.parse(JSON.stringify(data));
                        sendData.brand = brand;
                        sendData.model = model;
                        if(sendData.cpu && sendData.cpu !== ''){
                            sendData.CPUType  = sendData.cpu;
                        }else{
                            sendData.CPUType  = 'unknow';
                        }
                        delete sendData.cpu;
                        delete sendData.tip;
                        sendData.function_key = [];
                        for(let i=0; i<data.function_key.length; i++){
                            sendData.function_key.push(data.function_key[i].function_key);
                        }

                        sendJsonMessage(sendData);
                    }catch(err){}
                    setFlash(flashArr);
                    setReset(resetArr);
                    setTools(toolsArr);
                }, 200);
            }
        }).catch((err:any) => {});
    },[]);

    useEffect(() => {
        if(flash){
            if(flash.length > 0){
                setFlashType(flash[0].label);
                setFlashKey(flash[0].type);
                setFlashLock(flash[0].permission);
                setOperateName(flash[0].name);
                setFlashName(`${flash[0].function_key}${flash[0].function_id}`);
                setFlashExistence(true);
            }else{
                setFlashExistence(false);
            }
        }
    },[flash]);
    useEffect(() => {
        if(tools){
            if(tools.length > 0){
                setToolExistence(true);
            }else{
                setToolExistence(false);
            }
        }
    },[tools]);
    const filterFlashType = (type:any) => {
        let str = '';
        switch (type) {
            case 1:
                str = 'info';
                break;
            case 2:
                str = 'dump';
                break;
            case 3:
                str = 'write';
                break;
            case 4:
                str = 'erase';
                break;
            default:
                break;
        }
        return str;
    }
    const startOper = useCallback(() => {
        if(connectCode !== 'Open'){
            setNoServiceOpen(true);
            return;
        }
        if(flashLock === 0 && availability === 0){
            availableForFree && setPurchaseType(2);
            setIsShowPurchase(true);
            return;
        }
        if(gptdata.length > 0){
            localGptDataRef.current = gptdata;
        }else{
            localGptDataRef.current = null;
        }
        let viewHeight = window.innerHeight - 370;
        let viewNum = Math.ceil(viewHeight/40);
        setGptdata((gptdata:any) => gptdata.slice(0,viewNum));
        if(flashKey === 'info'){
            let sendData:any = {"operate":flashType,"brand":defaultBrand,"model":defaultModel,"Port":sendPort,"preloader":preloader,"opercode":opercode.operate};
            if(menuCheckId === 6){
                sendData["devparam"] = chipFilePath;
            }
            sendJsonMessage(sendData);
        }else if(flashKey === 'dump' || flashKey === 'write'){
            let dumpList = [];
            for(let i=0; i<gptdata.length; i++){
                if(gptdata[i].Checked === '1'){
                    let obj:any = {};
                    // obj[gptdata[i].name] = gptdata[i].File_s;
                    for(let j=0; j<gptmap.length; j++){
                        obj[gptmap[j].title] = gptdata[i][gptmap[j].title];
                    }
                    dumpList.push(obj)
                }
            };
            if(flashKey === 'write'){
                let sendData:any = {"operate":flashType,"brand":defaultBrand,"model":defaultModel,"Port":sendPort,
                    "preloader":preloader,
                    "FilePath":filePath,
                    "flashunit":gptdata,
                    "opercode":opercode.operate
                };
                if(menuCheckId === 6){
                    sendData["devparam"] = chipFilePath;
                }
                sendJsonMessage(sendData);
            }else{
                let sendData:any = {"operate":flashType,"brand":defaultBrand,"model":defaultModel,"Port":sendPort,
                    "preloader":preloader,
                    "FilePath":filePath,
                    "dumpunit":gptdata,
                    "opercode":opercode.operate
                };
                if(menuCheckId === 6){
                    sendData["devparam"] = chipFilePath;
                }
                sendJsonMessage(sendData);
            }
            
        }else if(flashKey === 'erase'){
            let dumpList = [];
            for(let i=0; i<gptdata.length; i++){
                if(gptdata[i].Checked === '1'){
                    let obj:any = {};
                    for(let j=0; j<gptmap.length; j++){
                        obj[gptmap[j].title] = gptdata[i][gptmap[j].title];
                    }
                    dumpList.push(obj)
                }
            };
            let sendData:any = {"operate":flashType,"brand":defaultBrand,"model":defaultModel,"Port":sendPort,
                "preloader":preloader,
                "FilePath":filePath,
                "dumpunit":gptdata,
                "opercode":opercode.operate
            }
            if(menuCheckId === 6){
                sendData["devparam"] = chipFilePath;
            }
            sendJsonMessage(sendData);
        }
        setOperateLoading(flashType);
        setOperateName(flashName);
    },[flashType,defaultBrand,defaultModel,sendPort,preloader,filePath,gptdata,chipFilePath,menuCheckId]);
    const stopOper = useCallback(() => {
        confirm({
            title: 'Tip',
            icon: <ExclamationCircleFilled />,
            content: retLang('AreyouStop'),
            onOk() {
                if(localGptDataRef.current){
                    setGptdata(localGptDataRef.current);
                    localGptDataRef.current = null;
                }
                sendJsonMessage({"operate":"STOP","opercode":opercode.stop});
                setOperateLoading(null);
            },
            onCancel() {
              
            },
        });
    },[]);
    window.onmousemove = (e:any) => onMouseMove(e);
    window.onmouseup = (e:any) => onMouseUp(false);

    const [moving, setMoving] = useState(false)
    const [left, setLeft] = useState(600)
    const onMouseDown = (e:any) => {
        setMoving(true);
    }
    const onMouseUp = (e:any) => {
        setMoving(false);
    }
    const onMouseMove = (e:any) => {
        moving && onMove(e);
    }
    const onMove = (e:any) => {
        const left = e.clientX;
        setLeft(left);
    }
    const filterTag = (index:any) => {
        let color = 'blue';
        switch (index) {
            case 'New':
                color = 'cyan';
                break;
            case 'Hot':
                color = 'red';
                break;
            default:
                break;
        }
        return color;
    }
    const getTestPoint = useCallback(() => {
        axios.post('/client/new/queryTestPointPageList',{
            brand:defaultBrand,
            wd:defaultModel.replace(/\s+\[.*?\]/, ''),
            page:1,
        }).then((res:any) => {
            if(res.data.code === 200){
                let list = res.data.data.list;
                let arr:any = [];
                let model = defaultModel.replace(/\s+\[.*?\]/, '');
                for(let i=0; i<list.length; i++){
                    if(list[i].model.toUpperCase() === model.toUpperCase()){
                        arr.push(list[i]);
                    }
                }
                setPointList(arr[0]);
                setShowPointImage(true);
            }
        }).catch((err:any) => {});
    },[defaultBrand,defaultModel]);
    const stopBtn = () => {
        if(operateLoading){
            return <Button type='primary' onClick={stopOper}>{retLang('Stop')} {translateFunItem(operateName)}</Button>
        }else{
            if(activeKey === '1'){
                return <Button loading={operateLoading} type='primary' style={{display:"flex",justifyContent:"center"}} onClick={startOper}>
                    <div className='btnText' style={{width:"auto"}}>
                        {flashLock === 0 && availability===0 ? <LockFilled /> : null}
                        {translateFunItem(flashName)}
                    </div>
                </Button>
            }else{
                return <Button type='primary' disabled>{retLang('SelectFunction')}</Button>
            }
        }
    };
    const handleBrandType = useCallback((val:any) => {
        setBrandTag(val);
    },[]);
    return (
        <div className='unlockIndex'>
            <UnlockGuide></UnlockGuide>
            <div className="unlock-content">
                <div className="unlock-l" style={{width:`${left}px`}}>
                    <div className="brand_model">
                        {/* <div className="block-header">{retLang('brandModel')}</div> */}
                        <div className="select_brand">
                            <div className='select-item'>
                                {
                                    menuCheckId === 4?
                                    <Select
                                    className='brandTypeSelect'
                                    value={brandTag}
                                    style={{ width: 100 }}
                                    onChange={handleBrandType}
                                    options={[
                                        { value: 'model', label: 'Brands'},
                                        { value: 'chip', label:  'Chipsets'},
                                    ]}
                                    />:null
                                }
                                <Select
                                    style={{width:`calc(100% - ${menuCheckId === 4?100:0}px)`}}
                                    disabled={operateLoading}
                                    showSearch
                                    key={defaultBrand}
                                    defaultValue={defaultBrand}
                                    placeholder={
                                        menuCheckId === 6?'Select Tools':
                                        brandTag === 'model'?'Select a Mobiles':'Select a Chipset'
                                    }
                                    optionFilterProp="children"
                                    onChange={onChangeBrand}
                                    onSearch={onSearch}
                                    loading={!brandList}
                                    fieldNames={{
                                        label: "Brand", value: "Brand",
                                    }}
                                    filterOption={(input, option:any) =>
                                        (option.Brand).toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={brandList}>
                                </Select>
                            </div>
                            <div className='select-item'>
                                <Badge.Ribbon style={{display:`${showPoint?'block':'none'}`}} text={
                                    <div className='select_text' onClick={getTestPoint}>
                                        {retLang('TestPoint')}
                                    </div>
                                } color="cyan">
                                    <Select
                                        disabled={operateLoading}
                                        showSearch
                                        key={defaultModel}
                                        defaultValue={defaultModel}
                                        placeholder="Select"
                                        optionFilterProp="value"
                                        optionLabelProp="value"
                                        onChange={onChangeModel}
                                        onSearch={onSearchModel}
                                        loading={!modelList}
                                        filterOption={(input, option:any) =>
                                            (option.value).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            modelList&&modelList.map((item:any,index:any) => {
                                                return <Select.Option key={index} value={item.Device} label={item.Device} point={item.test_point}>
                                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                                        <span className='select_text'>{item.Device}</span>
                                                        <div style={{display:"flex",alignItems:"center"}}>
                                                            {
                                                                item.tags.length>0 && item.tags.map((tag:any,index2:any) => {
                                                                    if(tag === 1){
                                                                        return <Tooltip className='tipBtn' placement='top' title={retLang('NewTag')}>
                                                                        <img key={index2} style={{width:"22px",marginRight:"3px"}} src={newIcon} alt="" />
                                                                        </Tooltip>
                                                                    }else if(tag === 2){
                                                                        return <Tooltip className='tipBtn' placement='top' title={retLang('HotTag')}>
                                                                         <img key={index2} style={{width:"22px",marginRight:"5px"}} src={hotIcon} alt="" />
                                                                        </Tooltip>
                                                                    }else if(tag === 3){
                                                                        return <Tooltip className='tipBtn' placement='top' title={retLang('ExclusiveServices')}>
                                                                         <img key={index2} style={{width:"22px"}} src={no1Icon} alt="" />
                                                                        </Tooltip>
                                                                    }else{
                                                                        return null;
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Badge.Ribbon>
                                {/* <Button type='primary'>{retLang('TestPoint')}</Button> */}
                            </div>
                            <div className='select-item'>
                                <Select
                                    disabled={operateLoading}
                                    showSearch
                                    key={defaultPort}
                                    defaultValue={defaultPort}
                                    placeholder="Select a Prot"
                                    optionFilterProp="children"
                                    onChange={onChangeProt}
                                    onFocus={onGetport}
                                    fieldNames={{
                                        label: "port", value: "port",
                                    }}
                                    filterOption={(input, option:any) =>
                                        (option.port).toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={portList}>
                                </Select>
                            </div>
                            <div className="select-item">
                                <FunctionOthers></FunctionOthers>
                            </div>
                        </div>
                    </div>
                    <div className='function-div'>
                        {
                            localGptDataRef.current?<div className="disableBox"></div>:null
                        }
                        <Function></Function>
                    </div>
                </div>
                <div className="togger_tag" onMouseDown={onMouseDown}></div>
                <div className="unlock-r" style={{width:`calc(100vw - ${left}px)`}}>
                    <div className="unlock-log">
                        <Common></Common>
                        <div className="console_log">
                            <Console ref={consoleRef}></Console>
                        </div>
                        <div className="console_progress">
                            <Progress></Progress>
                        </div>
                        <div className="console_start">
                            {
                                stopBtn()
                                // btnStr!=='STOP'?
                                // <Button loading={operateLoading} type='primary' style={{letterSpacing:"2px",display:"flex",justifyContent:"center"}} onClick={startOper}>
                                //     <div className='btnText' style={{width:"auto"}}>
                                //         {flashLock === 0 && availability===0 ? <LockFilled /> : null}
                                //         {btnStr || translateFunItem(operateName)}
                                //     </div>
                                // </Button>:
                                // <Button type='primary' style={{letterSpacing:"2px"}} onClick={stopOper}>{btnStr}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display:'none'}}>
                <Image
                    src={``}
                    preview={{
                        visible: showPointImage,
                        src: `https://www.${domain}/dimg${pointList?.pic_list_json[0].img_url}`,
                        onVisibleChange(value, prevValue, currentIndex) {
                            setShowPointImage(value);
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default Element;