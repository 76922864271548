import React, { useCallback, useEffect, useRef, useContext,useImperativeHandle,forwardRef } from 'react';
import { ClearOutlined, CopyOutlined, ShareAltOutlined } from "@ant-design/icons"
import { notification,Modal,Checkbox,Tooltip } from 'antd';
import { MyContext } from '@/utils/myContext';
import Share from '@/views/Component/share/share';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"

const opercode:any = operCode;

const Element  = forwardRef( (props: any, ref: any) => {
    let {
        retLang,
        defaultBrand,
        defaultModel,
        operateName,
        domain
    } = useContext(MyContext);
    const [api, contextHolder] = notification.useNotification({
        maxCount: 1,
    });
    const logRef = useRef(null);
    const logtext = useRef(null);
    const {receivedMessages} = useCustomWebSocket();
    const [shareOpen, setShareOpen] = React.useState(false);

    const clearLog = useCallback(() => {
        // dispatch({ type: 'clearMessages' });
        const text:any = logtext.current;
        text.innerText = '';
    },[])

    const copyLog = useCallback(() => {
        const text:any = logtext.current;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text.innerText);
        } else {
            document.execCommand('copy');
        }
        api["success"]({
            message: retLang('Copiedtoclipboard')
        });
    },[api])
    const handleMessage = (msg:any,code:any) => {
        let tag:any = logtext.current;
        const newMessageElement = document.createElement('div');
        newMessageElement.className = 'log-item';
        if(code === 102){
            newMessageElement.className = 'log-item';
        }else if(code === 200){
            newMessageElement.className = 'log-item log-success';
        }else if(code >= 400){
            if(code === 403){
                newMessageElement.className = 'log-item log-warning';
            }else{
                newMessageElement.className = 'log-item log-error';
            }
        }else if(code === 103){
            newMessageElement.className = 'log-item log-warning';
            if(msg === 'QualcommTestPoint'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>${retLang('operatRemark4')}</p>
                    <p>1. ${retLang('Poweroffthephone')}</p>
                    <p>2. ${retLang('operatRemark15')}</p>
                    <p>3. ${retLang('operatRemark6')}</p>
                    <p>4. ${retLang('operatRemark7')}</p>
                    <p>5. ${retLang('operatRemark8')}</p>
                </div>`
                
            }else if(msg === 'QualcommNormal'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>1. ${retLang('operatRemark9')}</p>
                    <p>2. ${retLang('operatRemark10')}</p>
                    <p>3. ${retLang('operatRemark7')}</p>
                    <p>4. ${retLang('operatRemark8')}</p>
                </div>`
            }else if(msg === 'MtkTestPoint'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>${retLang('operatRemark4')}</p>
                    <p>1. ${retLang('Poweroffthephone')}</p>
                    <p>2. ${retLang('operatRemark7')}</p>
                    <p>3. ${retLang('operatRemark15')}</p>
                    <p>4. ${retLang('operatRemark6')}</p>
                    <p>5. ${retLang('operatRemark8')}</p>
                </div>`
            }else if(msg === 'toFastboot'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>${retLang('EnterFastboot')}</p>
                    <p>1.${retLang('Poweroffthephone')}</p>
                    <p>2.${retLang('operatRemark1')}</p>
                    <p>3.${retLang('operatRemark2')}</p>
                    <p>${retLang('operatRemark3')}</p>
                </div>`
            }else if(msg === 'MiRcovery'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>${retLang('Enterrecovery')}</p>
                    <p>1.${retLang('Poweroffthephone')}</p>
                    <p>2.${retLang('operatRemark11')}</p>
                    <p>3.${retLang('operatRemark2')}</p>
                    <p>${retLang('operatRemark12')}</p>
                </div>`
            }else if(msg === 'mtkNormalBrom'){
                msg = `<div class="log-warning">
                    <p>${retLang('Operatingtips')}:</p>
                    <p>1. ${retLang('Poweroffthephone')}</p>
                    <p>2. ${retLang('operatRemark7')}</p>
                    <p>3. ${retLang('operatRemark13')}</p>
                    <p>4. ${retLang('operatRemark8')}</p>
                    <p>${retLang('operatRemark14')}</p>
                </div>`
            }else{
                msg = null;
            }
        }
        newMessageElement.innerHTML = msg;
        tag.appendChild(newMessageElement);
    };
    useImperativeHandle(ref, () => ({
        handleMessage,
        clearLog
    }));
    const onRemindersChange = (e:any) => {
        if(e.target.checked){
            localStorage.setItem('noReminders', 'true');
        }else{
            localStorage.removeItem('noReminders');
        }
    }
    useEffect(() => {
        let result:any = { ...receivedMessages };
        if(result.retcode === 102 && result.log){
            // dispatch({ type: 'addMessage', payload: result.log });
            handleMessage(result.log,result.retcode);
            scrollToBottom();
        }
        if(result.retcode === 200 && result.opercode === opercode.operate){
            if(!localStorage.getItem('noReminders')){
                
            }
        }
        if(result.retcode >= 400 || result.retcode === 200){
            if(result.opercode === opercode.operate && result.retcode != 401){
                // dispatch({ type: 'addMessage', payload: result.operate_info });
                handleMessage(result.operate_info,result.retcode);
                scrollToBottom();
            }
        }
        if(result.retcode === 201 && result.opercode === opercode.operate){
            clearLog();
        }
        if(result.retcode === 100){
            clearLog();
        }
    },[receivedMessages]);
    
    const scrollToBottom = () => {
        const current:any = logRef.current!;
        current.scrollTop = current.scrollHeight;
    }

    const handlerShare = () => {
        setShareOpen(true);
    }
    return (
        <div className="log_scroll" ref={logRef}>
            {contextHolder}
            <div className="log_oper">
                <Tooltip className='tipBtn' placement='top' title={retLang('Share')}>
                    <div className='clear-ico' onClick={handlerShare}>
                        <ShareAltOutlined />
                    </div>
                </Tooltip>
                <Tooltip className='tipBtn' placement='top' title={retLang('Copyall')}>
                    <div className='clear-ico' onClick={copyLog}>
                        <CopyOutlined />
                    </div>
                </Tooltip>
                <Tooltip className='tipBtn' placement='top' title={retLang('Clearall')}>
                    <div className='clear-ico' onClick={clearLog}>
                        <ClearOutlined/>
                    </div>
                </Tooltip>
            </div>
            <div className="log-text" ref={logtext}>
                
           </div>
           <Modal
                open={shareOpen}
                title={
                    <div style={{display:"flex",alignItems:"center"}}><ShareAltOutlined style={{color:"#fff",fontSize:"24px",marginRight:"10px"}} /> Share the good news with your friends!</div>
                }
                footer={null}
                centered
                onCancel={() => setShareOpen(false)}
           >
                <div style={{color:"#fff"}}>
                    <Share 
                        shareRemark={`[${defaultBrand || ''} ${defaultModel || ''} ${operateName || ''}] I've done this successfully at EVONDT TOOLS, you should try it too!`} 
                        btnShareLink={`https://apps.${domain}`}
                    ></Share>
                </div>
           </Modal>
        </div>
    )
})

export default Element;