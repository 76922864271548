import React, { useContext, useEffect, useState, useRef } from 'react';
import { MyContext } from '@/utils/myContext';
import { Select, Skeleton,Input,Button,Modal,Progress,Badge,notification,Checkbox } from 'antd';
import { MobileTwoTone, HddTwoTone, ThunderboltTwoTone, FlagTwoTone, LockFilled, ShareAltOutlined } from '@ant-design/icons';
import "./imeiBox.scss";
import axios from '@/utils/axios';
import Share from '@/views/Component/share/share';
import { Turnstile } from '@marsidev/react-turnstile'
const Element  = (props: any) => {
    let { retLang,domain } = useContext(MyContext);
    const [loading,setLoading] = useState(false);
    const [btnLoading,setBtnLoading] = useState(false);
    const lastNum = useRef(0);
    const [progress,setProgress] = useState<any>(0);
    const [imei,setImei] = useState<any>("");
    const [imeiVerify,setImeiVerify] = useState<any>(null);
    const [serviceId,setServiceId] = useState<any>(null);
    const [timers,setTimers] = useState<any>(null);

    const [turnKey, setTurnKey] = useState<any>(Math.random());
    const [turnToken, setTurnToken] = useState<any>(null);
    const turnRef= useRef<any>(null);
    const [imeiReseult,setImeiReseult] = useState<any>(null);

    const [exampleShow,setExampleShow] = useState<any>(false);
    const [exampleStr,setExampleStr] = useState<any>(null);
    const [lockService,setLockService] = useState<any>(null);
    const [serviceName,setServiceName] = useState<any>(null);
    const [shareOpen, setShareOpen] = useState(false);

    const [api, contextHolder] = notification.useNotification({
        maxCount: 1,
    });
    useEffect(() => {
        // setTimeout(() => {
        //     setLoading(false);
        // },1000)
        getImeiList();
        return () => {
            if(timers){
                clearInterval(timers);
            }
        }
    },[])
    // 声明一个类型
    type options = {
        label:string,
        value:number,
        having_permission:number,
        example:string
    }
    type list = {
        label:string,
        options:options[]
    }
    const [imeiList,setImeiList] = useState<list[]>([]);
    const calc = (imei:any) => {
        var evenArr = [];
        var oddNum = 0;
        for (let i = 0; i < imei.length; i++) {
          if (i % 2 == 1) {
            evenArr.push(imei[i] * 2);
          } else {
            oddNum += Number(imei[i]);
          }
        }
        var step2 = [];
        for (let i = 0; i < evenArr.length; i++) {
          if (evenArr[i] >= 10) {
            step2.push(parseInt(''+evenArr[i] / 10));
            step2.push(evenArr[i] % 10);
          } else {
            step2.push(evenArr[i]);
          }
        }
        var total = 0;
        for (let i = 0; i < step2.length; i++) {
          total += Number(step2[i]);
        }
        total += oddNum;
        lastNum.current = 10 - (total % 10);
        if(lastNum.current == 10){
            lastNum.current = 0;
        }
    };
    
    const verify = (imei:any) => {
        var reg = /^[A-Za-z0-9]+$/
        if(!reg.test(imei)){
            setImeiVerify(retLang('placeEnterImei'));
            return;
        }
        if(imei.length == 15){
            let str14 = imei.slice(0,14);
            let str15 = imei.slice(14,15);
            calc(str14);
            if(lastNum.current != str15){
                setImeiVerify(retLang('InvalidIMEI'));
            }else{
                setImeiVerify(true);
            }
        }else if(imei.length == 12){
            setImeiVerify(true);
        }else if(imei.length == 19 || imei.length == 20){
            setImeiVerify(true);
        }else{
            if(serviceId == 1227){
                setImeiVerify(retLang('InvalidICCID'));
            }else{
                setImeiVerify(retLang('InvalidIMEISerialNumber'));
            }
        }
    } 

    const getImeiList = () => {
        axios.post(`/client/imei/queryImeiPermissionList`,{}).then((result:any) => {
            if(result.data.code == 200){
                if(result.data.data.code == 200){
                    const data:any = filterList(result.data.data.data);
                    setImeiList(data);
                }
            }
        })
    }

    const filterList = (list:any) => {
        let data:any = [];
        list.forEach((item:any) => {
            const imeiType = item.imei_type;
            const serviceId = item.service_id;
            const havingPermission = item.having_permission;
            const example = item.example;
      
            // 查找相同的 imei_type 是否已存在
            const categoryIndex = data.findIndex((category:any) => category.label === imeiType);
      
            if (categoryIndex !== -1) {
              // 如果已存在，将选项添加到现有类别中
              data[categoryIndex].options.push({ 
                label: item.name,
                value: serviceId,
                having_permission: havingPermission,
                example: example,
              });
            } else {
              // 如果不存在，创建新的类别
              data.push({
                label: imeiType,
                options: [
                  {
                    label: item.name,
                    value: serviceId,
                    having_permission: havingPermission,
                    example: example,
                  },
                ],
              });
            }
        });
        return data;
    }
    useEffect(() => {
        if(imeiVerify == true){
            setBtnLoading(true);
            imeiloading();
        }
    },[imeiVerify])

    const imeiChange = (e:any) => {
        setImei(e.target.value);
    }

    const selectService = (value:any,opacity:any) => {
        setServiceName(opacity.label);
        setExampleStr(opacity.example);
        setLockService(opacity.having_permission);
        setServiceId(value);
    }

    const WidGet = () => {
        try{
          return (
            <Turnstile
              key={turnKey}
              ref={turnRef}
              siteKey='0x4AAAAAAAGzzbSBwuTxJ7vA'
              onSuccess={getToken}
              onExpire={() => setTurnKey(Math.random())}
            />
          )
        }catch(err){
    
        }
    }
    const getToken = (token:string) => {
        setTurnToken(token);
    }
    const num:any = (Math.random() * 3 + 1).toFixed(2);
    const incrementPercentage = (intervalTime:any) => {
        const timer = setInterval(() => {
            setProgress((progress:any) => progress + 1);
        }, intervalTime);
        setTimers(timer);
    };
    useEffect(() => {
        if (progress === 42) {
            clearInterval(timers);
            setTimeout(() => {
                incrementPercentage(350 * num);
            },5000)
        }else if (progress === 72) {
            clearInterval(timers);
            incrementPercentage(100 * num);
        }else if (progress === 96) {
            clearInterval(timers);
            getImei();
        }
    },[progress])
    const onRemindersChange = (e:any) => {
        if(e.target.checked){
            localStorage.setItem('noReminders', 'true');
        }else{
            localStorage.removeItem('noReminders');
        }
    }
    useEffect(() => {
        if(imeiReseult){
            if(!localStorage.getItem('noReminders')){
                
            }
        }
    },[imeiReseult])
    const imeiloading = () => {
        incrementPercentage(150 * num);
    }
    const getImei = () => {
        axios.post(`/client/imei/getAdvancedInfoByImei`,{
            imei:imei,
            serviceId:serviceId,
            token:turnToken
        }).then((result:any) => {
            if(result.data.code == 200){
                setProgress(100);
                if(serviceId === 1222){
                    var data = null
                    data = JSON.stringify(result.data.data.result).replaceAll('"', '');
                    data = data.replaceAll('{', '');
                    data = data.replaceAll('}', '');
                    data = data.replaceAll(',','<br />')
                    result.data.data.result = data;
                }
                setImeiReseult(result.data.data);
            }
        })
    }
    const closeProgress = () => {
        setImeiVerify(null);
        setBtnLoading(false);
        setProgress(0);
        clearInterval(timers);
        imeiReseult && setImeiReseult(null);
        turnRef.current?.reset();
    }
    const getExample = () => {
        setExampleShow(true);
    }

    const handlerShare = () => {
        setShareOpen(true);
    }

    return (
        <div className='imeiView' style={{display:"flex",height:"calc(100vh - 78px)"}}>
            {contextHolder}
            <div className="hide_box" style={{display:loading?"block":"none",width:"100%"}}>
                <div className="loading-imei">
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton.Image active style={{marginBottom:"20px"}}/>
                        <Skeleton active />
                    </div>
                </div>
            </div>
            {/* <iframe width={"100%"} style={{left:loading?"-9999px":"0",opacity:loading?"0":"1",}} src={`https://www.${domain}/${language?filterLang(language):'en'}/imei-Check`}></iframe> */}
            <div className="imei_check" style={{display:loading?"none":"block"}}>
                <div className="imei_title">
                    <h1>{retLang('IMEICHECK')}</h1>
                    <p className='imei_remark'>{retLang('ImeiRemark')}</p>
                    <p className='imei_remark'>{retLang('ImeiRemark2')}</p>
                </div>
                <div className="imei_search">
                    
                    <div className="serch_input">
                        <Input status={imeiVerify && imeiVerify!==true?'error':''} 
                            value={imei} 
                            onChange={imeiChange} 
                            maxLength={20}
                            size="large" 
                            placeholder={serviceId===1227?'ICCID':`${retLang('EntImei')} 123456789012347`} />
                        <p style={{color:"#ff4d4f"}}>{imeiVerify}</p>
                    </div>
                    <Badge.Ribbon style={{display:exampleStr?'block':"none"}} text={
                        <div className='select_text' onClick={getExample}>Example</div>
                    } color="cyan">
                        <Select 
                            showSearch 
                            className='serch_select' size="large" 
                            options={imeiList} 
                            onChange={selectService} 
                            placeholder={retLang('SelectService')}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                     </Badge.Ribbon>
                    <Button disabled={!serviceId || !imei} size='large' onClick={() => verify(imei)} className='serch_btn'>
                        {/* {lockService===0?<LockFilled />:null} */}
                        {retLang('CHECKIMEI')}
                    </Button>
                </div>
                <div className='widget'>
                    {WidGet()}
                </div>
                <div className="howtoCheckImei">
                    <div className="howtoCheckImei_title">
                        <h2>{retLang('HowtocheckIMEI')}</h2>
                        <p>{retLang('HowtocheckIMEIremark')}</p>
                    </div>
                    
                    <div className="step">
                        <div className="step_item">
                            <div className="step_item_img">
                                <MobileTwoTone />
                            </div>
                            <div className="step_item_text">
                                <p>{retLang('ImeiCheckSet1')}</p>
                            </div>
                        </div>
                        <div className="step_item">
                            <div className="step_item_img">
                                <ThunderboltTwoTone />
                            </div>
                            <div className="step_item_text">
                                <p>{retLang('ImeiCheckSet2')}</p>
                            </div>
                        </div>
                        <div className="step_item">
                            <div className="step_item_img">
                                <HddTwoTone />
                            </div>
                            <div className="step_item_text">
                                <p>{retLang('ImeiCheckSet3')}</p>
                            </div>
                        </div>
                        <div className="step_item">
                            <div className="step_item_img">
                                <FlagTwoTone />
                            </div>
                            <div className="step_item_text">
                                <p>{retLang('ImeiCheckSet4')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* btnLoading */}
            <Modal 
                open={btnLoading}
                title={imeiReseult?retLang('Successful'):retLang('Inquiriesinprogress')}
                footer={null}
                centered
                onCancel={closeProgress}
                destroyOnClose={true}
                getContainer={false}
                >
                {
                    imeiReseult?
                    <div className='resultImeiBox' style={{position:"relative"}}>
                        <div className='share-ico' onClick={handlerShare}>
                            <ShareAltOutlined />
                        </div>
                        <div className="resultImei" dangerouslySetInnerHTML={{
                            __html:imeiReseult && imeiReseult.result?imeiReseult.result:""
                        }}></div>
                    </div>:
                    <div>
                        <p style={{textAlign:"center",marginBottom:"20px",marginTop:"20px"}}>{retLang('InquiriesRemark')}</p>
                        <Progress 
                            percent={progress}
                            strokeColor={{'0%': '#529AFF','100%': '#529AFF'}}
                            size={[472,36]}
                            showInfo={false} />
                    </div>
                }
            </Modal>
            <Modal
                open={exampleShow}
                title="Example"
                footer={null}
                onCancel={() => setExampleShow(false)}
            >
                <div className="example" style={{height:"70vh",overflowY:"auto"}}>
                    <div dangerouslySetInnerHTML={{__html: exampleStr}} ></div>
                </div>
            </Modal>
            <Modal
                open={shareOpen}
                title={
                    <div style={{display:"flex",alignItems:"center"}}><ShareAltOutlined style={{color:"#fff",fontSize:"24px",marginRight:"10px"}} /> Congratulations, the query was successful!</div>
                }
                footer={null}
                centered
                onCancel={() => setShareOpen(false)}
           >
                <div style={{color:"#fff"}}>
                    <Share 
                        shareRemark={`[Free ${serviceName}] I've done this successfully at EVONDT TOOLS, you should try it too!`}
                        btnShareLink={`https://www.${domain}/imei-check`}
                    ></Share>
                </div>
           </Modal>
        </div>
    )
}

export default Element;