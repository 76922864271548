import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { notification } from 'antd';

const axiosInstance = axios.create({
  baseURL: 'https://v2api.evondt.com',
  // baseURL: 'http://172.20.1.245:10012/',
  // 其他配置
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const accountData:any = localStorage.getItem('accountData');
    try {
      const token:any = JSON.parse(accountData).accessToken;
      config.headers['Authorization'] = 'OAuth ' + token;
    }catch(e) {}
    
    return config;
  },
  (error: AxiosError) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 在这里你可以做一些响应数据的操作
    if(response.data.code !== 200) {
      let local:any = localStorage.getItem("language");
      let localLang:any= local || "en";
      if(response.data.code === 4001) {
        localStorage.removeItem("accountData");
        let path = window.location.pathname;
        if(!path.includes("/login")) {
          window.location.href = '/';
        }
      }else if(response.data.code === 4007) {
        
      }else{
        if(localLang !== "en") {
          if(response.config.url?.includes("get/content/translation")) {  // 防止死循环
            
          }else{
            axiosInstance.post('/dwapi/get/content/translation/',{
              content_key: response.data.msg,
              content_type:"text",
              source_language_code:'en',
              target_language_code:localLang,
              content: response.data.msg
            }).then((res:any) => {
              if(res.data.code === 200){
                notification.warning({
                  message: res.data.data[0],
                });
              }else{
                notification.warning({
                  message: response.data.msg,
                });
              }
            })
          }
        }else {
          notification.warning({
            message: response.data.msg,
          });
        }
      }
    }
    return response;
  },
  (error: AxiosError) => {
    // 处理响应错误，并使用 Ant Design 的 Notification 组件显示错误信息
    notification.error({
      message: error.message,
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;