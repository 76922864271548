import React from 'react'
import { createBrowserRouter,Navigate} from 'react-router-dom'
import Index from '../views/index'
import Login from '../views/Login'
import LoginAccount from '../views/Login/childer/loginAccount/loginAccount'
import LoginType from '../views/Login/childer/loginType/loginType'
import LoginEmail from '../views/Login/childer/loginEmail/loginEmail'
import LoginCode from '../views/Login/childer/loginEmail/loginCode'
import DeviceConnection from '../views/Device/deviceConnection/deviceConnection'
import ConnectStatus from '../views/Device/connectSuccess/connectStatus'
import DeviceInfo from '../views/Device/deviceInfo/deviceInfo'
import InfomationList from '../views/Device/deviceInfo/infomationList/infomationList'
import FirmwareList from '../views/Device/deviceInfo/firmwareList/firmwareList'
import ToolBox from '../views/ToolBox/toolBox'
import UnlockIndex from '../views/Device/unlockCom/unlockIndex/unlockIndex'
import Device from '../views/Device/device'
import ImeiBox from '../views/ImeiBox/imeiBox'
import TestPoint from '../views/PointBox/pointBox'
import Wait from '../views/Device/purchaseCom/wait'
import Code404 from '../views/404/Code404'
import Launch from '../views/Device/Launch/Launch'
import Tutorials from '../views/tutorials/tutorials'

const router = createBrowserRouter([
  {path: "/",element: <Navigate to="/login/loginType" />},
  {path: "/",element: <Index />,children:[
    {path: "/login",element: <Login />,children:[
      {path: "",element: <Navigate to="/login/loginType" />},
      {path: "loginType",element: <LoginType />},
      {path: "loginAccount",element: <LoginAccount />},
      {path: "loginEmail",element: <LoginEmail />},
      {path: "loginCode",element: <LoginCode />},
    ]},
    {path: "/launch",element: <Launch />},
    {path: "/dashboard",element: <Device />,children:[
      {path: "",element: <Navigate to="/dashboard/deviceConnection" />},
      {path: "deviceConnection",element: <DeviceConnection />},
      {path: "ConnectStatus",element: <ConnectStatus />},
      {path: "deviceInfo",element: <DeviceInfo />,children:[
        {path: "",element: <Navigate to="/dashboard/deviceInfo/infomationList" />},
        {path: "infomationList",element: <InfomationList />},
        {path: "firmwareList",element: <FirmwareList />},
      ]},
      {path: "toolBox",element: <ToolBox />},
      {path: "index",element: <UnlockIndex />},
      {path: "common-tools",element: <UnlockIndex />},
      {path: "imei-checker",element: <ImeiBox />},
      {path: "test-point",element: <TestPoint />},
      {path: "tutorials",element: <Tutorials />},
    ]},
    {path: "/wait",element: <Wait />},
  ]},
  {path: "*",element: <Code404 />}
])

export default router
