import React, { useEffect, useState,useContext } from 'react';
import { Button, Input, Table, Tooltip } from 'antd';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

import importIco from "@/assets/icon/button_import.svg";
import refreshIco from "@/assets/icon/button_list_refresh.svg";
import deleteIco from "@/assets/icon/button_delete.svg";
import exportIco from "@/assets/icon/button_export.svg";
import backIco from "@/assets/icon/back.svg";
import fileIco from "@/assets/icon/button_file.svg";
import "./filelistManage.scss"


const { Column } = Table;
const opercode:any = operCode;

const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    // const [currentFolder,setCurrentFolder] = useState("/");
    const [urlValue,setUrlValue] = useState("/");
    const changeUrl = (e:any) => {
        setUrlValue(e.target.value);
    };
    const [fileList,setFileList] = useState([
        // {key:"1","FileName":"IMG20220911210535.jpg","permissions":"-rwxrwx---","DataModeified":"2022-09-11 21:05","FileType":".jpg","FileSize":"3M890K"},
        // {key:"2","FileName":"新建文件夹","permissions":"-rwxrwx---","DataModeified":"2022-09-11 21:05","FileType":"","FileSize":"3M890K"},
    ]);
    useEffect (() => {
        sendJsonMessage({"operate":"LoadResource","devicePath":"/","opercode":opercode.LoadResource});
    },[]);
    useEffect(() => {
        let result:any = { ...receivedMessages };
        if(result.opercode === opercode.LoadResource && result.resource){
            setFileList(result.resource);
            setUrlValue(result.currentpath || "/");
        }
    },[receivedMessages])
    
    // const fileItem:any = fileList.map((item:any) => {
    //     return(
    //       <></>
    //     )
    // });
    const goBack = () => {
        let url = urlValue;
        if(url === "/"){
            return;
        }
        let urlArr = url.split("/");
        urlArr.pop();
        url = urlArr.join("/");
        if(url === ""){
            url = "/";
        }
        sendJsonMessage({"operate":"LoadResource","devicePath":url,"opercode":opercode.LoadResource});
    }
    const onkeyup = (e:any) => {
        if(e.keyCode === 13){
            sendJsonMessage({"operate":"LoadResource","devicePath":urlValue,"opercode":opercode.LoadResource});
        }
    }
    const refresh = () => {
        sendJsonMessage({"operate":"LoadResource","devicePath":urlValue,"opercode":opercode.LoadResource});
    }
    return (
        <div className="fileView">
            <div className="opertion">
                {/* <Button type="primary" size='small'><img src={importIco} alt="" /> 导入</Button>
                <Button type="primary" size='small'><img src={deleteIco} alt="" /> 删除</Button> */}
                <Button type="primary" size='small' onClick={refresh}><img src={refreshIco} alt="" />  {retLang('refresh')}</Button>
            </div>
            <div className="fileList">
                <div className="recentUse">

                </div>
                <div className="fileContent">
                    <div className="fileUrl">
                        <Button className='urlBack' type="primary" onClick={goBack}><img src={backIco} alt="" />  {retLang('goBack')}</Button>
                        <Input value={urlValue} onKeyUp={onkeyup} onChange={changeUrl} />
                    </div>
                    <div className="filelistTable">
                        <Table
                            scroll={{ y: "calc(100vh - 215px)" }}
                            pagination={{ position:['bottomRight'],defaultPageSize:20,showSizeChanger:false}}
                            dataSource={fileList}
                            onRow={(record:any) => {
                                return {
                                  onDoubleClick: (event) => {
                                    if(record.FileType === "" || record.FileType == null){
                                        const devicePath = `${urlValue === "/"?urlValue:urlValue+"/"}${record.FileName}`
                                        sendJsonMessage({"operate":"LoadResource","devicePath":devicePath,"opercode":opercode.LoadResource});
                                    }else{
                                        if(record.permissions[0]==='d'){
                                            const devicePath = `${urlValue === "/"?urlValue:urlValue+"/"}${record.FileName}`
                                            sendJsonMessage({"operate":"LoadResource","devicePath":devicePath,"opercode":opercode.LoadResource});
                                        }
                                    }
                                  },
                                };
                              }}
                            >
                            <Column title="File name" dataIndex="FileName"  ellipsis={{showTitle: false}}
                                render={(FileName,record:any) => (
                                <Tooltip placement="topLeft" title={FileName}>
                                    <div className='fileName'>
                                        <img src={fileIco} alt="" className={record.FileType?'iconHidden':(record.permissions[0]==='d'?'':'iconHidden')} />
                                        {FileName}
                                    </div>
                                </Tooltip>
                                )}/>
                            <Column title="Modify date" dataIndex="DataModeified" />
                            <Column title="Type" dataIndex="FileType"
                            render={(FileType) => (
                                <>{FileType || "Folders"}</>
                            )} />
                            <Column title="Size" dataIndex="FileSize"/>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Element;