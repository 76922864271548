import React, { useEffect, useState, useContext, useRef, useMemo, } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio,Table,Checkbox, Input, Button, Tooltip, Skeleton } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

const opercode:any = operCode;
  

const Element  = React.memo((props: any) => {
    let {
        flash,setFlash,
        flashType,setFlashType,
        flashKey,setFlashKey,
        flashLock,setFlashLock,
        filePath,setFilePath,
        chipFilePath,setChipFilePath,
        gptmap,setGptmap,
        gptdata,setGptdata,
        defaultBrand,
        defaultModel,
        fileFilter,
        retLang,
        translateFunItem,
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    const [showChipInput, setShowChipInput] = useState<boolean>(false);
    
    
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const tableList = useRef<any>([]);
    useEffect(() => {
        let result:any = { ...receivedMessages };
        //获取分区表头
        if(result.retcode === 102 && result.gptmap){
            setGptdata([]);
            let mapList = [];
            for(let i=0; i<result.gptmap.length; i++){
                if(result.gptmap[i] !== 'Checked'){
                    mapList.push({
                        title: result.gptmap[i],
                        dataIndex: result.gptmap[i],
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (data:any) => (
                            <Tooltip placement="topLeft" title={data}>
                                {data}
                            </Tooltip>
                        )
                    })
                }
            }
            setGptmap(mapList);
            tableList.current = [];
            setGptdata([]);
        }
        //获取分区列表
        if(result.retcode === 102 && result.gptdata){
            let dataItem = result.gptdata[0];
            tableList.current = [...tableList.current,dataItem];
        }
        //渲染分区列表
        if(result.retcode === 105){
            let checkArr:any = [];
            for(let i=0; i<tableList.current.length; i++){
                if(tableList.current[i].Checked === "1"){
                    checkArr.push(i);
                }
            }
            setSelectedRowKeys(checkArr);
            setGptdata(tableList.current);
        }

        // 读字库
        if(result.opercode === opercode.SelectFolder && result.retcode === 200){
            setFilePath(result.FilePatch);
        }

        // 写字库
        if(result.opercode === opercode.SelectFiles && result.retcode === 200){
            setFilePath(result.FilePatch);
            if(selectIndex !== null){
                gptdata[selectIndex].File_s = result.filename[0];
                setGptdata(gptdata);
                setSelectIndex(null);
            }
            sendJsonMessage({ 
                operate:"loadFirmware",
                brand:defaultBrand,
                model:defaultModel,
                files:result.filename,
                FilePath:result.FilePatch,
                opercode: opercode.loadFirmware
            });
        }
        if(result.opercode === opercode.SelectChipFiles && result.retcode === 200){
            setChipFilePath(result.FilePatch+result.filename[0]);
        }

        // 表格内读字库
        if(result.opercode === opercode.SaveFiles && result.retcode === 200){
            if(selectIndex !== null){
                gptdata[selectIndex].File_s = result.filename[0];
                setGptdata(gptdata);
                setSelectIndex(null);
            }
            setFilePath(result.FilePatch);
        }
    },[receivedMessages])

    const onChange = (e: RadioChangeEvent) => {
        setFlashType(e.target.value);
        flash.map((item:any) => {
            if(item.label === e.target.value){
                setFlashKey(item.type);
                setFlashLock(item.permission);
            }
        })
    };
    const flashItem:any = flash && flash.map((item:any,index:any) => {
        return(
            <Radio disabled={item.Values==="0"} value={item.label} key={index}>{translateFunItem(item.name || item.label)}</Radio>
        )
    });

    const [selectIndex,setSelectIndex] = useState<any>(null);

    const checkFile = () => {
        if(flashKey === "dump"){
            sendJsonMessage({ operate:"SelectFolder",Format:fileFilter(), patch:filePath,opercode: opercode.SelectFolder});
        }else if(flashKey === "write"){
            sendJsonMessage({ operate:"SelectFiles",Format:fileFilter(), patch:filePath,opercode: opercode.SelectFiles});
        }
    }
    const checkChipFile = () => {
        sendJsonMessage({ operate:"SelectFiles",Format:fileFilter(), patch:filePath,opercode: opercode.SelectChipFiles});
    }
    const changePath = (e:any) => {
        setFilePath(e.target.value);
    }
    const changeChipPath = (e:any) => {
        setChipFilePath(e.target.value);
    }
    const dataSource = useMemo(() => {
        return gptdata;
    },[gptdata])

    useEffect(() => {
        // 全部转换成小写
        let brand = defaultBrand?defaultBrand.toLowerCase():"";
        if(brand.includes("mediatek") || brand.includes("qualcomm")){
            setShowChipInput(true);
        }else{
            setShowChipInput(false);
        }
    },[defaultBrand])

    return (
        <div className='function-flash'>
            {/* <div style={{height:"25px"}}>
                {
                    flash?<Radio.Group onChange={onChange} value={flashType}>
                        {flashItem}
                    </Radio.Group>
                    :<Skeleton paragraph={{rows:1}} title={false}></Skeleton>
                }
            </div> */}
            <div className="gptDiv" style={{height:`calc(100vh - ${showChipInput?'358':'320'}px)`}}>
                <div className='gptTable'>
                    <div className='thead'>
                        <div className='tr'>
                            <div className='th tr-check'>
                                <Checkbox
                                    indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length}
                                    onChange={
                                        (e:any) => {
                                            if(e.target.checked){
                                                let arr:any = [];
                                                for(let i=0; i<dataSource.length; i++){
                                                    arr.push(i);
                                                    dataSource[i].Checked = "1";
                                                }
                                                setGptdata(dataSource);
                                                setSelectedRowKeys(arr);
                                            }else{
                                                for(let i=0; i<dataSource.length; i++){
                                                    dataSource[i].Checked = "0";
                                                }
                                                setGptdata(dataSource);
                                                setSelectedRowKeys([]);
                                            }
                                        }
                                    }
                                    checked={selectedRowKeys.length === dataSource.length && dataSource.length !== 0}
                                />

                            </div>
                            {
                                gptmap.map((item:any,index:any) => {
                                    return(
                                        <div className='th' key={index}>{item.title}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='tbody' style={{height:`calc(100vh - 330px - ${showChipInput?'70px':'32px'})`}}>
                        {
                            dataSource.map((item:any,index:any) => {
                                return(
                                    <div className='tr' key={index} onDoubleClick={
                                        () => {
                                            if(flashKey==="dump"){
                                                setSelectIndex(index);
                                                sendJsonMessage({ operate:"SaveFiles",Format:"Text files (*.bin)|*.BIN|Any file (*.*)|*.*",patch:filePath,opercode: opercode.SaveFiles});
                                            }else if(flashKey==="write"){
                                                setSelectIndex(index);
                                                sendJsonMessage({ operate:"SelectFiles",Format:"Text files (*.bin)|*.BIN|Any file (*.*)|*.*",patch:filePath,opercode: opercode.SelectFiles});
                                            }
                                        }
                                    }>
                                        <div className='td tr-check'>
                                            <Checkbox
                                                onChange={
                                                    (e:any) => {
                                                        let arr:any = [...selectedRowKeys];
                                                        if(e.target.checked){
                                                            arr.push(index);
                                                            dataSource[index].Checked = "1";
                                                        }else{
                                                            arr = arr.filter((item2:any) => {
                                                                return item2 !== index;
                                                            })
                                                            dataSource[index].Checked = "0";
                                                        }
                                                        setGptdata(dataSource);
                                                        setSelectedRowKeys(arr);
                                                    }
                                                }
                                                checked={selectedRowKeys.includes(index)}
                                            />
                                        </div>
                                        {
                                            gptmap.map((item2:any,index2:any) => {
                                                return(
                                                        <div className='td' key={index2}>{item[item2.dataIndex]}</div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                showChipInput ? (
                    <div className="table_input">
                        <Input size='small' placeholder={
                            defaultBrand==="MediaTek Tools"?
                            retLang('selectMtkFile'):
                            retLang('selectQualcommFile')
                        } 
                        value={chipFilePath} onChange={changeChipPath} />
                        <Button type='primary' size='small' onClick={checkChipFile}>...</Button>
                    </div>
                ) : null
            }
            <div className="table_input">
                <Input size='small' disabled={flashKey !== "dump" && flashKey !== "write"} placeholder={retLang('Selectfirmware')} value={filePath} onChange={changePath} />
                <Button type='primary' disabled={flashKey !== "dump" && flashKey !== "write"} size='small' onClick={checkFile}>...</Button>
            </div>
        </div>
    )
}) 

export default Element;