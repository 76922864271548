import React, { useEffect, useState,useContext } from 'react';
import { Button, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

import importIco from "@/assets/icon/button_import.svg";
import refreshIco from "@/assets/icon/button_list_refresh.svg";
import deleteIco from "@/assets/icon/button_delete.svg";
import uninstallIco from "@/assets/icon/button_uninstall.svg";
import Logo from "@/assets/icon/icon_vedio.svg";

import "./videoManage.scss"

const opercode:any = operCode;
interface DataType {
  key: React.Key;
  appname: string;
  apptype: string;
  appversion: string;
  appPath: string;
}
const columns: ColumnsType<DataType> = [
  {title: 'Music Name',dataIndex: 'appname',
    render: (appname) => (
      <div className='appName'><img src={Logo} alt="" />{appname}</div>
    ),
  },
  {title: 'Modify time',dataIndex: 'apptype'},
  {title: 'Type',dataIndex: 'appversion'},
  {title: 'Size',dataIndex: 'appPath',
    ellipsis: {
      showTitle: false,
    },
    render: (appPath) => (
      <Tooltip placement="topLeft" title={appPath}>
        {appPath}
      </Tooltip>
    ),
  },
];

const Element  = (props: any) => {
  let {
    retLang
  } = useContext(MyContext);
  const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
  const [data,setData] = useState([
    // {key:"1",appname: "vedio",apptype: "Sagit",appversion: "Snapdragon 450",appPath: "256GB"},
  ] as DataType[]);
  useEffect (() => {
    setData([]);
    getList();
  },[]);
  const getList = () => {
    sendJsonMessage({"operate":"LoadResource","devicePath":"/sdcard/Movies","opercode":opercode.LoadResource});
  }
  useEffect(() => {
    let result:any = { ...receivedMessages };
    if(result.opercode === opercode.LoadResource && result.applist){
      setData(result.applist)
    }
  },[receivedMessages])
  return (
    <div className="fileView">
      <div className="opertion">
        {/* <Button type="primary" size='small'><img src={importIco} alt="" /> 导入</Button>
        <Button type="primary" size='small'><img src={uninstallIco} alt="" /> 备份</Button>
        <Button type="primary" size='small'><img src={deleteIco} alt="" /> 删除</Button>
        <Button type="primary" size='small'><img src={refreshIco} alt="" /> 刷新</Button> */}
      </div>
      <div className="videoTable">
        <Table
          scroll={{ y: "calc(100vh - 160px)" }}
          rowSelection={{
            type: "checkbox"
          }}
          pagination={{ position:[]}}
          columns={columns}
          dataSource={data}
        />
      </div>
    </div>
  )
}
export default Element;