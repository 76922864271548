import React, { useState, useCallback, useEffect, useContext } from 'react';
import type { MenuProps } from 'antd';
import { Drawer, Menu } from 'antd';
import { MyContext } from '@/utils/myContext';
import operCode from "@/utils/opercode.json"
import "./fileDrawer.scss"
import closeBtn from "@/assets/icon/button_expand.svg"
import appIcon from "@/assets/icon/button_application.svg";
import picIcon from "@/assets/icon/button_pic.svg";
import musicIcon from "@/assets/icon/button_music.svg";
import videoIcon from "@/assets/icon/button_video.svg";
import fileIcon from "@/assets/icon/button_filemanagement.svg";

import AppView from "../fileManage/appManage/appManage";
import PicView from "../fileManage/picManage/picManage";
import VideoView from "../fileManage/videoManage/videoManage";
import AudioView from "../fileManage/audioManage/audioManage";
import FilelistView from "../fileManage/filelistManage/filelistManage";

const opercode:any = operCode;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const Element = (props: any) => {

  let { retLang,setNoServiceOpen,connectCode } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("1-1" as any);

  const items: MenuItem[] = [
    getItem(retLang('AppGames'), '1', <img src={appIcon} alt="" />, [
      getItem(retLang('Installedapps'), '1-1'),
      getItem(retLang('Systemapps'), '1-2')
    ]),
    getItem(retLang('Photo'), '2', <img src={picIcon} alt="" />, [
      getItem(retLang('Camerafilms'), '2-1'),
      getItem(retLang('WeChatpictures'), '2-2'),
      getItem(retLang('Screenshots'), '2-3'),
    ]),
    getItem(retLang('Audio'), '3', <img src={musicIcon} alt="" />),
  
    getItem(retLang('Video'), '4', <img src={videoIcon} alt="" />),
  
    getItem(retLang('FileList'), '5', <img src={fileIcon} alt="" />),
  ];

  const getFile = useCallback(() => {
    if(connectCode !== 'Open'){
      setNoServiceOpen(true);
      return;
    }
    setOpen(true);
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    
  }, []);

  const menuSelect: MenuProps['onSelect'] = (item: any) => {
    setFileKey(item.key);
  }

  const fileView = () => {
    if(fileKey === "1-1"){
      return(<AppView appType={0}></AppView>)
    }else if (fileKey === "1-2"){
      return(<AppView appType={1}></AppView>)
    }else if (fileKey === "2-1"){
      return(<PicView></PicView>)
    }else if (fileKey === "2-2"){
      return(<PicView></PicView>)
    }else if (fileKey === "2-3"){
      return(<PicView></PicView>)
    }else if (fileKey === "3"){
      return(<AudioView></AudioView>)
    }else if (fileKey === "4"){
      return(<VideoView></VideoView>)
    }else if (fileKey === "5"){
      return(<FilelistView></FilelistView>)
    }else{
      return(<AppView appType={1}></AppView>)
    }
  }


  return (
    <div className="fileDrawer">
      <div onClick={getFile} className="file-button">
        <span>{retLang('FileManagement')}</span>
      </div>
      <Drawer
        title="File Management"
        placement="bottom"
        height="100%"
        open={open}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0, background: "#464955" }}
      >
        <div className="manageBox">
          <div className="file-left-menu">
            <div className="file-title">{retLang('FileManagement')}</div>
            <div className="file-menu">
              <Menu
                defaultSelectedKeys={['1-1']}
                defaultOpenKeys={['1']}
                mode="inline"
                theme="dark"
                items={items}
                onSelect={menuSelect}
              />
            </div>
          </div>
          <div className="file-right-box">
            <div className="file-manage-header">
              <div className="file-top-menu"></div>
              <div className='closeBtn' onClick={onClose}>{retLang('Close')}<img src={closeBtn} alt="" /></div>
            </div>
            <div className="file-content">
              {fileView()}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Element;