import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Checkbox, Modal } from 'antd';
import './loginEmail.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { MailOutlined,CloseCircleOutlined } from '@ant-design/icons';

import LicensingAgreement from '@/views/Component/agreement/LicensingAgreement';
import { MyContext } from '@/utils/myContext';

import useCustomWebSocket from '@/utils/websocket';
import axios from '@/utils/axios';


const LoginAccount = () => {
  const {sendJsonMessage} = useCustomWebSocket();
  useEffect(() => {
    sendJsonMessage({"operate":"GetComputerHardWare","opercode":999});
  },[])
  let {
    language,retLang,domain,ipInfo
} = useContext(MyContext);
  const navigate = useNavigate();
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [form] = Form.useForm();

  const openAgreement = (e:CheckboxChangeEvent) => {
    let val = e.target.checked
    setIsModalOpen(val);
    
  };
  const handleOk = () => {
    setAgreementChecked(true)
    setIsModalOpen(false);
  }
  const handleClose = () => {
    setAgreementChecked(false);
    setIsModalOpen(false);
  }
  const accountLogin = (data : any) => {
    if(!agreementChecked){
      setIsModalOpen(true);
      return;
    }
    setLoading(true);
    var obj = {
      loginAccount:form.getFieldValue("email")
    }
    axios.post('/client/new/sendEmailClientLoginCode',obj).then((res:any) => {
      setLoading(false);
      if(res.data.code === 200){
        localStorage.setItem("account",obj.loginAccount);
        navigate('/login/loginCode');
      }else{
        
      }
    }).catch((err:any) => {
      setLoading(false);
    })
  }
  const goBack = () => {
    navigate('/login/loginType');
  }
  const goAccount = () => {
    navigate('/login/loginAccount');
}
    return (
        <div className="loginAccount">
          {/* <div className="goBack" onClick={ goBack }><LeftOutlined /> {retLang("goBack")}</div> */}
          <Form
          colon={false}
          name="basic"
          form={form}
          style={{ width: 300 }}
          onFinish={accountLogin}
          autoComplete="off"
          requiredMark={false}
          >
            <Form.Item name="email">
              <Input prefix={<MailOutlined className='iconFont' />} />
            </Form.Item>
            <Form.Item name="agreement" className='remember agreement'>
              <Checkbox checked={agreementChecked} onChange={openAgreement}><span className='checkbox_text'>{retLang("EndUserLicenceAgreement")}</span></Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" loading={loading} htmlType="submit" block>
                {retLang("SignInwithEmail")}
              </Button>
            </Form.Item>
            <p className="loginRemark">{retLang('OrYoucan')} <span onClick={goAccount}>{retLang('loginwithyourpassword')}.</span></p>
            <p className="remark" style={{fontSize:'14px'}}>
              <a target='_blank' href={`https://account.${domain}/user-register`}>{
                (ipInfo && ipInfo?.country!=="CN" && ipInfo?.type!=="hosting")?retLang('Signupbetaaccount'):retLang('SignUp')
              }</a>
            </p>
          </Form>
          <Modal title=" " 
          closeIcon={<CloseCircleOutlined />}
          open={isModalOpen} 
          onOk={handleOk} 
          onCancel={handleClose}
          okText={retLang("Accept")}
          cancelText = {retLang("Reject")}
          centered={true}
          >
            <LicensingAgreement></LicensingAgreement>
          </Modal>
        </div>
    )
}

export default LoginAccount;