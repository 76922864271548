import React, { useEffect } from 'react';
import ModelCom from "./deviceModelComponent/deviceModelComponent";
import InformationCom from "./deviceInformation/deviceInformation";
import "./deviceInfo.scss"
const Element  = (props: any) => {
    return (
        <div className="viewModel">
            <div className='indexBox'>
                <ModelCom />
                <InformationCom />
            </div>
        </div>
    )
}

export default Element;