import React, { useState, useContext, useEffect } from 'react';
import { Popover, Badge, Button, Modal } from 'antd';
import { ExportOutlined,CloseCircleOutlined,EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons'

import { useNavigate, useLocation } from 'react-router-dom';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"

import "./footerMenu.scss"
import buttonNotice from "@/assets/icon/button_notice.svg";
import buttonNoticeNo from "@/assets/icon/button_notice_no.svg";
import buttonSet from "@/assets/icon/button_set.svg";
import buttonUser from "@/assets/icon/button_user.svg";
import buttonClose from "@/assets/icon/button_close.svg";
import SettingView from "./footerComponent/settingView"
import NoticeView from "./footerComponent/noticeView"

const opercode:any = operCode;

const Element = () => {
    let {
        retLang,
        sdkVersion,
        filterType,
        userInfo,
        getUserInfo,
        maskData,
        domain,
        isModalOpen, setIsModalOpen,
        language,
        socket,setSocket,
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const isLogin = location.pathname.includes('/dashboard');
    const [open, setOpen] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [showPhone, setShowPhone] = useState(true);

    const noticeHide = () => setOpen(false);
    const handleOpenChange = (newOpen: boolean) => setOpen(newOpen);

    
    const content = (
        <div className='notice-content'>
          <div className="notice-header">
            <span>{retLang('Notice2')}</span>
            <CloseCircleOutlined onClick={noticeHide} />
          </div>
          <NoticeView />
        </div>
    );
    const logout = () => {
        sendJsonMessage({"operate":"STOP","opercode":opercode.stop});
        axios.get('/client/new/signOut').then((res:any) => {
            socket.disconnect();
            navigate('/login/loginType');
            localStorage.removeItem('accountData');
            setUserOpen(false);
            setSocket(null);
        })
    };
    const [userOpen, setUserOpen] = useState(false);
    const userHide = () => setUserOpen(false);
    const handleUserOpenChange = (newOpen: boolean) => {
        if(newOpen){
            getUserInfo();
        }
        setUserOpen(newOpen);
    };
    
    const userContent = (
        <div className="notice-content">
            <div className="notice-header">
                <span>{retLang('Account')}</span> 
                <CloseCircleOutlined onClick={userHide} />
            </div>
            <div className="userFunction">
                <div className="userItem">
                    <span className="user-label">{retLang('UserName')}</span>
                    <span className='user-value'>@{userInfo.userName}</span>
                </div>
                <div className="userItem">
                    <span className="user-label">{retLang('Email')}</span>
                    <span className='user-value'>
                        {maskData(userInfo.loginAccount,showEmail)}
                        {
                            showEmail?<EyeOutlined className='clickIcon' onClick={() => setShowEmail(false)} />
                            :<EyeInvisibleOutlined className='clickIcon' onClick={() => setShowEmail(true)} />
                        }
                    </span>
                </div>
                <div className="userItem">
                    <span className="user-label">{retLang('PhoneNumber')}</span>
                    <span className='user-value'>
                        {userInfo.areaCode} {maskData(userInfo.phone,showPhone)}
                        {
                            showPhone?<EyeOutlined className='clickIcon' onClick={() => setShowPhone(false)} />
                            :<EyeInvisibleOutlined className='clickIcon' onClick={() => setShowPhone(true)} />
                        }
                    </span>
                </div>
                <div className="userItem">
                    <span className="user-label">{retLang('Plan')}</span>
                    <span className='user-value'>{`${filterType(userInfo.userAttr)} ${retLang('Plan')}`}</span>
                </div>
                {
                    (userInfo.userAttr === 1 || userInfo.userAttr === 3) ? <div className="userItem">
                        <span className="user-label">{retLang('ExpireTime')}</span>
                        <span className='user-value'>
                            {
                                userInfo?.expireDateStr?<span>
                                    {(language==='zh_CN' || language==='zh_TW')?userInfo.expireDateStr2:userInfo.expireDateStr}
                                </span>:
                                <span>--</span>
                            }
                        </span>
                    </div>:null
                }
                {/* <div className="userItem">
                    <span className="user-label">{retLang('LoginIp')}</span>
                    <span className='user-value'>{userInfo.lastLoginIp}</span>
                </div> */}
            </div>
            <div className="logOut">
                <Button type='primary' icon={<ExportOutlined />} onClick={logout}>{retLang('Logout')}</Button>
            </div>
        </div>
    )

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const seeAgree = () => {
        window.open(`https://www.${domain}/release-notes`);
    }
    return (
        <div className='footerMenu'>
            <div className="footer_left" onClick={seeAgree}>{sdkVersion?`${retLang('Currentversion')} ${sdkVersion}`:''}</div>
            <div className="footer_right">
                {
                    isLogin && <Popover content={content} 
                        destroyTooltipOnHide={true}
                        trigger="click" 
                        arrow={false} 
                        open={open} 
                        onOpenChange={handleOpenChange}>
                        <div className={`footer_button ${open?"footer_button_active":""}`}><img src={buttonNotice} alt="" />{retLang('Notice2')}</div>
                    </Popover>
                }
                {isLogin && <div className="footer_button" onClick={showModal}><img src={buttonSet} alt="" />{retLang("Settings")}</div>}
                {
                    isLogin && <Popover content={userContent} 
                        trigger="click" 
                        arrow={false} 
                        open={userOpen} 
                        onOpenChange={handleUserOpenChange}>
                        <div className={`footer_button ${userOpen?"footer_button_active":""}`}><img src={buttonUser} alt="" />{retLang("Account")}</div>
                    </Popover>
                }
            </div>
            <Modal width="1080px" 
            className='settingModal'
            centered={true}
            title={retLang('Settings')}
            closeIcon={<CloseCircleOutlined style={{fontSize:"20px"}} />}
            open={isModalOpen} 
            destroyOnClose={true}
            footer 
            onCancel={handleCancel}>
                <SettingView></SettingView>
            </Modal>
        </div>
    )
}

export default Element;