import React, { useEffect, useRef } from 'react';
import axios from '@/utils/axios';
const Element = (props: any) => {
    const htmlRef = useRef<any>(null);
    useEffect(() => {
        let local: any = localStorage.getItem("language");
        let localLang: any = local || "en";
        if (localLang && localLang !== 'en') {
            if(htmlRef.current){
                axios.post('/dwapi/get/content/translation/', {
                    content_key: "device_pleasenote_3",
                    content_type: "html",
                    source_language_code: 'en',
                    target_language_code: localLang,
                    content: htmlRef.current.innerHTML
                }).then((res: any) => {
                    if (res.data.code === 200) {
                        try{
                            htmlRef.current.innerHTML = res.data.data;
                        }catch(e){}
                    }
                })
            }
        }
    }, [])
    return (
        <div ref={htmlRef} className='word_text' style={{height:'auto'}}>
            <h2>Notice on Account Login and Device Binding</h2>
            <p>Dear User</p>
            <p>In order to improve the security of your account and provide a more personalized service, we will be introducing new rules for account login and device binding in the near future. Below are the relevant rules and requirements:</p>
            <p>Account Login:</p>
            <p>1. Your account can only be logged in on one device at any one time. If your account is logged in on a new device, the previously logged in account will automatically be taken offline.</p>
            <p>Device Binding:</p>
            <p>1. Each user account can be tied to a maximum of 5 devices. Once an account is tied to a device, our software can only be used on those devices.</p>
            <p>2. After your account is bound to a device, if you need to change or add a bound device, you need to wait at least 60 minutes before doing so. This is to protect your account security and prevent frequent device changes.</p>
            <p>3. Please make sure that the device you are tied to is one that you personally own and use on a regular basis. This will help keep your account secure and avoid unnecessary hassles.</p>
            <p>4. We will use the hardware unique ID generated on your computer to identify the device. We are committed to strictly protecting the privacy of your data and will not use this information for other purposes.</p>
            <p>5. If your device is lost or stolen, please contact our customer service immediately so that we can unbind your device in time to protect your account.</p>
            <p>We realize that this may cause you some inconvenience, but please understand that this is to improve the security of your account and the quality of our service. If you have any questions about this or need assistance, please feel free to contact our customer service team.</p>
            <p>Thank you for your support and understanding.</p>
            <p>Good luck with your business.</p>
        </div>
    )
}

export default Element;