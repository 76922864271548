import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import rrweb, { record, EventType } from 'rrweb';
import axios from '@/utils/axios';
import { MyContext } from '@/utils/myContext';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json";

const opercode:any = operCode;
const RecordReplayComponent = () => {
  const { defaultBrand, defaultModel, operateName } = useContext(MyContext);
  const {receivedMessages} = useCustomWebSocket();
  const targetRef = useRef<HTMLDivElement>(null);
  const events = useRef<EventType[]>([]);
  const [uniqueKey] = useState("EVONDT CLOUD-" + Math.random().toString(36).substring(2) + Date.now().toString(36));
  const recordRef = useRef<any>(null);
  var browserInfo = getBrowserInfo();
  const otherInfo = {
    browserLanguage: navigator.language,
    resolution: `${window.screen.width}x${window.screen.height}`,
    browser: browserInfo.name,
    browserVersion: browserInfo.version,
  }
  function getBrowserInfo() {
    // 获取用户代理字符串
    var userAgent = navigator.userAgent;

    // 创建一个正则表达式来匹配常见的浏览器标识符
    var browserInfoRegex = /(edge|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+(\.\d+)*)/i;

    // 使用正则表达式匹配浏览器信息
    var match = userAgent.match(browserInfoRegex);

    if (match) {
        var browserName = match[1]; // 匹配到的浏览器名称
        var browserVersion = match[2]; // 匹配到的浏览器版本号

        return {
            name: browserName,
            version: browserVersion
        };
    }

    // 如果未匹配到浏览器信息，则返回未知
    return {
        name: "Unknown",
        version: "Unknown"
    };
  }
  useEffect(() => {
    const targetNode = targetRef.current;
    if (targetNode) {
      recordRef.current = record({
        emit(event: EventType, payload: any) {
          events.current.push(event);
        },
        maskAllInputs: true,
      });
    }

    setInterval(() => {
      if (events.current.length > 0) {
        saveEvens({
          brand: defaultBrand,
          model: defaultModel,
          functionKey: operateName,
          rrWebType: 'BrowsingRecords',
        });
      }
    }, 10000);
  }, []);
  // 操作完成记录
  useEffect(() => {
    let result:any = { ...receivedMessages };
    if(result.opercode === opercode.operate){
      if(result.retcode === 200){
        
      }
    }
  }, [receivedMessages]);
  
  const saveEvens = (data:any) => {
    const eventsCopy = [...events.current];
    events.current = [];
    axios.post('/client/new/rrweb/add', {
      rrWebKey: uniqueKey,
      rrwebEventsRecords: eventsCopy,
      other: otherInfo,
      ...data,
    }).then((res:any) => {})
  };

  return <div ref={targetRef}></div>;
};

export default RecordReplayComponent;
