import React, { useState, useContext, useEffect } from 'react';
import { Select, Divider, Dropdown } from 'antd';
import {GlobalOutlined} from '@ant-design/icons';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
const opercode:any = operCode;
import './languageFixed.scss';
const { Option } = Select;
const Element  = (props: any) => {
    let {
        language,setLanguage,
        retLang
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    useEffect (() => {
        
    }, []);
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.updateLanguage && result.retcode === 200){
            // localStorage.setItem("language",language);
        }
    }, [receivedMessages]);
    const [languageList,setLanguageList] = useState<any>([
        {label:'English',key:'en'},
        {label:'Español',key:'es-ES'},
        {label:'বাংলা',key:'bn-BD'},//新增
        {label:'عربى',key:'ar-SA'},
        {label:'Русский',key:'ru-RU'},
        {label:'Português',key:'pt-BR'},//新增
        {label:'日本語',key:'ja-JP'},
        {label:'Deutsch',key:'de-DE'},
        {label:'Français',key:'fr-FR'},//新增
        {label:'हिंदी',key:'hi-IN'}, //新增
        {label:'اردو',key:'ur-PK'}, //新增
        {label:'فارسی',key:'fa-IR'}, //新增
        {label:'Melayu',key:'ms-MY'}, //新增
        {label:'Bahasa Indonesia',key:'id-ID'},
        {label:'Tiếng Việt',key:'vi-VN'},
        {label:'한국어',key:'ko-KR'},
        {label:'Türkçe',key:'tr-TR'},
        {label:'Italiano',key:'it-IT'},
        {label:'แบบไทย',key:'th-TH'}, //新增
        {label:'Nederlands',key:'nl-NL'},
        {label:'中文 (简体)',key:'zh_CN'},
        {label:'中文 (繁體)',key:'zh_TW'},
    ]);
    const changeLanguage = (item:any) => {
        let value = item.key;
        localStorage.setItem("language",value);
        setLanguage(value);
        let sendValue;
        if(value === "zh_CN"){
            sendValue = "zh-CN";
        }else if(value === "zh_TW"){
            sendValue = "zh-TW";
        }else if(value === "en"){
            sendValue = "en-US";
        }else{
            sendValue = value;
        }
        sendJsonMessage({"operate":"updateLanguage","opercode":opercode.updateLanguage,"language":sendValue});
        // window.location.reload();
    }
    return (
        <div className='language_fixed'>
            <Dropdown menu={{ 
                items:languageList,
                onClick:changeLanguage
            }}
            rootClassName='language_fixed_dropdown'
            trigger={['click']}>
                <GlobalOutlined />
            </Dropdown>
        </div>
    )
}

export default Element;