import React, { useContext,useState,useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Button, Modal, Form, Input,notification,Progress,Select } from 'antd';
import { CopyOutlined,ExclamationCircleFilled,EyeInvisibleOutlined,EyeOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import Share from '@/views/Component/share/share';

 import countryCodes from '@/utils/countryCodes.json';

const { confirm } = Modal;
const Element  = (props: any) => {
    const navigate = useNavigate();
    let {
        retLang,
        filterType,
        userInfo,setUserInfo,
        getUserInfo,
        maskData,
        setIsModalOpen,
        setIsShowPurchase,
        domain,
        language
    } = useContext(MyContext);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [shareLink,setShareLink] = useState('');
    const [passNum,setPassNum] = useState(0);
    const editUseritem = useRef<any>({});
    const password = useRef<any>(null);
    const [showEmail,setShowEmail] = useState(true);
    const [showPhone,setShowPhone] = useState(true);

    const [isPasswordlOpen, setIsPasswordlOpen] = useState(false);
    const showModal = () => {
        setIsPasswordlOpen(true);
    };
    useEffect (() => {
        getUserInfo();
        axios.post('/client/invite/getAgentInviteUserUrl').then((res:any) => {
            if (res.data.code === 200) {
                setShareLink(res.data.data);
            }
        })
    }, []);
    const copyLog = useCallback(() => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareLink);
        } else {
            document.execCommand('copy');
        }
        api["success"]({
            message: retLang('Copiedtoclipboard')
        });
    },[api,shareLink])
    const shareText = retLang('WelcometoEvondt');

    const handleConfirm = (data:any) => {
        setLoading(true);
        axios.post('/client/new/updatePwd', data ).then((res:any) => {
            setLoading(false);
            if (res.data.code === 200) {
                setIsPasswordlOpen(false);
            }
        }).catch((err:any) => {
            setLoading(false);
        });
    };
    const changePassword = (e:any) => {
        password.current = e.target.value;
    }
    const Deregister = () => {
        confirm({
            title: retLang('Deregisteraccount'),
            icon: <ExclamationCircleFilled style={{color:'#ff4d4f'}} />,
            content: <div className='DeregisterContent'>
                <p>{retLang('Deregisteraccountcontent')}</p>
                <p>{retLang('Deregisteraccountcontent2')}</p>
                <div style={{marginTop:"25px"}}>
                    <div>{retLang('Password')}</div>
                    <div>
                        <Input.Password onChange={changePassword} />
                    </div>
                </div>
            </div>,
            okText: retLang('Deactivateyouraccount'),
            cancelText: retLang('Cancel'),
            okType: 'danger',
            // centered:true,
            className: "edit_info_modal",
            onOk() {
                axios.post('/client/new/accountCancel',{
                    password:password.current
                }).then((res:any) => {
                    if (res.data.code === 200) {
                        localStorage.removeItem('accountData');
                        localStorage.removeItem('rememberData');
                        setIsModalOpen(false);
                        navigate('/');
                    }
                }).catch((err:any) => {
                    
                });
            },
            onCancel() {
                
            },
        });
    };


    const handleCancel = () => {
        setIsPasswordlOpen(false);
    };

    const passChange = (e:any) => {
        let value = e.target.value;
        var modes = 0;
        if (value.length < 5) return setPassNum(modes);
        if (/\d/.test(value)) modes+=25;
        if (/[a-z]/.test(value)) modes+=25;
        if (/[A-Z]/.test(value)) modes+=25;
        if (/\W/.test(value)) modes+=25;
        setPassNum(modes);
    }

    const changeUserInfo = (e:any) => {
        editUseritem.current.value = e.target.value;
    };
    const onChange = (value: string) => {
        editUseritem.current.code = value;
    };
    
    const onSearch = (value: string) => {
        
    };

    const editUserInfo = useCallback((type:string) => {
        let remark = '';
        let subData = {};
        if(type === 'userName'){
            remark = retLang('EditUserName');
        }
        if(type === 'phone'){
            remark = retLang('EditPhoneNumber');
        }
        confirm({
            title: remark,
            icon:null,
            content: (
                <div className='delete_device_box' style={{marginRight:'-34px'}}>
                    <div className="delete_device_input">
                        <p style={{margin:"8px 0"}}>{type === 'userName'?retLang('UserName'):retLang('PhoneNumber')}</p>
                        {
                            type === 'userName' ? 
                            <Input defaultValue={userInfo.userName} onChange={changeUserInfo} prefix={'@'} /> 
                            : <div>
                                <Input defaultValue={userInfo.phone} onChange={changeUserInfo}
                                    addonBefore={
                                        <Select
                                            style={{ width: 100 }}
                                            showSearch
                                            defaultValue={userInfo.areaCode}
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            filterOption={(input:any, option:any) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={countryCodes.map((item:any) => {
                                                return {
                                                    value: item.dial_code,
                                                    label: item.dial_code,
                                                };
                                            })}
                                        />
                                    }
                                />
                            </div> 
                        }
                        
                    </div>
                </div>
            ),
            okText: retLang('Confirm'),
            cancelText: retLang('Cancel'),
            okButtonProps: {
                style: {
                    background: '#4990cb',
                    borderColor: '#4990cb',
                    color: '#fff',
                    padding: '7px 30px',
                    height: 'auto',
                }
            },
            cancelButtonProps: {
                type: 'text',
                style: {
                    color: '#fff',
                    background: 'none',
                    border: 'none',
                    padding: '7px 30px',
                    height: 'auto',
                }
            },
            className: "edit_info_modal",
            onOk() {
                if(type === 'userName'){
                    subData = {
                        userName:editUseritem.current.value,
                    }
                }
                if(type === 'phone'){
                    subData = {
                        userName:userInfo.userName,
                        phone:editUseritem.current.value,
                        areaCode:editUseritem.current.code
                    }
                }
                axios.post('/client/new/userinfo/updateMerchantInfo',subData).then((res:any) => {
                    if(res.data.code === 200){
                        getUserInfo();
                    }
                }).catch((err:any) => {
        
                })
            },
            onCancel() {
                
            },
        });
    },[editUseritem]);

    const selectPlan = () => {
        setIsModalOpen(false);
        setIsShowPurchase(true)
    }

    return (
        <div className='content_item account_box'>
            {contextHolder}
            <div className="setting_list">
                <div className="setting_title">{retLang('Myaccount')}</div>
                <Divider />
                <div className="content_account_item">
                    <div>
                        <div className="account_item_title">{retLang('UserName')}</div>
                        <div className="content_text">@{userInfo.userName}</div>
                    </div>
                    <Button type='primary' style={{color:"#fff"}} onClick={() => editUserInfo('userName')}>{retLang('Edit')}</Button>
                </div>
                <div className="content_account_item">
                    <div>
                        <div className="account_item_title">{retLang('Email')}</div>
                        <div className="content_text">
                            {maskData(userInfo.loginAccount,showEmail)}
                            {
                                showEmail?<EyeOutlined className='clickIcon' onClick={() => setShowEmail(false)} />
                                :<EyeInvisibleOutlined className='clickIcon' onClick={() => setShowEmail(true)} />
                            }
                        </div>
                    </div>
                </div>
                <div className="content_account_item">
                    <div>
                        <div className="account_item_title">{retLang('PhoneNumber')}</div>
                        <div className="content_text">
                            {userInfo.areaCode} {maskData(userInfo.phone,showPhone)}
                            {
                                showPhone?<EyeOutlined className='clickIcon' onClick={() => setShowPhone(false)} />
                                :<EyeInvisibleOutlined className='clickIcon' onClick={() => setShowPhone(true)} />
                            }
                        </div>
                    </div>
                    <Button type='primary' style={{color:"#fff"}} onClick={() => editUserInfo('phone')}>{retLang('Edit')}</Button>
                </div>
                <div className="content_account_item">
                    <div>
                        <div className="account_item_title">{retLang('Plan')}</div>
                        <div className="content_text">{`${filterType(userInfo.userAttr)} ${retLang('Plan')}`}</div>
                    </div>
                    <Button type='primary' style={{color:"#fff"}} onClick={() => selectPlan()}>{retLang('SelectPlan')}</Button>
                </div>
                {
                    (userInfo.userAttr === 1 || userInfo.userAttr === 3) ? 
                    <div className="content_account_item">
                        <div>
                            <div className="account_item_title">{retLang('ExpireTime')}</div>
                            <div className="content_text">
                                {
                                userInfo?.expireDateStr?<span>
                                    {(language==='zh_CN' || language==='zh_TW')?userInfo.expireDateStr2:userInfo.expireDateStr}
                                </span>:
                                <span>--</span>
                                }
                            </div>
                        </div>
                    </div> : null
                }
                <div className="content_account_item">
                    <div className="account_item_title">{retLang('ChangePassword')} </div>
                    <div className="content_text"><Button type='primary' style={{color:"#fff"}} onClick={showModal}>{userInfo?.needSetLoginPwd==="1"?retLang("SetPassword"):retLang('Change')}</Button></div>
                </div>
                <div className="content_account_item">
                    <div className="account_item_title">{retLang('Deregisteraccount')} </div>
                    <div className="content_text"><Button type='primary' style={{color:"#fff"}} onClick={Deregister}>{retLang('Deregister')}</Button></div>
                </div>
                <div className="account_item">
                    <div className="account_item_title">{retLang('InviteUser')}</div>
                    <div style={{marginTop:"-30px",width:"500px"}}>
                        <Share 
                            shareRemark={retLang("WelcometoEvondt")}
                            btnShareLink={`https://account.${domain}/user-register`}
                        ></Share>
                    </div>
                    {/* <p style={{textAlign:'right'}}>成功邀请一位用户获得七天免费使用</p> */}
                </div>
            </div>
            <Modal 
            title={retLang('ChangePassword')}
            width={400} 
            open={isPasswordlOpen} 
            footer 
            onCancel={handleCancel}
            destroyOnClose={true}
            centered={true}
            >
                <Form labelCol={{ span: 24 }} onFinish={handleConfirm}>
                    {
                        userInfo?.needSetLoginPwd==="1"?null:
                        <Form.Item label={retLang('OldPassword')} name="oldPwd">
                            <Input.Password />
                        </Form.Item>
                    }
                    
                    <Form.Item label={retLang('NewPassword')} name="newPwd">
                        <Input.Password onChange={passChange} />
                    </Form.Item>
                    <p>
                        {
                            passNum > 0 ? <Progress strokeColor={{'0%': '#599ED6','100%': '#529AFF'}} percent={passNum} showInfo={false} /> : null
                        }
                    </p>
                    <Form.Item label={retLang('ConfirmPassword')} name="confirmPwd">
                        <Input.Password  />
                    </Form.Item>
                    <Form.Item style={{textAlign:"right"}}>
                        <Button style={{
                            color: '#fff',
                            background: 'none',
                            border: 'none',
                            padding: '7px 30px',
                            height: 'auto',
                            marginRight: 10 
                        }} 
                        onClick={handleCancel} 
                        >
                            {retLang('Cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}
                        style={{
                            background: '#4990cb',
                            borderColor: '#4990cb',
                            color: '#fff',
                            padding: '7px 30px',
                            height: 'auto',
                        }}>
                            {retLang('Confirm')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Element;