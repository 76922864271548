import React, { useEffect, useState, useContext } from 'react';
import { Tour } from 'antd';
import type { TourProps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '@/utils/myContext';
import "./launch.scss"
import axios from '@/utils/axios';
const Element  = (props: any) => {
    const navigate = useNavigate();
    const {
        domain,userInfo
    } = useContext<any>(MyContext);
    const [launchOpen, setLaunchOpen] = useState(false);
    const [steps,setSteps] = useState<TourProps['steps']>([]);
    useEffect(() => {
        let launchStatus = localStorage.getItem("launch");
        if(launchStatus){
            axios.get('/client/new/appsGuide/getAppsGuideInfo').then((res:any) => {
                if(res.data.code === 200){
                    const data = res.data.data.detailList;
                    if(data && data.length > 0 && userInfo && userInfo.guideSwitch===1){
                            const steps = data.map((item:any) => {
                                return {
                                    title: '',
                                    description: <div className='launchPage' onClick={() => hrefUrl(item.url)}>
                                        <div className="launchImage" style={{
                                            backgroundImage: `url(https://v2api.${domain}/client/new/file/image/view?fileName=${item.urlPic}&type=1)`
                                        }}></div>
                                    </div>,
                                    nextButtonProps: { children: <RightOutlined /> },
                                    prevButtonProps: { children: <LeftOutlined /> },
                                    target: null,
                                }
                            })
                            setSteps(steps);
                            setLaunchOpen(true);
                        
                    }else{
                        navigate("/dashboard/deviceConnection");
                    }
                }else{
                    navigate("/dashboard/deviceConnection");
                }
            }).catch(() => {
                navigate("/dashboard/deviceConnection");
            })
        }
        return () => {
            localStorage.removeItem("launch");
        }
    }, [userInfo])
    const hrefUrl = (url:any) => {
        window.open(url);
    }
    const closeStep = () => {
        setLaunchOpen(false);
        localStorage.removeItem("launch");
        navigate("/dashboard/deviceConnection");
    }
    return (
        <div className='launchView'>
            <Tour
            open={launchOpen} 
            type="primary" 
            onClose={closeStep} 
            steps={steps} 
            rootClassName="launchBox" />
        </div>
    )
}

export default Element;