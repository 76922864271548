import en from '@/lang/en.js';
import zh_CN from '@/lang/zh_CN.js';
import zh_TW from '@/lang/zh_TW.js';
import es_ES from '@/lang/es-ES.js';
import bn_BD from '@/lang/bn-BD.js';
import ar_SA from '@/lang/ar-SA.js';
import ru_RU from '@/lang/ru-RU.js';
import pt_BR from '@/lang/pt-BR.js';
import ja_JP from '@/lang/ja-JP.js';
import de_DE from '@/lang/de-DE.js';
import fr_FR from '@/lang/fr-FR.js';
import hi_IN from '@/lang/hi-IN.js';
import ur_PK from '@/lang/ur-PK.js';
import fa_IR from '@/lang/fa-IR.js';
import ms_MY from '@/lang/ms-MY.js';
import id_ID from '@/lang/id-ID.js';
import vi_VN from '@/lang/vi-VN.js';
import ko_KR from '@/lang/ko-KR.js';
import tr_TR from '@/lang/tr-TR.js';
import it_IT from '@/lang/it-IT.js';
import th_TH from '@/lang/th-TH.js';
import nl_NL from '@/lang/nl-NL.js';

export default {
    "en": en,
    "zh_CN": zh_CN,
    "zh_TW": zh_TW,
    "es-ES": es_ES,
    "bn-BD": bn_BD,
    "ar-SA": ar_SA,
    "ru-RU": ru_RU,
    "pt-BR": pt_BR,
    "ja-JP": ja_JP,
    "de-DE": de_DE,
    "fr-FR": fr_FR,
    "hi-IN": hi_IN,
    "ur-PK": ur_PK,
    "fa-IR": fa_IR,
    "ms-MY": ms_MY,
    "id-ID": id_ID,
    "vi-VN": vi_VN,
    "ko-KR": ko_KR,
    "tr-TR": tr_TR,
    "it-IT": it_IT,
    "th-TH": th_TH,
    "nl-NL": nl_NL,
}

// {label:'English',key:'en'},
// {label:'Español',key:'es-ES'},
// {label:'বাংলা',key:'bn-BD'},//新增
// {label:'عربى',key:'ar-SA'},
// {label:'Русский',key:'ru-RU'},
// {label:'Português',key:'pt-BR'},//新增
// {label:'日本語',key:'ja-JP'},
// {label:'Deutsch',key:'de-DE'},
// {label:'Français',key:'fr-FR'},//新增
// {label:'हिंदी',key:'hi-IN'}, //新增
// {label:'اردو',key:'ur-PK'}, //新增
// {label:'فارسی',key:'fa-IR'}, //新增
// {label:'Melayu',key:'ms-MY'}, //新增
// {label:'Bahasa Indonesia',key:'id-ID'},
// {label:'Tiếng Việt',key:'vi-VN'},
// {label:'한국어',key:'ko-KR'},
// {label:'Türkçe',key:'tr-TR'},
// {label:'Italiano',key:'it-IT'},
// {label:'แบบไทย',key:'th-TH'}, //新增
// {label:'Nederlands',key:'nl-NL'},
// {label:'中文 (简体)',key:'zh_CN'},
// {label:'中文 (繁體)',key:'zh_TW'},