import React, { useEffect, useRef } from 'react';
import axios from '@/utils/axios';
const Element = (props: any) => {
    const htmlRef = useRef<any>(null);
    useEffect(() => {
        let local: any = localStorage.getItem("language");
        let localLang: any = local || "en";
        if (localLang && localLang !== 'en') {
            if(htmlRef.current){
                axios.post('/dwapi/get/content/translation/', {
                    content_key: "client_software_license_agreement",
                    content_type: "html",
                    source_language_code: 'en',
                    target_language_code: localLang,
                    content: htmlRef.current.innerHTML
                }).then((res: any) => {
                    if (res.data.code === 200) {
                        try{
                            htmlRef.current.innerHTML = res.data.data;
                        }catch(e){}
                    }
                })
            }
        }
    }, [])
    return (
        <div ref={htmlRef} className='word_text'>
            <h2>SOFTWARE LICENSE AGREEMENT</h2>
            <p>IMPORTANT: Please read the following terms and conditions carefully before downloading, installing, or using Software Product Name (hereinafter referred to as "Software"). By clicking "Accept", or by downloading, installing, or using this Software, you agree to the following terms and conditions.</p>
            <p>1. License Grant: This Agreement grants you a personal, non-exclusive, non-transferable right to use this Software.</p>
            <p>2. Restrictions: Unless explicitly permitted by Australian law, you may not reverse engineer, decompile, or disassemble this Software. You may not rent, lend, publicly display, sell, or distribute this Software.</p>
            <p>3. Ownership: The Software is protected by copyright laws and international treaties. The ownership and intellectual property rights of this Software belong to Company Name.</p>
            <p>4. Termination: If you breach any of the terms of this Agreement, this Agreement will automatically terminate. In this case, you must immediately cease using this Software and destroy all copies.</p>
            <p>5. Disclaimer: The Software is provided "as is", with no express or implied warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement, unless explicitly required by Australian law. In no event shall Company Name be liable for any direct, indirect, special, incidental, or consequential damages arising from the use or inability to use this Software, unless explicitly required by Australian law.</p>
            <p>If you do not agree to the terms and conditions of this Agreement, you are not permitted to download, install, or use this Software.</p>
            <p></p>
            <p></p>
        </div>
    )
}

export default Element;