import React, { useState,useContext } from 'react';
import { GlobalOutlined, UserOutlined,QuestionCircleFilled,SettingFilled,LockFilled,ApiFilled,FundOutlined,RocketOutlined,ContainerFilled,ProfileOutlined,ControlOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom';
import LanguageCom from './generalTemplate/languageCom'
import AccountCom from './accountCom'
import OrdersCom from './ordersCom'
import GeneralCom from './generalCom'
import DiagnosisCom from './diagnosisCom'
import DeviceCom from './deviceCom'
import DriverCom from './driverCom'
import HelpCom from './helpCom'
import NotificationsCom from './notificationsCom'
import { MyContext } from '@/utils/myContext';


const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const location = useLocation();
    const isLogin = location.pathname.includes('/dashboard');
    const menuList =
    [
        {title:retLang('General') ,icon:<SettingFilled />,itemContent:<GeneralCom />,id:0},
        {title:retLang('Notifications') ,icon:<ControlOutlined />,itemContent:<NotificationsCom />,id:8},
        {title:retLang('Myaccount') ,icon:<UserOutlined />,itemContent:<AccountCom />,id:1},
        {title:retLang('Plan') ,icon:<ProfileOutlined />,itemContent:<OrdersCom />,id:2},
        // {title:retLang('FileSavePath'),icon:<ContainerFilled />,itemContent:<FilesAddress />,id:2},
        // {title:retLang('Privacyandsecurity'),icon:<LockFilled />,itemContent:<AfetyCom />,id:3},
        {title:retLang('Devices'),icon:<ApiFilled />,itemContent:<DeviceCom />,id:4},
        {title:retLang('Driver'),icon:<RocketOutlined />,itemContent:<DriverCom />,id:5},
        {title:retLang('Diagnostics'),icon:<FundOutlined />,itemContent:<DiagnosisCom />,id:6},
        {title:retLang('AboutSupport'),icon:<QuestionCircleFilled />,itemContent:<HelpCom />,id:7},
    ];
    const [checkedMenu, setCheckedMenu] = useState(menuList[0]);
    const menu = menuList.map((item:any) => {
        return (
            <div className="setting_menu_item" onClick={() => checkMenu(item)} key={item.id}>
                {item.icon}
                <span>{item.title}</span>
            </div>
        )
    })
    const checkMenu = (item:any) => {
        setCheckedMenu(item);
    }
    return (
        <div className='setting'>
            <div className="setting_menu">
                {menu}
            </div>
            <div className="setting_model">
                {checkedMenu.itemContent}
            </div>
            
        </div>
    )
}

export default Element;