import React, { useEffect, useContext } from 'react';
import { Checkbox,Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
import { preloadAll } from 'react-loadable';

const opercode:any = operCode;
  
const Element  = React.memo( (props: any) => {
    let {
        retLang,
        others,setOthers,
        preloader,setPreloader
    } = useContext(MyContext);

    const {receivedMessages} = useCustomWebSocket();

    useEffect(() => {
        let result:any = { ...receivedMessages };
    },[receivedMessages])

    const onChange = (e: CheckboxChangeEvent) => {
        setPreloader(e.target.checked?'1':'0');
    };
    
    const othersItem:any = others.map((item:any,index:any) => {
        return(
            <div key={index} style={{display:"flex",alignItems:"center"}}>
                <Checkbox checked={preloader==='1'?true:false} onChange={onChange} key={index}>{item.name || item.label}</Checkbox>
                <Tooltip className='tipBtn' placement='top' title={retLang('preloadertobrom')}>
                    <QuestionCircleOutlined style={{color:"#B6BDC9"}} />
                </Tooltip>
            </div>
        )
    });
    return (
        <div className='function-reset'>
            {othersItem}
        </div>
    )
})

export default Element;