import React, { useState, useRef, useEffect } from 'react';

const VerificationCodeInput = (props:any) =>{
  const { submit } = props;
  const [codes, setCodes] = useState(new Array(6).fill(''));

  const inputs = useRef<any>([]);
  
  const handleChange = (e:any, index:any) => {
    const newCodes = [...codes];
    newCodes[index] = e.target.value;
    setCodes(newCodes);

    
  };
  const handlePaste = (e:any) => {
    const pasteData = e.clipboardData.getData('text/plain').slice(0, 6); // 获取前6个字符
    const newCodes = pasteData.split('');
    setCodes(prev => [...newCodes, ...prev.slice(newCodes.length)]);
  };
  useEffect(() => {
    inputs.current[0].focus();
  }, []);
  useEffect(() => {
    if (codes.join('').length === 6) {
      submit(codes.join(''));
    }
    for(var i = 0; i < codes.length; i++) {
      if(codes[i] === '') {
        inputs.current[i].focus();
        break;
      }
      if(i === codes.length - 1) {
        inputs.current[i].focus();
      }
    }
  }, [codes]);
  

  return (
    <div className='codeInput'>
      <div className="focusBox" onClick={
        () => {
          for(var i = 0; i < codes.length; i++) {
            if(codes[i] === '') {
              inputs.current[i].focus();
              break;
            }
            if(i === codes.length - 1) {
              inputs.current[i].focus();
            }
          }
        }
      }></div>
      {codes.map((code, index) => 
        <input 
          autoComplete="off"
          key={index}
          ref={ref => inputs.current[index] = ref}
          maxLength={1}
          value={code}
          onChange={e => handleChange(e, index)}
          onPaste={handlePaste}
          onKeyDown={e => {
            if(e.keyCode === 8){
              const newCodes = [...codes];
              if(index > 0 && index < 5){
                newCodes[index-1] = '';
                setCodes(newCodes);
                inputs.current[index-1].focus()
              }else if(index === 5){
                if(newCodes[index] === ''){
                  newCodes[index-1] = '';
                  setCodes(newCodes);
                  inputs.current[index-1].focus()
                }
              }
            }
          }}
        />  
      )}
    </div>
  )

}

export default VerificationCodeInput;