import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from 'antd';
import "./reseller.scss"
import axios from '@/utils/axios';
import { MyContext } from '@/utils/myContext';
const Element  = (props: any) => {
    let { retLang,domain } = useContext(MyContext);
    const [resellerList, setResellerList] = useState([]);//reseller列表

    useEffect(() => {
        getPayList();
    },[])
    const getPayList = () => {
        axios.post('/client/new/userinfo/agentInfoPageList',{size:1000}).then((res:any) => {
            if(res.data.code === 200){
                let list = res.data.data.records;
                if(list){
                    setResellerList(list);
                }
            }
        })
    }
    const contactNode = (type:any,text:any,link?:any) => {
        return (
            <>
            {
                text && <Tooltip title={
                    <>
                    {
                        type==="wechat"?
                        <div className="contactTooltip">
                            <img style={{width:"100%"}} src={`https://v2api.${domain}/client/new/userinfo/agent/image/view?fileName=${link}`} alt="" />
                        </div>:
                        <div className="contactTooltip">
                            <span style={{textTransform:"uppercase"}}>{type}: </span>
                            <span>{text}</span>
                        </div>
                    }
                    </>
                    } placement="right" mouseEnterDelay={0.3}>
                    <div className={`contactItem ${type}`}>
                        {
                            (link && type!=="wechat")?<a href={link} target="_blank" className="itemText">{text}</a>:
                            <span>{text}</span>
                        }
                    </div>
                </Tooltip>
            }
            </>
        )
    }
    return (
        <div className='resellerView'>
            {
                resellerList.map((item:any, index:number) => {
                    return (
                        <div className='reseller-item' key={index}>
                            <div className="headimg">
                                {
                                    item.headImg?<img src={`https://v2api.${domain}/client/new/file/image/view?fileName=${item.headImg}&type=2`} alt="" />:
                                    <img src={require('@/assets/reseller/co-defale.jpg')} alt="" />
                                }
                            </div>
                            <h2>{item.name}</h2>
                            {/* <p className='itemCenter'>{item.country}</p> */}
                            <div className="contactDetails">
                                {contactNode('phone',item.phone)}
                                {contactNode('whatsapp',item.whatsApp,item.whatsAppUrl)}
                                {contactNode('facebook',item.facebook,item.facebookUrl)}
                                {contactNode('telegram',item.telegram,item.telegramUrl)}
                                {contactNode('twitter',item.twitter,item.twitterUrl)}
                                {contactNode('wechat',item.wechatAccount,item.wechatAccountQrcodeImg)}
                                {contactNode('skype',item.skype,item.skypeUrl)}
                                {contactNode('INS',item.instagram,item.instagramUrl)}
                                {contactNode('vk',item.vk,item.vkUrl)}
                                {contactNode('youtube',item.youtube,item.youtubeUrl)}
                                {contactNode('zalo',item.zalo,item.zaloUrl)}
                                {contactNode('viber',item.viber,item.viberUrl)}
                                {contactNode('icq',item.icq,item.icqUrl)}
                                {contactNode('website',item.website,item.websiteUrl)}
                                {contactNode('email',item.email,item.emailUrl)}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Element;