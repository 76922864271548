import React, { useCallback, useEffect, useState,useContext } from 'react';
import { Button, Table, Tooltip, Modal, message } from 'antd';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

import importIco from "@/assets/icon/button_import.svg";
import refreshIco from "@/assets/icon/button_list_refresh.svg";
import uninstallIco from "@/assets/icon/button_uninstall.svg";
import backupIco from "@/assets/icon/button_backup.svg";
import Logo from "@/assets/icon/icon_application.svg";

import "./appManage.scss"

const { Column } = Table;
const { confirm } = Modal;
const opercode:any = operCode;
// const remote = require('@electron/remote');
// const dialog = remote.dialog;

const Element  = (props: any) => {
  let {
    retLang
} = useContext(MyContext);
  const {appType} = props;
  const [messageApi, contextHolder] = message.useMessage();
  const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
  const [operIndex,setOperIndex] = useState(0);
  const uninstallApp = (record:any,index:any) => {
    setOperIndex(index)
    confirm({
      title: 'Do you want to uninstall this app?',
      icon: "",
      content: '',
      onOk() {
        sendJsonMessage({"operate":appType===1?"light_uninstal":"uninstall_android_app","appname":record.appname,"opercode":opercode.uninstall_android_app})
      },
      onCancel() {
        
      },
    });
  }
  const getApp = useCallback(() => {
    setData([]);
    sendJsonMessage({"operate":"load_app_list","systemapp":appType,"opercode":opercode.load_app_list});
  },[appType])
  interface DataType {
    key: React.Key;
    appname: string;
    apptype: string;
    appversion: string;
    appPath: string;
  }
  const [data,setData] = useState([
    // {key:"1",appname: "Xiaomi Mi 5",apptype: "Sagit",appversion: "Snapdragon 450",appPath: "256GB"},
  ] as DataType[]);
  useEffect (() => {
    getApp();
    return () => {
      
    }
  },[appType]);
  useEffect(() => {
    let result:any = { ...receivedMessages };
    if(result.opercode === opercode.load_app_list && result.applist){
      setData((pre:any) => [result.applist[0],...pre])
    }
    if(result.retcode === 200){
      if(result.opercode === opercode.uninstall_android_app || result.opercode === opercode.light_uninstal){
        data.splice(operIndex,1);
        setData([...data]);
        messageApi.open({
          type: 'success',
          content: 'The application has been successfully removed from the phone',
        });
      }
    }
    if(result.retcode === 200 && result.opercode === opercode.install_android_app){
      messageApi.open({
        type: 'success',
        content: 'App has been successfully installed on the phone',
      });
      getApp();
    }
    if(result.opercode === opercode.SelectFiles && result.retcode === 200){
      const file = `${result.FilePatch}${result.filename[0]}` ;
      if(file){
        sendJsonMessage({"operate":"install_android_app","apppath":file,"opercode":opercode.install_android_app});
      }
    }
  },[receivedMessages])
  const refresh = () => {
    getApp();
  }

  const installApk = () => {
    sendJsonMessage({ operate:"SelectFiles",Format:"APK file (*.APK)|*.apk", opercode: opercode.SelectFiles});
  }
  return (
    <div className="fileView">
      {contextHolder}
      <div className="opertion">
        {appType === 0?<Button type="primary" size='small' onClick={installApk}><img src={importIco} alt="" />{retLang('Import Installation')} </Button>:''}
        {/* <Button type="primary" size='small'><img src={backupIco} alt="" /> 备份</Button> */}
        {/* <Button type="primary" size='small'><img src={uninstallIco} alt="" /> 卸载</Button> */}
        <Button type="primary" size='small' onClick={refresh}><img src={refreshIco} alt="" /> {retLang('refresh')}</Button>
      </div>
      <div className="appTable">
        <Table
          scroll={{ y: "calc(100vh - 215px)" }}
          rowSelection={{
            type: "checkbox"
          }}
          pagination={{ position:['bottomRight'],defaultPageSize:20,showSizeChanger:false}}
          dataSource={data}
        >
          <Column title="Application Name" dataIndex="appname" ellipsis={{showTitle: false}}
            render={(appname) => (
              <Tooltip placement="topLeft" title={appname}>
                <div className='appName'><img src={Logo} alt="" />{appname}</div>
              </Tooltip>
            )}/>
          <Column title="Type" dataIndex="apptype" />
          <Column title="Versions" dataIndex="appversion" />
          <Column title="Application Path" dataIndex="appPath" ellipsis={true}
            render={(appPath) => (
              <Tooltip placement="topLeft" title={appPath}>
                {appPath}
              </Tooltip>
            )}/>
          <Column title="Operation"
            render={(_: any, record: DataType, index:number) => (
              <Button type="primary" size='small' onClick={() => uninstallApp(record, index )}>卸载</Button>
            )}/>
        </Table>
      </div>
    </div>
  )
}
export default Element;