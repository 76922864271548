import React, { useState, useEffect } from 'react';
import { Badge } from 'antd';
import axios from '@/utils/axios';
import { Modal,Skeleton,Empty } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import buttonNoticeNo from "@/assets/icon/button_notice_no.svg";
const Element  = (props: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notice, setNotice] = useState<any>({});

    const [noticeList, setNoticeList] = useState<any>(null)
    useEffect(() => {
        // /client/new/message/readMessage
        // axios.get('/client/new/message/readMessage');
        axios.get('/client/new/message/getMessageListByUserType').then(async (res:any) => {
            if(res.data.code === 200){
                try{
                    let local: any = localStorage.getItem("language");
                    let localLang: any = local || "en";
                    if (localLang && localLang !== 'en') {
                        let list = res.data.data;
                        let newList:any = [];
                        for(let i=0;i<list.length;i++){
                            await axios.post('/dwapi/get/content/translation/', {
                                content_key: 'notice-' + list[i].id,
                                content_type: "json",
                                source_language_code: 'en',
                                target_language_code: localLang,
                                content: list[i]
                            }).then((ress: any) => {
                                if (res.data.code === 200) {
                                    let newItem = ress.data.data;
                                    try{
                                        newItem.noticeContent = newItem.noticeContent.replace(/< p/g,'<p');
                                    }catch(e){}
                                    newList.push(newItem);
                                }
                            })
                        }
                        setNoticeList(newList);
                    }else{
                        setNoticeList(res.data.data);
                    }
                }catch(e){}
            }
        })
    },[]);
    const viewNotice = (item:any) => {
        setNotice(item);
        setIsModalVisible(true);
    }
    const oncancel = () => {
        setIsModalVisible(false);
    }
    return (
        <div className="notice_list">
            {
                noticeList === null?
                <div style={{padding:"15px"}}> <Skeleton active /></div>:
                noticeList.length === 0?
                <Empty description={false} style={{marginTop:"30px",opacity:0.6}} />:
                noticeList.map((item:any,index:any) => {
                    return(
                        // notice_item_no
                        <div onClick={() => viewNotice(item)} className={`notice_item ${item.unread?"":""}`} key={index}>
                            <div className="notice-icon"><img src={buttonNoticeNo} alt="" /></div>
                            <div className="notice-data">
                                <Badge dot={item.unread} color="#599ED6" offset={[3, 5]}>
                                    <div className="notice-title">{item.masterTitle}</div>
                                </Badge>
                                <div className="notice-remark">{item.subTitle}</div>
                            </div>
                        </div>
                    )
                })
            }
            <Modal title={notice.masterTitle}
            closeIcon={<CloseCircleOutlined />}
            getContainer={false}
            zIndex={1031}
            centered={true}
            width={800}
            open={isModalVisible} 
            footer={null}
            onCancel={oncancel}>
                <div className="message-contain">
                    <div className="message-subtitle">{notice.subTitle}</div>
                    <div className="message-content" dangerouslySetInnerHTML={{__html: notice.noticeContent}}></div>
                    <div className="message-time">{notice.noticeTime}</div>
                </div>
            </Modal>
        </div>
    )
}

export default Element;