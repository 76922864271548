import React, { useContext,useState } from 'react';
import { Divider,Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import { MyContext } from '@/utils/myContext';

import PrivacyPolicy from '@/views/Component/agreement/privacyPolicy';
import TermsConditions from '@/views/Component/agreement/termsConditions';
import LicensingAgreement from '@/views/Component/agreement/LicensingAgreement';
const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const [agreeName, setAgreeName] = useState<any>(null);//1:隐私政策 2:服务协议
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    }
    const handleClose = () => {
        setIsModalOpen(false);
    }
    const seeAgree = (type:number) => {
        setAgreeName(type);
        setIsModalOpen(true);
    }
    return (
        <div className='afety_box'>
            {/* <div className="setting_list"> */}
                {/* <div className="setting_title">{retLang('Privacyandsecurity')}</div> */}
                <Divider />
                {/* <div className="content_item">
                    <div className="content_item_title">统计分析服务</div>
                    <div className="content_item_text">
                        共享匿名数据以帮助我们发现问题。 与个人无关。
                    </div>
                    <div className="content_item_btn">了解更多</div>
                </div> */}
                <div className="content_item">
                    <div className="content_item_title">{retLang('Privacypolicy')}</div>
                    <div className="content_item_text">
                        {retLang('PrivacypolicyText')}
                    </div>
                    <div className="content_item_btn" onClick={() => seeAgree(1)}>{retLang('Learnmore')}</div>
                </div>
                <div className="content_item">
                    <div className="content_item_title">{retLang('TermsConditions')}</div>
                    <div className="content_item_text">
                        {retLang('TermsConditionsText')}
                    </div>
                    <div className="content_item_btn" onClick={() => seeAgree(2)}>{retLang('Learnmore')}</div>
                </div>
                <div className="content_item">
                    <div className="content_item_title">{retLang('EndUserLicenceAgreement')}</div>
                    <div className="content_item_text">
                        {retLang('EndUserLicenceAgreementText')}
                    </div>
                    <div className="content_item_btn" onClick={() => seeAgree(3)}>{retLang('Learnmore')}</div>
                </div>
            {/* </div> */}
            <Modal title=" " 
            closeIcon={<CloseCircleOutlined />}
            width={600}
            open={isModalOpen} 
            onOk={handleOk}
            onCancel={handleClose}
            okText={retLang("Close")}
            cancelButtonProps={{
                style:{display:'none'}
            }}
            centered={true}
            >
                {
                    agreeName === 1 ? <PrivacyPolicy /> 
                    : agreeName === 2 ? <TermsConditions />
                    : agreeName === 3 ? <LicensingAgreement />
                    : null
                }
            </Modal>
        </div>
    )
}

export default Element;