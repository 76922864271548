import React, { useContext } from 'react';
import { Button } from 'antd';
import logoIcon from "../../assets/LOGO.svg"
import { useNavigate } from 'react-router-dom';
import { MyContext } from '@/utils/myContext';
import "./Code404.scss"

const Element  = (props: any) => {
    const navigate = useNavigate();
    const {retLang} = useContext(MyContext);
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className='View404'>
            <div className="Page404">
                <img src={logoIcon} alt="" />
                <div v-if="error.statusCode === 404">
                    <h1>404 - Page not found</h1>
                    <p>We couldn't find the page you are looking for.</p>
                </div>
                <Button type="primary" onClick={goBack}>{retLang('goBack')}</Button>
            </div>
        </div>
    )
}

export default Element;