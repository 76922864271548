import { useContext,useEffect,useState } from "react";
import {socketUrl} from "@/utils/globaldata"
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { encryptData } from '@/utils/crypotoJS';

function randomString(e:number) {    
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
  a = t.length,
  n = "";
  for (let i:number = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}
const randomStr = randomString(16);
function useCustomWebSocket() {
  const {
    sendJsonMessage: originalSendJsonMessage,
    lastJsonMessage,
    readyState
  } = useWebSocket(socketUrl + `?hello=${randomStr}`, {
    share:true,
    reconnectAttempts: 50,
    reconnectInterval: 3000,
    shouldReconnect: (closeEvent) => true,  //会话结束后，是否应重新连接
    onOpen: () => {
      
    }
  });

  const sendJsonMessage = (data: any) => {
    // 对发送的数据进行统一处理
    // ...
    if(data.hello){
      return originalSendJsonMessage(data);
    }
    if(data.operate === 'GetComputerHardWare'){
      return originalSendJsonMessage(data);
    }
    let enData:any = encryptData(JSON.stringify(data),randomStr);
    originalSendJsonMessage({msg:enData});
  };

  return {
    sendJsonMessage,
    receivedMessages:lastJsonMessage,
    readyState,
  };
}

export default useCustomWebSocket;
