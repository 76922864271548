import React, { useEffect, useState } from 'react';
import { Modal, Form, Button,Select, Input } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import './globalModel.scss';
const opercode:any = operCode;
const { Option } = Select;
const Element  = (props: any) => {
    const {
        receivedMessages,
        sendJsonMessage,
    } = useCustomWebSocket();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [components,setComponents] = useState<any>([]);
    const [submitedName,setSubmitedName] = useState<any>('Submit');
    const [operate,setOperate] = useState<any>('');
    const [loading,setLoading] = useState(false);
    useEffect (() => {
        const result:any =  { ...receivedMessages };
        if( result.retcode === 125 && result.opercode === opercode.operate ){
            setIsModalOpen(true);
            setSubmitedName(result.buttonname);
            setComponents(result.components);
            setOperate(result.operate);
        }
    },[receivedMessages]);
    useEffect (() => {
        if( isModalOpen && components.length > 0 ){
            const obj:any = {};
            components.forEach((item:any) => {
                obj[item.key] = item.value;
            });
            try{
                form.setFieldsValue(obj);
            }catch(e){}
            
        }
    },[components,isModalOpen]);
    
    const handleClose = () => {
        setIsModalOpen(false);
    };
    const handleSubmited = (values:any) => {
        const data = {
            "operate":operate,
            "opercode":opercode.operate,
            ...values,
        }
        sendJsonMessage(data);
        setIsModalOpen(false);
        setComponents([]);
    }
    return (
        <div className='globalModel'>
            <Modal
                title=""
                closeIcon={<CloseCircleOutlined />}
                open={isModalOpen}
                onCancel={handleClose}
                footer={null}
                width={600}
                className='globalModel'
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
            >
                <div className='globalModel-content'>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true }}
                        labelCol={{ span: 6,offset: 0 }}
                        wrapperCol={{ span: 12,offset: 0 }}
                        onFinish={handleSubmited}
                    >
                        {
                            components.map((item:any,index:number) => {
                                if(item.name === 'input'){
                                    return (
                                        <Form.Item
                                            label={item.label}
                                            name={item.key}
                                            key={index}
                                        >
                                            <Input placeholder={item.placeholder} autoComplete='off' />
                                        </Form.Item>
                                    )
                                }else if(item.name === 'select'){
                                    return (
                                        <Form.Item
                                            label={item.label}
                                            name={item.key}
                                            key={index}
                                        >
                                            <Select placeholder={item.placeholder}>
                                                {
                                                    item.items.map((option:any,index:number) => {
                                                        return (
                                                            <Option value={option} key={index}>{option}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            })
                        }
                        <Form.Item wrapperCol={{ offset: 0, span: 24 }} className='footerBtn' >
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {submitedName}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default Element;