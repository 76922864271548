import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import googlemail from '@/assets/loginIco/logo-gmail.png';
import outlookemail from '@/assets/loginIco/outlook.png';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import { io } from "socket.io-client";
import CodeCom from './codeCom';

const opercode:any = operCode;

const LoginAccount = () => {
  let {
    socket,
    retLang,
    connectSocketio,
    sdkVersion,
} = useContext(MyContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
  const account = localStorage.getItem("account");
  const [visitTime,setVisitTime] = useState(60);
  useEffect(() => {
    sendJsonMessage({"operate":"GetComputerHardWare","opercode":999});
  },[])
  useEffect(() => {
    if(visitTime > 0){
      setTimeout(() => {
        setVisitTime(visitTime - 1);
      },1000)
    }
  },[visitTime])
  //启动
  useEffect (() => {
    let result:any = { ...receivedMessages };
    if(result.retcode === 200 && result.opercode === opercode.loginaccount){
      
    }
  }, [receivedMessages,navigate]);

  const accountLogin = (code : any) => {
    const computerData = JSON.parse(localStorage.getItem("computerData") || "{}");
    setLoading(true);
    var obj = {
        loginAccount:account,
        code:code,
        deviceUnique:computerData.computerID || null,
        clientVersion:sdkVersion,
    }
    axios.post('/client/new/emailLoginCodeClientLogin',obj).then((res:any) => {
        setLoading(false);
        if(res.data.code === 200){
          try{
            socket.disconnect();
            localStorage.setItem("accountData",JSON.stringify(res.data.data));
            localStorage.setItem("launch","1");
            connectSocketio();
            navigate('/Launch');
            sendJsonMessage(res.data.data);
          }catch(err){
            
          }
          
        }
    }).catch((err:any) => {
      setLoading(false);
    })
  }
  const goBack = () => {
    navigate('/login/loginType');
  }
    return (
        <div className="loginAccount" style={{width:"auto"}}>
          <div className="goBack" onClick={ goBack }><LeftOutlined /> {retLang("goBack")}</div>
          <div className='code_box'>
            <p className='code_remark'>We've sent a 6-character code to <b>{account}</b>. The code expires shortly, so please enter it soon.</p>
            <CodeCom submit={(code:any) => accountLogin(code)} />
            <div className="form-helper">
              <a target="_blank" href="https://mail.google.com/mail/u/0/" rel="noopener noreferrer">
                <img src={googlemail} alt="" />
                <span>{ retLang('OpenGmail') }</span>
              </a>
              <a target="_blank" href="https://outlook.live.com/mail/0/inbox" rel="noopener noreferrer">
                <img src={outlookemail} alt="" />
                <span>{ retLang('OpenOutlook') }</span>
              </a>
            </div>
            <div className="code_remark" style={{fontSize:'14px'}}>{ retLang('codeRemark') }</div>
          </div>
        </div>
    )
}

export default LoginAccount;