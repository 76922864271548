import React, { useState, useEffect, useContext } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { MyContext } from '@/utils/myContext';
import { Tag } from 'antd';
import { FireOutlined,RocketFilled } from '@ant-design/icons';
import "./infoMenu.scss";
const Element = () => {
    const { 
        language, retLang, domain,menuCheckId,setMenuCheckid,
        availableForFree, setPurchaseType, setIsShowPurchase
    } = useContext(MyContext);
    const menuList:any = [
        {name:retLang("Devices"),id:1,url:"/dashboard/deviceInfo/infomationList"},
        // {name:"Firmware List",id:2,url:"/dashboard/deviceInfo/firmwareList"},
        {name:retLang("RepairTools"),id:4,url:"/dashboard/index"},
        {name:retLang("Utilitytools"),id:6,url:"/dashboard/common-tools"},
        {name:retLang("ImeiCheck"),id:5,url:"/dashboard/imei-checker"},
        {name:retLang("Tutorials"),id:8,url:"/dashboard/tutorials"},
        {name:retLang("Community"),id:7,url:""},
    ]
    const navigate = useNavigate();

    const pathName = useLocation();
    useEffect(() => {
        if(pathName.pathname === "/dashboard/deviceInfo/infomationList"){
            setMenuCheckid(1);
        }else if(pathName.pathname === "/dashboard/deviceInfo/firmwareList"){
            setMenuCheckid(2);
        }else if(pathName.pathname === "/dashboard/index" || pathName.pathname === "/dashboard/noService"){
            setMenuCheckid(4);
        }else if(pathName.pathname === "/dashboard/imei-checker"){
            setMenuCheckid(5);
        }else if(pathName.pathname === "/dashboard/common-tools"){
            setMenuCheckid(6);
        }else if(pathName.pathname === "/dashboard/tutorials"){
            setMenuCheckid(8);
        }
    },[pathName])
    
    const clickMenu = (item:any) => {
        if(item.id === 7){
            window.open(`https://community.${domain}`);
            return false;
        }else{
            setMenuCheckid(item.id);
            navigate(item.url);
        }
    }
    const getFreeTrial = () => {
        setPurchaseType(2);
        setIsShowPurchase(true);
    }
    const menuItem:any = menuList.map((item:any,index:any) => {
        return(
            <div key={index} onClick={() => clickMenu(item)} className={`menu_item ${menuCheckId===item.id?"active":""}`}>{item.name}</div>
        )
    })
    const goWebsite = () => {
        window.open(`https://www.${domain}`);
    }
    return (
        <div className='header_nav'>
            <div className="image_logo" onClick={goWebsite}></div>
            <ul className="menu">
                {menuItem}
            </ul>
            {
                availableForFree && <div className="freec" style={{marginLeft:"80px",cursor:"pointer"}}>
                    <Tag icon={<RocketFilled />} color="#cd201f" onClick={getFreeTrial}>
                        {retLang("gerFreeTrial")}
                    </Tag>
                </div>
            }
            
        </div>
    )
}

export default Element;