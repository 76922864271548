import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';

import '../../style/login.scss';
import { Helmet } from 'react-helmet';
const Login = () => {
    const navigate = useNavigate();
    let {
        domain,
    } = useContext(MyContext);
    useEffect(() => {
        // let accountData = localStorage.getItem("accountData");
        // if(accountData){
        //     navigate("/dashboard/deviceConnection");
        // }
    }, [])
    return (
        <div className='login-box'>
            <Helmet>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="EVONDT CLOUD" />
                <meta name="twitter:description" content="Smarter choice, Smarter life." />
                <meta name="twitter:image" content="https://www.evondt.com/shareBanner.png" />

                {/* facebook */}
                <meta property="og:title" content="EVONDT CLOUD" />
                <meta property="og:description" content="Smarter choice, Smarter life." />
                <meta property="og:image" content="https://www.evondt.com/shareBanner.png" />
                <meta property="og:url" content="https://apps.evondt.com" />
            </Helmet>
            <div 
            onClick={() => {window.open(`https://www.${domain}`)}}
            style={{
                position: 'fixed',
                top: '15px',
                left: '15px',
                textAlign: 'center',
                cursor: 'pointer',
            }}>
                <HomeOutlined style={{fontSize:"18px"}} />
                <span style={{marginLeft:"8px"}}>Official Website</span> 
            </div>
            <div className="logo">
                
            </div>
            
            <div id="detail">
                <Outlet />
            </div>
        </div>
    )
}

export default Login;