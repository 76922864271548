import React, { useEffect, useContext } from 'react';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

import "./deviceModelComponent.scss"
import model from "@/assets/deviceImg/model.png"
import reboot from "@/assets/icon/button_reboot.svg"
import refresh from "@/assets/icon/button_refresh.svg"
import shutdown from "@/assets/icon/button_shutdown.svg"

const opercode:any = operCode;
const Element  = (props: any) => {
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    let { connectBrand, connectModel, retLang, setNoServiceOpen, connectCode } = useContext(MyContext);
    useEffect(() => {
        
    },[receivedMessages])

    const clickreboot = () => {
        if(connectCode !== 'Open'){
            setNoServiceOpen(true);
            return;
        }
        sendJsonMessage({"operate":"android_reboot","opercode":opercode.android_reboot});
    }
    const clickshutdown = () => {
        if(connectCode !== 'Open'){
            setNoServiceOpen(true);
            return;
        }
        sendJsonMessage({"operate":"android_shutdown","opercode":opercode.android_shutdown});
    }
    const clickRefresh = () => {
        if(connectCode !== 'Open'){
            setNoServiceOpen(true);
            return;
        }
        sendJsonMessage({"operate":"GetDeviceInfo","opercode":opercode.GetDeviceInfo});
    }
    return (
        <div className="modelBanner">
           <img className='model_img' src={model} alt="" />
           <p>{retLang("CurrentEquipment")}: {connectModel}</p>
           <div className="opert_button_group">
                <div className="button_item" onClick={clickreboot}><img src={reboot} alt="" /> {retLang("Reboot")}</div>
                <div className="button_item" onClick={clickshutdown}><img src={shutdown} alt="" />{retLang("Shutdown")}</div>
                <div className="button_item" onClick={clickRefresh}><img src={refresh} alt="" />{retLang("Refresh")}</div>
           </div>
        </div>
    )
}

export default Element;