import React, { useState, useContext, useEffect } from 'react';
import { MyContext } from '@/utils/myContext';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import './notice.scss';
const Element  = (props: any) => {
    const { socket,userInfo } = useContext(MyContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notice, setNotice] = useState<any>({});
    useEffect(() => {
        socket.on("message_event", (msg:any) => {
            try {
                if(userInfo.guideSwitch === 0) return;
                const messageData = JSON.parse(msg);
                if(messageData.code === 200) {
                    if(messageData.data.type && messageData.data.type === 8002) {
                        setNotice(messageData.data.data);
                        setIsModalVisible(true);
                    }
                }
            }catch(e) {}
        });
    },[])
    return (
        <div>
            <Modal title={notice.masterTitle}
            closeIcon={<CloseCircleOutlined />}
            centered={true}
            width={800}
            open={isModalVisible} 
            footer={null}
            onCancel={() => setIsModalVisible(false)}>
                <div className="message-contain">
                    <div className="message-subtitle">{notice.subTitle}</div>
                    <div className="message-content" dangerouslySetInnerHTML={{__html: notice.noticeContent}}></div>
                    <div className="message-time">{notice.noticeTime}</div>
                </div>
            </Modal>
        </div>
    )
}

export default Element;