import React, { useCallback, useEffect, useContext } from 'react';
import { Button, Tooltip, Skeleton } from 'antd';
import { LockFilled } from '@ant-design/icons';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

const opercode:any = operCode;
  
const Element  = React.memo( (props: any) => {
    let { defaultBrand,
        defaultModel,
        sendPort,
        tools,
        setIsShowPurchase,
        availability,
        menuCheckId,
        chipFilePath,
        connectCode,
        setNoServiceOpen,
        translateFunItem,
        tranFunRemark,
        operateLoading,setOperateLoading,
        setOperateName,
        setPurchaseType,
        availableForFree,
    } = useContext(MyContext);

    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();

    useEffect(() => {
        let result:any = { ...receivedMessages };
    },[receivedMessages])
    
    const clickFun = useCallback((oper:any,permission:any,name:any) => {
        if(connectCode !== 'Open'){
            setNoServiceOpen(true);
            return;
        }
        if(availability === 0 && permission === 0){
            availableForFree && setPurchaseType(2);
            setIsShowPurchase(true);
            return;
        }
        let sendData:any = {operate:oper,"brand":defaultBrand,"model":defaultModel,"Port":sendPort,"opercode":opercode.operate};
        if(menuCheckId === 6){
            sendData["devparam"] = chipFilePath;
        }
        sendJsonMessage(sendData);
        setOperateName(name);
        setOperateLoading(oper);
    },[sendJsonMessage,defaultBrand,defaultModel,sendPort])
    
    const resetItem:any = tools && tools.map((item:any,index:any) => {
        return(
          <div className="info-item" key={index}>
            <Tooltip mouseEnterDelay={1} className='tipBtn' placement='right' key={index} title={
                <div className='tip-content'>
                    <p style={{marginBottom:"10px"}}>{translateFunItem(`${item.function_key}${item.function_id}`)}</p>
                    <p>{tranFunRemark[item.function_id] || item.description}</p>
                </div>
            }>
                <Button type='primary' disabled={operateLoading} loading={operateLoading===item.label} onClick={() => clickFun(item.label,item.permission,`${item.function_key}${item.function_id}`)}>
                    <span className='btnText'>
                        {item.permission === 0 && availability === 0 ? <LockFilled /> : null}
                        {translateFunItem(`${item.function_key}${item.function_id}`)}
                    </span>
                </Button>
            </Tooltip>
          </div>
        )
    });
    return (
        <div className='function-reset'>
            {
                tools?resetItem:
                Array.from(new Array(4)).map((item,index) => {
                    return(
                        <div className="info-item" key={index}>
                            <Skeleton.Button style={{width:"130px"}} active={true} size='large' />
                        </div>
                    )
                })
            }
        </div>
    )
})

export default Element;