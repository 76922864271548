import React, { useEffect,useRef } from 'react';

declare global {
    interface Window {
        paypal: any;
    }
}

function paypalCom(props: any) {
    const paypalRef = useRef<any>(null);
    // 获取后端返回的 clientSecret
    const { resData } = props;
  useEffect(() => {
    var PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?client-id=${resData.clientSecret}&currency=USD&components=buttons,funding-eligibility`;
    
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.src = PAYPAL_SCRIPT;
    script1.async = true;
    script1.onload = () => {
        var FUNDING_SOURCES = [
            window.paypal.FUNDING.PAYPAL
        ];
        FUNDING_SOURCES.forEach(function(fundingSource) {
            var button = window.paypal.Buttons({
                fundingSource: fundingSource,
                onClick: function(data:any, actions:any) {
                    
                },
                // Sets up the transaction when a payment button is clicked
                createOrder: (data:any, actions:any) => {
                    
                    return actions.order.create({
                        purchase_units: [{
                          amount: {
                            "currency_code": "USD",
                            "value": resData.payAmt, // Can also reference a variable or function
                              "breakdown":{
                                "item_total":{
                                  "currency_code": "USD",
                                  "value": resData.payAmt
                                }
                              }
                          },
                          "items":[
                            {
                              "name":resData.goodsName,
                              "quantity":1,
                              "unit_amount":{
                                "currency_code": "USD",
                                "value": resData.payAmt
                              },
                            }
                          ],
                          "custom_id":resData.orderNo
                        }]
                    });
                },
                // Finalize the transaction after payer approval
                onApprove: (data:any, actions:any) => {
                    return actions.order.capture().then(function(orderData:any) {
                        // Successful capture! For dev/demo purposes:
                        const transaction = orderData.purchase_units[0].payments.captures[0];
                        // alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
                        // When ready to go live, remove the alert and show a success message within this page. For example:
                        // const element = document.getElementById('paypal-button-container');
                        // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                        // Or go to another URL:  actions.redirect('thank_you.html');
                        var loca = window.location.origin;
                        actions.redirect(loca+'/wait?o='+resData.orderNo)
                    });
                },
                
                
            });
            // Check if the button is eligible
            if (button.isEligible()) {

                // Render the standalone button for that funding source
                button.render('#paypal-button-container');
            }
        });
    };

    // 将 script 标签添加到 body 中
    document.body.appendChild(script1);
    // 清理函数：组件卸载时移除 script 标签
    return () => {
      document.body.removeChild(script1);
    };
  }, []);  // 空依赖数组表示这个 useEffect 只在组件挂载时运行

    const goPay = () => {
        console.log('goPay')
    }
  // 返回 noscript 标签
  return (
    <div ref={paypalRef} onClick={goPay} id='paypal-button-container'></div>
  );
}

export default paypalCom;
