import React, { useState,useEffect } from 'react';
import { Button,Tooltip } from 'antd';

import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"

const opercode:any = operCode;

const Element  = React.memo(() => {
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    useEffect(() => {
        let result:any = { ...receivedMessages };
        
    },[receivedMessages])
    return (
        <div>

        </div>
    )
})

export default Element;