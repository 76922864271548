import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import * as CryptoJS from "crypto-js";
 
export function encryptData(message: string, randomString: string): string {
    const md5Hash = CryptoJS.MD5(randomString).toString();

    const sha256Hash = CryptoJS.SHA256(md5Hash).toString();

    const iv:any = CryptoJS.enc.Utf8.parse(sha256Hash.substr(0, 16));
    const key = CryptoJS.enc.Utf8.parse(sha256Hash.substr(16, 16));

    const cipherData = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
    });
    return cipherData.toString();
}

export function decryptAES(encryptedText:any, secretKey:any, iv:any) {
    const decryptedBytes = AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(secretKey), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decryptedBytes.toString(Utf8);
}