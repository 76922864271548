import React, { useState,useContext, useEffect, useRef } from 'react';
import { MyContext } from '@/utils/myContext';
import { Modal,Segmented,Tabs } from 'antd';
import { CloseCircleOutlined,LoadingOutlined } from '@ant-design/icons'
import SelectPlan from './purchaseCom/selectPlan'
import Reseller from './purchaseCom/reseller'
import Share from '@/views/Component/share/share';
import "./device.scss"
import axios from '@/utils/axios';
const Element  = (props: any) => {
    const { 
        retLang,
        isShowPurchase,setIsShowPurchase,
        purchaseType, setPurchaseType,
        isWaitAudited, setIsWaitAudited,
        domain
    } = useContext(MyContext);
    const [isAuditedSuccess, setIsAuditedSuccess] = useState(false);
    let timer:any = useRef<any>(null);
    useEffect(() => {
        if(isShowPurchase){
            
        }
    },[isShowPurchase])
    useEffect(() => {
        if(isWaitAudited === 1){
            timer.current = setTimeout(() => {
                axios.post('/client/new/order/freeGoodsApply').then((res:any) => {
                    if(res.data.code === 200){
                        setIsShowPurchase(false);
                        setIsAuditedSuccess(true);
                        setIsWaitAudited(null);
                    }else{
                        setIsWaitAudited(null);
                    }
                })
            }, 100 * 1000);
        }
    },[isWaitAudited])
    const purchaseTypeList = [
        {label:retLang('Reseller'),value:1},
        {label:retLang('Official'),value:2},
    ]
    const handleCancel = () => {
        setIsWaitAudited(null);
        setIsShowPurchase(false);
        clearTimeout(timer.current);
    };
    const getSuccess = () => {
        setIsAuditedSuccess(false);
        window.location.reload();
    }
    return (
        <div>
            <Modal
            width={900}
            title={
                isWaitAudited===1?retLang('Underaudit'):
                <div className='purchaseType'>
                    {
                        purchaseTypeList.map((item,index) => {
                            return (
                                <div className={`purchaseTypeItem ${purchaseType === item.value ? 'active' : ''}`} key={index} onClick={() => setPurchaseType(item.value)}>
                                    {item.label}
                                </div>
                            )
                        })
                    }
                </div>
            }
            className='purchaseModal'
            closeIcon={<CloseCircleOutlined style={{display:isWaitAudited===1?'none':'block',fontSize:'20px'}} />}
            footer={null}
            open={isShowPurchase}
            centered={true}
            getContainer={false}
            maskClosable={isWaitAudited===1?false:true}
            destroyOnClose={true}
            onCancel={handleCancel}>
                {
                    isWaitAudited===1?<div className='leadAroundProduct Underaudit' style={{width:"500px",margin:"0 auto",textAlign:"center"}}>
                        <h2 style={{marginBottom:"20px",color:"#fff"}}>{retLang('UnderauditTitle')}</h2>
                        <p>{retLang('UnderauditRemark1')}</p>
                        <p style={{textAlign:"center",marginTop:"50px",fontSize:"48px"}}><LoadingOutlined /></p>
                    </div>:
                    <div className='leadAroundProduct'>
                        {
                            purchaseType === 1 ?
                            <Reseller />:
                            <SelectPlan />
                        }
                    </div>
                }
            </Modal>
            <Modal
                open={isAuditedSuccess}
                footer={null}
                title={retLang('Congratulations')}
                destroyOnClose={true}
                onCancel={getSuccess}
            >
                <div>
                    <p>{retLang('CongratulationsRemark1')}</p>
                    <Share shareRemark={retLang('GetSuccessfulTextSharing')} btnShareLink={`https://apps.${domain}`} />
                </div>
            </Modal>
        </div>
    )
}

export default Element;