import React, { useEffect,useCallback,useState, useContext } from 'react';
import { Tooltip } from 'antd';
import "./infomationList.scss"
import { MyContext } from '@/utils/myContext';
import buttonReport from "@/assets/icon/button_report.svg";
import datacapacity from "@/assets/icon/icon-datacapacity.svg";
import Capacity from "@/assets/icon/Capacity.svg"
import FileDrawer from "../fileDrawer/fileDrawer"
import { ReadyState } from 'react-use-websocket';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
const opercode:any = operCode;

const Element  = (props: any) => {
  const {sendJsonMessage,receivedMessages,readyState} = useCustomWebSocket();
  
  let { retLang, connectBrand,setConnectBrand, connectModel,setConnectModel } = useContext(MyContext);
  
  const [totaldisk,setTotaldisk] = useState<string>("0 GB");
  const [useddisk,setUseddisk] = useState<string>("0 GB");

  type Employee = {
    label: string;
    Values: string;
    id : Number
  };
  const [dataList,setDataList] = useState<Employee[]>([
    // {label:"Firmware Version",Values:"iOs12.1.1(16C5050a)",id:1},
    // {label:"Apple ID Lock",Values:"Not turned on",id:2},
    // {label:"Production",Values:"September 7, 2014 (Week 36)",id:3},
    // {label:"Jailbreak Status",Values:"Jailbroken",id:4},
    // {label:"iCloud",Values:"Not turned on",id:5},
    // {label:"Warranty Period",Values:"Online Enquiry",id:6},
    // {label:"Activation Status",Values:"Activation",id:7},
    // {label:"manufacturer name",Values:"Iphone",id:8},
    // {label:"model",Values:"Iphone 14 pro",id:9},
  ])

  type EmpType = {
    label: string;
    Values: string;
    percentage : string
  };
  const [typeList,setTypeList] = useState<EmpType[]>([
    // {label:"System",Values:"spaceDetails", percentage:"10%"},
    // {label:"Applications",Values:"spaceDetails", percentage:"24%"},
    // {label:"Photo",Values:"spaceDetails", percentage:"16%"},
    // {label:"Audio",Values:"spaceDetails", percentage:"3%"},
    // {label:"Other",Values:"spaceDetails", percentage:"8%"}
  ]);
  
  useEffect (() => {
    if(readyState === ReadyState.OPEN){
      sendJsonMessage({"operate":"GetDeviceInfo","opercode":opercode.GetDeviceInfo});
    }
  }, [readyState]);

  useEffect (() => {
    let result:any = { ...receivedMessages };
    if(result.opercode === opercode.GetDeviceInfo && result.deviceinfo){
      setDataList(result.deviceinfo);
      for(var i=0; i<result.deviceinfo.length; i++){
        if(result.deviceinfo[i].label === "manufacturer name"){
          setConnectBrand(result.deviceinfo[i].Values);
        }
        if(result.deviceinfo[i].label === "model"){
          setConnectModel(result.deviceinfo[i].Values);
        }
      }
      sendJsonMessage({"operate":"GetDiskInfo","opercode":opercode.GetDiskInfo});
    }
    if(result.opercode === opercode.GetDiskInfo && result.spaceDetails){
      setTotaldisk(result.totalspace);
      setUseddisk(result.userdspace);
      setTypeList(result.spaceDetails);
    }
  }, [receivedMessages]);

  const sendReport = useCallback(() => {
    
  },[]);

  
  const infoItem:any = dataList.map((item:any) => {
    return(
      <div className="info-item" key={item.label}>
        <span className="item-label">{item.label}</span>
        <Tooltip title={item.Values}>
          <span className="item-value">{item.Values}</span>
        </Tooltip>
      </div>
    )
  });

  

  const typeItem:any = typeList.map((item:any) => {
    let itemStyle = {
      width:item.percentage
    };
    return(
      <div className="capacity-pro-item" key={item.label} style={itemStyle}>
      </div>
    )
  });
  const typeRemarkItem:any = typeList.map((item:any) => {
    return(
      <div className="capacity-comp-item" key={item.label}>
        <div className="item-color"></div>
        <span>{item.label}</span>
      </div>
    )
  })
  const backgroundNum:any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];
  return (
    <div className='infomationList'>
      <div className="infodialog">
        <div className="info-header">
          <div className="header-name">
            {
              <span>{connectBrand}</span>
            }
            <span className="tag-item">{totaldisk}</span>
            {
              <span>{connectModel}</span>
            }
          </div>
          {/* <div onClick={sendReport} className="header-report">
            <img src={buttonReport} alt="" />
            <span>Inspection Report</span>
          </div> */}
        </div>
        <div className="data-content">
          <div className="data-list">
            {infoItem}
          </div>
        </div>
      </div>
      <div className="capacity">
        <div className="border-background">
          {backgroundNum.map((item:any) => <img key={item} src={Capacity} alt="" />)}
        </div>
        <div className="capacity-content">
          <div className="datacapcity">
            <img src={datacapacity} alt="" />
            <span>{retLang("DataCapacity")}</span>
            <div className="file-manage"><FileDrawer></FileDrawer></div>
          </div>
          <div className="capacity-pro">
            {typeItem}
          </div>
          <div className="capacity-comp">
            <div className="capacity-comp-l">{typeRemarkItem}</div>
            <div className="capacity-comp-r">{useddisk} / {totaldisk}</div>
          </div>
        </div>
        <div className="border-background">
          {backgroundNum.map((item:any) => <img key={item} src={Capacity} alt="" />)}
        </div>
      </div>
    </div>
  )
}

export default Element;