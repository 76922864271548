import React, { useContext, useEffect } from 'react';
import { MyContext } from '@/utils/myContext';
import { Skeleton, } from 'antd';
import "./pointBox.scss";
const Element  = (props: any) => {
    let { domain } = useContext(MyContext);
    const [loading,setLoading] = React.useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        },1000)
    },[])
    return (
        <div className='pointView' style={{display:"flex",height:"calc(100vh - 78px)"}}>
            <div className="hide_box" style={{display:loading?"block":"none",width:"100%"}}>
                <div className="loading-imei">
                    <div className="loading-imei-item">
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton active />
                    </div>
                    <div className="loading-imei-item">
                        <Skeleton active />
                    </div>
                </div>
            </div>
            <iframe width={"100%"} style={{left:loading?"-9999px":"0",opacity:loading?"0":"1",}} src={`https://community.${domain}/?q=testpoint%20ISP`}></iframe>
        </div>
    )
}

export default Element;