import React, { useEffect,useContext,useState, useCallback, useRef } from 'react';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import { notification,Button,Modal } from 'antd';
import { CloseCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import useCustomWebSocket from '@/utils/websocket';
import { MyContext } from '@/utils/myContext';
import Purchase from './purchase';
import axios from '@/utils/axios';

import NoServiice from '@/views/Device/noService/noService'
import InfoMenu from "@/views/Component/infoMenu/infoMenu";
import DriverCom from '@/views/Component/footerMenu/footerComponent/driverCom'


import "./device.scss"
import operCode from "@/utils/opercode.json"
const opercode:any = operCode;
const Element  = (props: any) => {
    const [api, contextHolder] = notification.useNotification();
    const {
        connectCode,userInfo,setUserInfo,domain,retLang,noServiceOpen,
        setNoServiceOpen,isModalOpen,setIsShowPurchase,setPurchaseType,
        ipInfo, setIpInfo, availableForFree, setAvailableForFree
    } = useContext<any>(MyContext);
    const navigate = useNavigate();
    const {receivedMessages,sendJsonMessage} = useCustomWebSocket();
    const [freeTipOpen, setFreeTipOpen] = useState(false);
    const [driverOpen, setDriverOpen] = useState(false);
    const pathName = useLocation();
    
    const payPlan = useCallback(() => {
        setPurchaseType(2);
        setIsShowPurchase(true);
        setFreeTipOpen(false);
    }, [userInfo])
    useEffect(() => {
        sendJsonMessage({"operate":"check_driver","opercode":opercode.check_driver});
        axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
            if(res.data.code === 200){
              const data = res.data.data;
              setUserInfo(data);
              if(data.userAttr === 0){
                setFreeTipOpen(true);
              }
            }
        })
    }, [])
    useEffect(() => {
        const lang = navigator.language;
        if(userInfo && ipInfo && ipInfo.country && ipInfo.type){
            if(userInfo?.lastLoginIp === "18.142.174.103"){
                if(userInfo?.userAttr === 0 && userInfo?.freeGoodsApply===0 && ipInfo?.country!=="CN"){
                    setAvailableForFree(true);
                }
            }else{
                if(userInfo?.userAttr === 0 && userInfo?.freeGoodsApply===0 && ipInfo?.country!=="CN" && ipInfo?.type!=="hosting" && lang!=="zh-CN"){
                    setAvailableForFree(true);
                }
            }
        }
    }, [userInfo,ipInfo])
    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.check_driver && result.retcode === 200){
            let list = result.check_driver;
            let flag = false;
            if(list && list.length > 0){
                list.map((item:any) => {
                    if(item.value === "0"){
                        flag = true;
                    }
                })
            }
            if(flag && !isModalOpen){
                setDriverOpen(true);
            }
        }
    }, [receivedMessages]);
    
    const handleCancel = () => {
        setFreeTipOpen(false);
    }
    return (
        <div>
            {contextHolder}
            <Purchase />
            {
                !pathName.pathname.includes('deviceConnection') && !pathName.pathname.includes('ConnectStatus')?
                <InfoMenu />:""
            }
            <Modal
                open={freeTipOpen && availableForFree}
                title={<div className="freeTipTitle">
                    <h3>{retLang("freeTrialTitle")}</h3>
                </div>
                }
                width={700}
                closeIcon={<CloseCircleOutlined style={{fontSize:"20px"}} />}
                footer={null}
                centered
                onCancel={handleCancel}
            >
                <div className="freeTipBox">
                    <div className='freeUserTipe'>
                        <div className='freeRemark'>{retLang("freeTrialRemark1")}</div>
                        <div className='freeRemark'>{retLang("freeTrialRemark2")}</div>
                        <div className='freeRemark'>{retLang("freeTrialRemark3")}</div>
                        <div className="freeRemark">{retLang("freeTrialRemark4")}</div>
                    </div>
                    <div className='freeUserTipeBtn'>
                        <Button block type="primary" onClick={payPlan}>{retLang("gerFreeTrial")}</Button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="No service"
                closeIcon={<CloseCircleOutlined style={{fontSize:"20px"}} />}
                open={noServiceOpen}
                width={900}
                footer={null}
                onCancel={() => setNoServiceOpen(false)}
            >
                <NoServiice></NoServiice>
            </Modal>
            <Modal
                width={700}
                closeIcon={<CloseCircleOutlined style={{fontSize:"20px"}} />}
                open={driverOpen}
                footer={null}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                onCancel={() => setDriverOpen(false)}
            >
                <DriverCom />
            </Modal>
            <Outlet />
        </div>
    )
}

export default Element;