import React, { useCallback, useEffect,useState } from 'react';
import './style/App.scss';
import {RouterProvider} from 'react-router-dom'
import router from './routers';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ConfigProvider, Modal, notification } from 'antd';
import { ReadyState } from 'react-use-websocket';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { io } from "socket.io-client";
import { MyContext } from '@/utils/myContext';
import lang from '@/utils/langData.js';
import axios from '@/utils/axios';
import * as Sentry from "@sentry/react";
import Notice from '@/views/Component/notice/notice';
import RecordReplayComponent from './RecordReplayComponent';
const opercode:any = operCode;
const { confirm } = Modal;
var userAgent = navigator.userAgent.toLowerCase();
const langData:any = lang;
function App() {
  const [socket,setSocket] = useState<any>(null);

  const [language,setLanguage] = useState<any>(null);
  const [languageJson,setLanguageJson] = useState<any>(langData["en"]);
  const [sdkVersion,setSdkVersion] = useState(null);
  const [isShowPurchase,setIsShowPurchase] = useState(false);
  const [clientVersion,setClientVersion] = useState('1.0.3');
  const [domain] = useState('evondt.com');
  const [userInfo,setUserInfo] = useState<any>({});
  const [transKey,setTransKey] = useState<any>(null);
  const [allFunction,setAllFunction] = useState<any>(null);
  const [transAllFunction,setTransAllFunction] = useState<any>([]);
  const [funRemark,setFunRemark] = useState<any>(null);
  const [tranFunRemark,setTranFunRemark] = useState<any>({});
  const [ serviceUrl ] = useState(`wss://clientv2wss.${domain}/clientws`);

  const [api, contextHolder] = notification.useNotification();
  const {
    receivedMessages,
    sendJsonMessage,
    readyState,
  } = useCustomWebSocket();

  const [whiteList] = useState([
    opercode.ConnectionStatus,
    opercode.getBrandList,
    opercode.getDeviceList,
    opercode.GetCommPort,
    opercode.selectDevice,
    opercode.operate,
    opercode.readInfo
  ]);
  const [tipWhiteList] = useState([
    opercode.SelectFolder,
    opercode.SelectFiles,
    opercode.SaveFiles,
    opercode.loadFirmware,
    opercode.operate,
    103,
  ]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  const [connectCode,setConnectCode] = useState('');
  useEffect (() => {
    const handler = (event:any) => {
      if (event.key === 'r' && event.ctrlKey) {
        event.preventDefault();
      }
      if (event.key === 'r' && event.ctrlKey && event.shiftKey) {
         event.preventDefault();
      }
      if (event.key === 'u' && event.ctrlKey) {
        event.preventDefault();
      }
      if (event.key === 'l' && event.ctrlKey && event.shiftKey) {
        event.preventDefault();
      }
      if (event.key === 'p' && event.ctrlKey) {
        event.preventDefault();
      }
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault();
      }
      if (event.key === 'o' && event.ctrlKey) {
        event.preventDefault();
      }
      if (event.key === 'ArrowLeft' && event.altKey) {
        event.preventDefault();
      }
      if (event.key === 'ArrowRight' && event.altKey) {
        event.preventDefault();
      }

      if (event.key === 'F5') {
        event.preventDefault();
      }
      if (event.key === 'F12') {
        event.preventDefault();
      }
    }
    const handleContextMenu = (event:any) => {
      event.preventDefault();
    }
    const handleBeforeunload = (event:any) => {
      // event.preventDefault();
      sendJsonMessage({"operate":"STOP","opercode":opercode.stop});
    }
    window.addEventListener('keydown', handler);
    // window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('beforeunload', handleBeforeunload);
    
    let local:any = localStorage.getItem("language");
    let localLang:any= local || "en";
    setLanguage(localLang);
    getFunctionList();

    axios.get('/client/new/ip/getInfoByIp').then((res:any) => {
      if(res.data.code === 200){
        const data = res.data.data;
        setIpInfo(data);
      }
    })
    return () => {
      window.removeEventListener('keydown', handler);
      // window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('beforeunload', handleBeforeunload);
    }
  },[])
  useEffect (() => {
    if(language && language !== "en"){
      setLanguageJson(langData[language] || langData["en"]);
      // axios.post('/dwapi/get/content/translation/',{
      //   content_key:"client.1.0.4",
      //   content_type:"json",
      //   source_language_code:'en',
      //   target_language_code:language,
      //   content: lang
      // }).then((res:any) => {
      //   if(res.data.code === 200){
      //       setLanguageJson(res.data.data);
      //   }
      // })
      if(allFunction){
        getTranFunctionList();
      }
    }else{
      setLanguageJson(langData["en"]);
    }
  },[language,allFunction])
  useEffect (() => {
    if(language && language !== "en"){
      if(funRemark){
        getFunRemarkTrans();
      }
    }else{
      if(funRemark){
        setTranFunRemark(funRemark);
      }
    }
  },[language,funRemark])
  const getFunctionList = () => {
    axios.get('/dwapi/web/show/function').then((res:any) => {
      if(res.data.code === 200){
        let remarkObj:any = {};
        setTransKey(res.data.content_key);
        let arr = res.data.function_list;
        let obj:any = {};
        arr.forEach((item:any) => {
          obj[`${item.function_key}${item.id}`] = item.name;
          remarkObj[item.id] = item.description;
        });
        setAllFunction(obj);
        setFunRemark(remarkObj);
      }
    })
  }
  const getTranFunctionList = () => {
    axios.post('/dwapi/get/content/translation/',{
      content_key:"functionJson",
      content_type:"json",
      source_language_code:'en',
      target_language_code:language,
      content: allFunction
    }).then((res:any) => {
      if(res.data.code === 200){
        setTransAllFunction(res.data.data);
      }
    })
  }
  const getFunRemarkTrans = () => {
    axios.post('/dwapi/get/content/translation/',{
      content_key:transKey+'v1',//funRemark 固定key
      content_type:'json',
      source_language_code:'en',
      target_language_code:language,
      content: funRemark
    }).then((res:any) => {
      setTranFunRemark(res.data.data);
    })
  }
  const translateFunItem = (value:any) => {
    if(allFunction){
      if(language && language !== "en"){
        try{
          return transAllFunction[value];
        }catch(e){
          return allFunction[value];
        }
      }else{
        return allFunction[value];
      }
    }else{
      return null;
    }
  }
  useEffect (() => {
    setConnectCode(connectionStatus);
    if(connectionStatus === "Closed"){
      // const isBrowser = typeof navigator !== 'undefined';
      // if(isBrowser){
      //   try{
      //     const link = document.createElement('a');
      //     link.href = 'evotool://';
      //     link.click();
      //   }catch(e){
      //     
      //   }
      // }
      // api["warning"]({
      //   message: "Service link failed, if not installed, please download the service before attempting to run it"
      // });
    }
    return () => {
      
    }
  },[connectionStatus]);

  const openFloder = (path:any) => {
    const pathParts = path.split(/[\\\/]/);
    pathParts.pop();
    const directoryPath = pathParts.join('\\');
    sendJsonMessage({"operate":"LocalResource","folderPath":directoryPath,"opercode":opercode.LocalResource});
  }
  
  useEffect (() => {
    const result:any =  { ...receivedMessages };
    if(result.computerID){
      localStorage.setItem("computerData",JSON.stringify(result));
    }
    if(result.language){
      let local:any;
      if(result.language === "zh-CN"){
        local = "zh_CN";
      }else if(result.language === "zh-TW"){
        local = "zh_TW";
      }else if(result.language === "en-US"){
        local = "en";
      }else{
        local = result.language;
      }
      localStorage.setItem("language",local);
      setLanguage(local);
      setSdkVersion(result.SDKVersion);
    }
    
    if(result.retcode === 300){
      setIsShowPurchase(true);
      // confirm({
      //   title: result.title,
      //   icon: <ExclamationCircleFilled />,
      //   content: 'Unavailable, please purchase a package first.',
      //   okText: 'Purchase',
      //   onOk() {
      //     window.open(`https://account.${domain}/plan?email=${userInfo.loginAccount}`);
      //   },
      //   onCancel() {
          
      //   },
      // });
    }
    if(result.retcode === 201){
      if( whiteList.indexOf(result.opercode) === -1 ){
        
      }
    }
    if( result.retcode === 200 ){
      
    }
    if(result.retcode === 220){
      api["success"]({
        message:  retLang('Successful'),
        description: <div style={{color:"#fff"}}>
          <p>{ result.operate_info}</p>
          <p style={{cursor:"pointer",textDecoration:"underline",marginTop:"15px"}} onClick={() => openFloder(result.SavePhoto)}>{retLang('Openthecontainingfolder')}</p>
        </div>
      });
    }
    if(result.retcode === 221){
      api["error"]({
        message:  retLang('Failure'),
        description: <div style={{color:"#fff"}}>
          <p>{ retLang('ScreenshotFailure') }</p>
        </div>
      });
    }
    if(result.retcode === 222){
      api["success"]({
        message:  retLang('Successful'),
        description: <div style={{color:"#fff"}}>
          <p>{ retLang('Copiedscreenshotclipboard') }</p>
        </div>
      });
    }
    if(result.retcode >= 400){
      if(result.retcode === 503){
        // sendJsonMessage({"operate":"STOP","opercode":opercode.stop});
      }else if( result.retcode === 800 ){
        confirm({
          title: result.title,
          icon: <ExclamationCircleFilled />,
          content: result.Messagebuf,
          onOk() {
            sendJsonMessage({"operate":"messageBoxResponse","result":"1","opercode":opercode.operate});
          },
          onCancel() {
            sendJsonMessage({"operate":"messageBoxResponse","result":"2","opercode":opercode.operate});
          },
        });
      }else if( result.retcode === 401 ){
        axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
          if(res.data.code === 200){
            const account:any = localStorage.getItem("accountData");
            try{
              const accountData = JSON.parse(account);
              sendJsonMessage(accountData);
            }catch(e){
              
            }
          }
        })
      }else if(result.retcode === 801){

      } else{
        if(tipWhiteList.indexOf( result.opercode ) === -1 ){
          api["warning"]({
            message: result.operate_info
          });
        }
      }
    }
  }, [receivedMessages,whiteList,api]);

  useEffect (() => {
    if(socket === null){
      connectSocketio();
    }
  },[socket]);

  const connectSocketio = () => {
    console.log(location.href)
    
    let computerDatal:any = localStorage.getItem("computerData");
    let accountData:any;
    if(!location.href.includes('login')){
      accountData = localStorage.getItem("accountData");
    }
    try{
      computerDatal = JSON.parse(computerDatal || '{}');
      accountData = JSON.parse(accountData || '{}');
    } catch(e){
      
    }
    let str = "";
    if(computerDatal.computerID){
      str = `clientDevId=${computerDatal.computerID}`;
    }
    if(accountData.accessToken){
      str = `clientUserToken=${accountData.accessToken}`;
    }
    if(computerDatal.computerID && accountData.accessToken){
      str = `clientDevId=${computerDatal.computerID}&clientUserToken=${accountData.accessToken}`;
    }
    const newSocket = io(serviceUrl+`?${str}`,{
      // extraHeaders: obj,
      transports: ['websocket'],
    });
    setSocket(newSocket);
  }

  const getUserInfo = () => {
    axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
      if(res.data.code === 200){
        const data = res.data.data;
        setUserInfo(data);
      }
    })
  }
  const [connectBrand,setConnectBrand] = useState<any>(null);
  const [connectModel,setConnectModel] = useState<any>(null);
  const [defaultBrand,setDefaultBrand] = useState(null);
  const [defaultModel,setDefaultModel] = useState<any>(null);
  const [defaultPort,setDefaultPort] = useState("Auto");
  const [sendPort,setSendPort] = useState<any>("Auto");
  const [flash,setFlash] = useState<any>(null);
  const [reset,setReset] = useState(null);
  const [tools,setTools] = useState<any>(null);
  const [others,setOthers] = useState([]);
  const [flashType,setFlashType] = useState(null);
  const [flashKey,setFlashKey] = useState(null);
  const [flashLock,setFlashLock] = useState<any>('1');
  const [operateName,setOperateName] = useState<any>('');
  const [flashName,setFlashName] = useState<any>('');
  const [preloader,setPreloader] = useState('0');
  const [activeKey, setActiveKey] = useState<any>("1");
  const [gptmap,setGptmap] = useState([
    {title:'name',dataIndex:'name'},
    {title:'File_s',dataIndex:'File_s'},
    {title:'size',dataIndex:'size'},
    {title:'startadd',dataIndex:'startadd'},
]);
  const [gptdata,setGptdata] = useState<any>([
    // {Checked: "0",File_s: "NULL",lun: "0",name: "xbl",setionpage: "512",size: "0x0000380000",startadd: "0x04000000"},
  ]);
  const [filePath,setFilePath] = useState('');
  const [chipFilePath,setChipFilePath] = useState('');
  const [availability,setAvailability] = useState<any>(true);
  const [flashExistence,setFlashExistence] = useState<any>(true);
  const [toolExistence,setToolExistence] = useState<any>(true);
  const [menuCheckId,setMenuCheckid] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noServiceOpen, setNoServiceOpen] = useState(false);
  const [operateLoading, setOperateLoading] = useState<any>(null);
  const [payInfo, setPayInfo] = useState<any>(null);
  const [purchaseType, setPurchaseType] = useState(1);
  const [ipInfo, setIpInfo] = useState<any>(null);
  const [availableForFree, setAvailableForFree] = useState<any>(false);
  const [isWaitAudited, setIsWaitAudited] = useState<any>(null);
  const retLang = useCallback((key:any) => {
    if(languageJson){
        return languageJson[key]?languageJson[key]:langData["en"][key];
    }
  },[languageJson]);
  const fileFilter = () => {
    try {
      let reg = /\((.+?)\)/gi;
      let chips = defaultModel.match(reg)[0];
      let chip = chips.substring(1,chips.length-1);
      if(chip === "Qualcomm"){
          return "qualcomm firmware files (*.xml)|*.XML|Any file (*.*)|*.*";
      }else if(chip === "Mediatek"){
          return "Text files (*.txt)|*.TXT|Any file (*.*)|*.*";
      }else if(chip === "Spreadtrun"){
          return "Sprd firmware files(*.pac)|*.PAC|Any file (*.*)|*.*";
      }else if(chip === "upgrade model flasher" || chip === "fastboot model flasher"){
          return "app firmware files (*.app)|*.APP|Text files (*.txt)|*.TXT|Any file (*.*)|*.*";
      }else{
          return "Firmwares(*.txt;*.xml;*.app;*.pac)|*.txt;*.xml;*.app;*.pac|Any file (*.*)|*.*";
      }
    } catch (error) {
      return "Firmwares(*.txt;*.xml;*.app;*.pac)|*.txt;*.xml;*.app;*.pac|Any file (*.*)|*.*";
    }
  }
  const filterType = (type:any) => {
    switch (type) {
        case 0:
            return retLang('Free');
        case 1:
            return retLang('Paid');
        case 2:
            return retLang('Test');
        case 3:
            return retLang('Trial');
        default:
            return retLang('User');
    }
  }
  const maskData = (input:string,shouldMask:boolean) => {
    if (!shouldMask) {
      return input;
    }
    try{
      if (input.includes('@')) {
        // Handle email
        let [localPart, domain] = input.split('@');
        localPart = localPart.length > 2 ? localPart[0] + '***' + localPart[localPart.length - 1] : '***';
        return localPart + '@' + domain;
      } else {
        // Handle phone number
        return input.replace(/(\d{1})\d*(\d{2})/, '$1****$2');
      }
    }catch(e){};
  }
  return (
    <div className="App">
      <MyContext.Provider value={{
        connectBrand,setConnectBrand,
        connectModel,setConnectModel,
        defaultBrand,setDefaultBrand,
        defaultModel,setDefaultModel,
        defaultPort,setDefaultPort,
        sendPort,setSendPort,
        flash,setFlash,
        reset,setReset,
        tools,setTools,
        others,setOthers,
        flashType,setFlashType,
        flashKey,setFlashKey,
        flashLock,setFlashLock,
        operateName,setOperateName,
        flashName,setFlashName,
        preloader,setPreloader,
        gptmap,setGptmap,
        gptdata,setGptdata,
        filePath,setFilePath,
        language,setLanguage,
        languageJson,setLanguageJson,
        retLang,
        fileFilter,
        serviceUrl,
        socket,setSocket,
        connectCode,
        sdkVersion,
        clientVersion,
        domain,
        filterType,
        isShowPurchase,setIsShowPurchase,
        userInfo,setUserInfo,
        getUserInfo,
        connectSocketio,
        availability,setAvailability,
        maskData,
        menuCheckId,setMenuCheckid,
        flashExistence,setFlashExistence,
        toolExistence,setToolExistence,
        chipFilePath,setChipFilePath,
        isModalOpen, setIsModalOpen,
        noServiceOpen, setNoServiceOpen,
        translateFunItem,
        activeKey, setActiveKey,
        funRemark,setFunRemark,
        tranFunRemark,setTranFunRemark,
        operateLoading, setOperateLoading,
        payInfo, setPayInfo,
        purchaseType, setPurchaseType,
        ipInfo, setIpInfo,
        availableForFree, setAvailableForFree,
        isWaitAudited, setIsWaitAudited,
      }}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#464955',
              borderRadius: 0
            },
            components:{
              Checkbox: {
                colorPrimary: '#7FB3DE',
                colorPrimaryHover: '#7FB3DE'
              },
              Radio: {
                padding: 3,
              },
              Switch: {
                colorPrimary: '#4990CB',
                colorPrimaryHover: '#67a2d3',
              }
            },
          }}
        >
          <div className="App-header">
            {contextHolder}
            <RouterProvider router={router} />
            {socket?<Notice />:null} 
          </div>
        </ConfigProvider>
        <RecordReplayComponent />
      </MyContext.Provider>
    </div>
  );
}

export default Sentry.withProfiler(App);
