import React, { useContext, useEffect,useState } from 'react';
import { Tabs,Dropdown } from 'antd';
import type { TabsProps,MenuProps } from 'antd';
import { DownOutlined, LockFilled } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';

import FunctionFlash from './function-flash'
import FunctionReset from './function-reset'
import FunctionTools from './function-tools'




const Element  = (props: any) => {
  const { 
    retLang,
    defaultBrand,
    toolExistence,
    flashExistence,
    flash,
    flashType,setFlashType,
    setFlashKey,
    menuCheckId,
    flashLock,setFlashLock,
    operateName,setOperateName,
    operateLoading,
    flashName,setFlashName,
    availability,
    translateFunItem,
    activeKey, setActiveKey,
  } = useContext(MyContext);
  const [flashOpen, setFlashOpen] = useState<any>(false);
  const [toolsName, setToolsName] = useState<any>("");
  const selectFlash = (item:any) => {
    setFlashType(item.label);
    setFlashKey(item.type);
    setFlashLock(item.permission);
    setFlashName(`${item.function_key}${item.function_id}`);
    setFlashOpen(false);
  }
  const handleOpenChange = (open:any) => {
    setFlashOpen(open);
  }
  useEffect(() => {
    // 全部转换成小写
    let brand = defaultBrand?defaultBrand.toLowerCase():"";
      if(brand.includes("adb")){
          setToolsName("ADB");
      }else if(brand.includes("mtp")){
          setToolsName("MTP");
      }else{
          setToolsName("");
      }
  },[defaultBrand])
  
  const items: TabsProps['items'] = [
    flashExistence && {
      key: '1',
      label: <div>
        <Dropdown
          disabled={operateLoading}
          open={flashOpen}
          onOpenChange={handleOpenChange}
          trigger={['click']}
          getPopupContainer={() => document.getElementById('shortcut') as HTMLElement}
          placement='bottomRight'
          dropdownRender={
            menu => (
              <div className='flashMenu'>
                {
                  flash && flash.map((item:any,index:number) => {
                    return <div key={index} 
                    className={`flashItem ${item.label === flashType?'active':''} ${item.permission?'':'disabled'}`}
                    onClick={() => selectFlash(item)}>
                      {/* {flashLock === 0 && availability===0 ? <LockFilled /> : null} */}
                      {translateFunItem(`${item.function_key}${item.function_id}`)}
                    </div>
                  })
                }
              </div>
            )
          }
        >
          <div>
            <div style={{display:"inline-block"}}><span style={{fontSize:"14px"}}>{translateFunItem(flashName)}</span></div>
            <span className='fr'><DownOutlined style={{margin:0}} /></span>
          </div>
        </Dropdown>
      </div>,
      disabled: !flashExistence,
      children: (
        <FunctionFlash></FunctionFlash>
      )
    },
    {
      key: '2',
      label: menuCheckId===6?`${toolsName} ${retLang('Function')}`: retLang("Reset"),
      children: (
        <FunctionReset></FunctionReset>
      ),
    },
    toolExistence && {
      key: '3',
      label: retLang("Tools"),
      disabled: !toolExistence,
      children: (
        <FunctionTools></FunctionTools>
      ),
    },
  ];
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  useEffect(() => {
    if(!flashExistence && activeKey === "1"){
      setActiveKey("2");
    }
  },[flashExistence])

  useEffect(() => {
    if(!toolExistence && activeKey === "3"){
      setActiveKey("2");
    }
  },[toolExistence])
  return (
    <div className='unlock-function'>
      <div className="block-header">{retLang("Function")}</div>
      <div className="shortcut" id='shortcut'>
        <Tabs
          activeKey={activeKey}
          centered={true}
          onChange={onChange}
          animated={{inkBar: true, tabPane: false}}
          type="card"
          items={items}
        />
      </div>
    </div>
  )
}

export default Element;