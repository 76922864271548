import React, { useEffect,useContext, useRef,useState } from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import useCustomWebSocket from '@/utils/websocket';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import { MyContext } from '@/utils/myContext';
import axios from '@/utils/axios';
import FooterCom from "@/views/Component/footerMenu/footerMenu";
import GlobalModel from '@/views/Component/globalModel/globalModel';
import { CustomContextMenu } from '@/views/Component/contextMenu/contextMenu';
import './index.scss'
import operCode from "@/utils/opercode.json"
import LanguageFixed from '@/views/Component/languageFixed/languageFixed';
const opercode:any = operCode;
const { confirm } = Modal;

import countryCodes from '@/utils/countryCodes.json';

const Element  = (props: any) => {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    let {
        socket,
        connectSocketio,
        setAvailability,
        retLang,
        setIsModalOpen,
    } = useContext(MyContext);
    const pathName = useLocation();
    const {
        receivedMessages,
        sendJsonMessage,
        readyState
    } = useCustomWebSocket();
    const [isModalUpdata, setIsModalUpdata] = useState(false);
    const [isUpdate,setIsUpdate] = useState(false);
    const progressRef = useRef(null);
    const numberRef = useRef(null);
    const installRef = useRef(null);

    const handleClose = () => {
        setIsModalUpdata(false);
    };
    const fulterCountry = (country:any) => {
        countryCodes.map((item:any) => {
            if(item.code === country){
                return item.name;
            }
        })
    }
    useEffect (() => {
        const result:any =  { ...receivedMessages };
        const tag:any = progressRef.current;
        const numtag:any = numberRef.current; 
        const installtag:any = installRef.current;
        if(result.retcode === 103 && result.progress && result.opercode === 0){
            try{
                tag.style.width = result.progress + '%';
                numtag.innerText = result.progress;
            }catch(e){
                
            }
        }
        if( result.retcode === 801 ){
            setIsModalUpdata(true);
        }else if( result.retcode === 802 ){
            try{
                installtag.innerText = result.operate_info;
            } catch(e){

            }
        }
        if(result.accessToken){
            if(pathName.pathname.includes('/login')){
                localStorage.setItem("accountData",JSON.stringify(result));
                axios.post('/client/new/userinfo/getMerchantInfo').then((res:any) => {
                    if(res.data.code === 200){
                        navigate('/dashboard/deviceConnection');
                        socket.disconnect();
                        connectSocketio();
                    }
                })
            }
        }
    },[receivedMessages]);
    useEffect (() => {
        if(socket){
            socket.on("message_event", (msg:any) => {
                try{
                    const messageData:any = JSON.parse(msg);
                    if(messageData.code === 200) {
                        if(messageData.data.type && messageData.data.type === 8003){
                            let countdata:any = messageData.data || {};
                            let countdata2:any = countdata.data || {};
                            setAvailability(countdata2.count);
                        }
                        if(messageData.data.type && messageData.data.type === 8005){
                            let countdata:any = messageData.data || {};
                            let countdata2:any = countdata.data || {};
                            api.warning({
                                message: <div>
                                    <div>{retLang('Youraccountonanotherdevice')}</div>
                                    <div>{retLang('loginaddress')} : {fulterCountry(countdata2.country)} {countdata2.city}</div>
                                </div>,
                            })
                            sendJsonMessage({"operate":"STOP","opercode":opercode.stop});
                            localStorage.removeItem('accountData');
                            setIsModalOpen(false);
                            socket.disconnect();
                            connectSocketio();
                            navigate('/login/loginType');
                        }
                    }
                }catch(e){}
            });
        }
    },[socket]);
    useEffect (() => {
        if(readyState === 1){
            if(pathName.pathname !== '/login/loginType' && isUpdate === true){
                navigate('/login/loginType');
                setIsUpdate(false);
            }
            
            handleClose();
        }
    },[readyState]);
    return (
        <div>
            {contextHolder}
            <Outlet />
            <FooterCom />
            <CustomContextMenu />
            <LanguageFixed />
            <Modal title="Update"
            closeIcon={<CloseCircleOutlined />}
            open={isModalUpdata}
            onCancel={handleClose}
            closable={false}
            maskClosable={false}
            footer={null}
            centered={true}
            >
                <div className="installPro">
                    <div className='progress-box'>
                        <div className="progress">
                            <div className="progress-bar" ref={progressRef} style={{width:'0%'}}></div>
                        </div>
                        <div className="progress-num"><span ref={numberRef}>0</span> %</div>
                    </div>
                    <div className="installProTitle" ref={installRef}>Installing...</div>
                </div>
            </Modal>
            <GlobalModel />
        </div>
    )
}

export default Element;