import React, { useCallback, useEffect, useState,useContext } from 'react';
import { Button, Tooltip, Image  } from 'antd';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { MyContext } from '@/utils/myContext';

import importIco from "@/assets/icon/button_import.svg";
import refreshIco from "@/assets/icon/button_list_refresh.svg";
import deleteIco from "@/assets/icon/button_delete.svg";
import exportIco from "@/assets/icon/button_export.svg";
import notselectedIco from "@/assets/icon/button_media_notselected.svg";
import selectedIco from "@/assets/icon/button_media_selected.svg";
import Logo from "@/assets/logo_128.png";
// import demo from "@/assets/demo2.jpg";

import "./picManage.scss"

const opercode:any = operCode;

const Element  = (props: any) => {
    let {
        retLang
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    type picListyee = {
        isSelected: boolean;
        FileName: string;
        filePath: string;
        permissions: string;
        DataModeified: string;
        FileType: string;
        FileSize: string;
    } 
    const [loadIndex,setLoadIndex] = useState<number>(0);
    const [picList,setPicList] = useState<picListyee[]>([
        // {filePath:"",isSelected:false,FileName:"IMG20220911210535.jpg",permissions:"-rwxrwx---", DataModeified:"2022-09-11 21:05",FileType:".jpg",FileSize:"3M890K"},
    ])
    useEffect (() => {
        sendJsonMessage({"operate":"LoadResource","devicePath":"/sdcard/DCIM/Camera","opercode":opercode.LoadResource_Camera});
    },[]);
    useEffect(() => {
        let result:any = { ...receivedMessages };
        if(result.opercode === opercode.LoadResource_Camera && result.resource){
            let arr = [...result.resource];
            for(var i=0; i<arr.length; i++){
                arr[i].isSelected = false;
                arr[i].filePath = "";
            }
            setPicList([...arr]);
            if(arr.length > 0){
                sendJsonMessage({"operate":"load_photos","file":arr[loadIndex].FileName,"removepath":"/sdcard/DCIM/Camera","opercode":opercode.load_photos});
            }
        }
        if(result.opercode === opercode.load_photos && result.log){
            let arr = [...picList];
            let path = result.log.split("\\")[result.log.split("\\").length-1];
            arr[loadIndex].filePath = path;
            setPicList([...arr]);
            setLoadIndex((pre:any) => {
                if(pre+1 < picList.length){
                    sendJsonMessage({"operate":"load_photos","file":picList[pre+1].FileName,"removepath":"/sdcard/DCIM/Camera","opercode":opercode.load_photos});
                }
                return pre+1;
            });
            
        }
    },[receivedMessages])
    
    
    const importPic = () => {
        
    }
    const refresh = () => {
        sendJsonMessage({"operate":"LoadResource","devicePath":"/sdcard/DCIM/Camera","opercode":opercode.LoadResource_Camera});
    }

    const picItem:any = picList.map((item:any,index:any) => {
        return(
          <div className={`pic-item ${item.isSelected?'pic-item-active':''}`} key={index}>
            <div className="select_ico" onClick={ () => {
                item.isSelected = !item.isSelected;
                const newList = picList;
                setPicList([...newList])
            } }> 
                <img src={item.isSelected?selectedIco:notselectedIco} alt="" />
            </div>
            <div className="image-show">
                <Image src={`http://127.0.0.1:8890/?commond=loadimagefile&file=${item.filePath}`} fallback={Logo} alt="" />
            </div>
            <div className='fileName'>
                <Tooltip placement="topLeft" title={item.FileName}>{item.FileName}</Tooltip>
            </div>
          </div>
        )
    });
    return (
        <div className="fileView">
            <div className="opertion">
                {/* <Button type="primary" size='small' onClick={importPic}><img src={importIco} alt="" /> 导入</Button>
                <Button type="primary" size='small'><img src={deleteIco} alt="" /> 删除</Button> */}
                <Button type="primary" size='small' onClick={refresh}><img src={refreshIco} alt="" />{retLang('refresh')}</Button>
            </div>
            <div className="picList">
                <Image.PreviewGroup
                    preview={{}}
                >
                    {picItem}
                </Image.PreviewGroup>
            </div>
        </div>
    )
}
export default Element;