import React, { useEffect,useRef } from 'react';

import useCustomWebSocket from '@/utils/websocket';


const Element  = React.memo( (props: any) => {
    const {receivedMessages} = useCustomWebSocket();
    const progressRef = useRef(null);
    const numberRef = useRef(null);
    useEffect(() => {
        const tag:any = progressRef.current;
        const numtag:any = numberRef.current; 
        let result:any = { ...receivedMessages };
        if(result.retcode === 103 && result.progress && result.opercode !== 0){
            numtag.innerText = result.progress;
            tag.style.width = result.progress + '%';
        }
    },[receivedMessages])
    return (
        <div className='progress-box'>
            <div className="progress">
                <div className="progress-bar" ref={progressRef} style={{width:'0%'}}></div>
            </div>
            <div className="progress-num"><span ref={numberRef}>0</span> %</div>
        </div>
    )
})
export default Element;