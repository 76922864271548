import React, { useState,useContext, useEffect } from 'react';
import { MyContext } from '@/utils/myContext';
import { Divider, Segmented,Button, Modal,notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons'
import axios from '@/utils/axios';
// 路由跳转
import { useNavigate } from 'react-router-dom';

import Paypal from './paypal'
const Element  = (props: any) => {
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();
    const [api, contextApiHolder] = notification.useNotification();
    const { 
        retLang,
        isShowPurchase,setIsShowPurchase,
        setPurchaseStatus,
        domain,
        payInfo, setPayInfo,
        availableForFree,
        setIsWaitAudited
    } = useContext(MyContext);
    const [pack, setPack] = useState<any>(2);
    const [packList, setPackList] = useState<any>([]);
    const [packDataList, setPackDataList] = useState<any>([]);
    const [checkPack, setCheckPack] = useState<any>(null);
    const [packPrice, setPackPrice] = useState<any>(null);
    const [payList, setPayList] = useState<any>([]);

    const [payType, setPayType] = useState<any>(1);
    useEffect(() => {
        if(isShowPurchase){
            getPriceList();
            getPayList();
        }
    },[isShowPurchase])
    const getPriceList = () => {
        axios.post('/client/new/userinfo/userGoodsList').then((res:any) => {
            if(res.data.code === 200){
                let list = res.data.data;
                let arr = [];
                setPackDataList(list);
                for(let i in list){
                    arr.push({
                        label:list[i].goodsName,
                        value:list[i].id
                    });
                }
                setPackList(arr);
                setCheckPack(list[1].id);
                setPackPrice(list[1]);
            }
        })
    }
    const getPayList = () => {
        axios.get('/client/new/order/getEnabledPayTypeList').then((res:any) => {
            if(res.data.code === 200){
                let list = res.data.data;
                if(list.length>0){
                    setPayList(list);
                }
            }
        })
    }
    const selectPack = (index:any) => {
        if(index === 1) return;
        setPack(index);
    }
    const selectGoods = (value:any) => {
        setCheckPack(value);
        let data = packDataList.filter((item:any) => item.id === value);
        setPackPrice(data[0]);
    }
    const purchasePlan = () => {
        const payData = {
            goodsId:packPrice.id,
            payAmt:packPrice.disAmtTotalPrice,
            orderAmt:packPrice.saleAmtTotalPrice,
            goodsName:packPrice.goodsName,
            payType:payType,
            backUrl:window.location.origin
        }
        axios.post('/client/new/order/userRecharge',payData).then((res:any) => {
            if(res.data.code === 200){
                if(res.data.data.rpsCode === "200"){
                    setPayInfo(res.data.data);
                    localStorage.setItem('payInfo',JSON.stringify(res.data.data));
                    // setIsShowPurchase(false);
                    if(res.data.data.payType === 2){
                        modal.info({
                            title: retLang('placeClickPaypal'),
                            icon: null,
                            centered: true,
                            okText: 'Close',
                            maskClosable: true,
                            content: (
                                <div style={{marginRight:"-34px",marginTop:"24px"}}>
                                    <Paypal resData={res.data.data}></Paypal>
                                </div>
                            )
                        })
                    }else if( res.data.data.payType === 5 || res.data.data.payType === 6 ){
                        navigate('/wait?o='+res.data.data.orderNo);
                    }else{
                        window.open(res.data.data.redirectUrl);
                    }
                }else{
                    api.error({
                        message: res.data.data.rpsMsg,
                        description: '',
                        duration: 2,
                        placement:'topRight'
                    });
                }
            }
        })
    }
    const payTypeList = payList.map((item:any,index:any) => {
        return (
            <div className={`payTypeItem ${item===payType?'active':''}`} onClick={()=>setPayType(item)} key={index}>
                {
                    item === 1 ? 
                    <div className='payTypeItem_div'>
                        <div className="payTypeItem_l">
                            <span className='payLabel'>Credit Card</span>
                            <span className="payIconlist">
                                <img src={require('@/assets/payimg/visa.png')} alt=""/>
                                <img src={require('@/assets/payimg/mastercard.png')} alt=""/>
                                <img src={require('@/assets/payimg/visaelectron.png')} alt=""/>
                                <img src={require('@/assets/payimg/amex.png')} alt=""/>
                                <img src={require('@/assets/payimg/dinersclub.png')} alt=""/>
                                <img src={require('@/assets/payimg/discover.png')} alt=""/>
                                <img src={require('@/assets/payimg/jcb.png')} alt=""/>
                                <img src={require('@/assets/payimg/unionpay.png')} alt=""/>
                            </span>
                        </div>
                    </div>:
                    item === 2 ? <div className='payTypeItem_div'>
                        <div className="payTypeItem_l">
                            <span className='payLabel'>PayPal</span>
                            <span className="payIconlist">
                                <img src={require('@/assets/payimg/paypal.png')} alt=""/>
                            </span>
                        </div>
                    </div>:
                    item === 3 ? <div className='payTypeItem_div'>
                        <div className="payTypeItem_l">
                            <span className='payLabel'>USDT / USDC</span>
                            <span className="payIconlist usdtImg">
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/polygon.png')} alt=""/>
                                </div>
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/usd-coin-usdc-logo.png')} alt=""/>
                                </div>
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/tether-usdt-logo.png')} alt=""/>
                                </div>
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/binance-usd-busd-logo.png')} alt=""/>
                                </div>
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/trueusd-tusd-logo.png')} alt=""/>
                                </div>
                                <div className="usdtIcon">
                                    <img src={require('@/assets/payimg/coin-bitcoin.png')} alt=""/>
                                </div>
                            </span>
                        </div>
                    </div>:
                    item === 5 ? <div className='payTypeItem_div'>
                        <div className="payTypeItem_l">
                            <span className='payLabel'>WECHAT</span>
                            <span className="payIconlist">
                                <img className='wechatpayImg' src={require('@/assets/payimg/wechatpay.png')} alt=""/>
                            </span>
                        </div>
                    </div>:
                    item === 6 ? <div className='payTypeItem_div'>
                        <div className="payTypeItem_l">
                            <span className='payLabel'>Alipay</span>
                            <span className="payIconlist">
                                <img src={require('@/assets/payimg/alipay.png')} alt=""/>
                            </span>
                        </div>
                    </div>:null
                }
                <div className="selectCheck"><CheckCircleFilled /></div>
            </div>
        )
    });
    
    return (
        <div className='selectPlan'>
            <div className="productRemark">
                <p>{retLang('productRemark')}</p>
                <p>{retLang('productRemark2')}</p>
            </div>
            <div className="setp">
                <div className="setpNum">SETP 1</div>
                <span>{retLang('setp1Remark')}:</span>
            </div>
            <div>
                {
                    <div className='productPrice'>
                        <div className={`priceItem ${pack===1?'active':''}`} onClick={() => selectPack(1)}>
                            <div className="plan-box" style={{cursor:availableForFree?"pointer":"no-drop"}}>
                                {/* Trial 3 times */}
                                <div className="save_remark">{retLang('Free')}</div>
                                <div className="plan-name">{retLang('Free')} {availableForFree&&"15 Day"} {retLang('Plan')}</div>
                                {
                                    availableForFree?null:
                                    <div className="thirty-day">{retLang('Original')} $ 0.00 USD {retLang('permonth')}</div>
                                }
                                <div className="cross-monthly-amount"><span> ${availableForFree?"10.00":"0.00"}</span></div>
                                <div className="fenge"></div>
                                <div className="plan-amount">
                                    <div className="price">
                                        <div className="sup">$ </div>
                                        <div className="monthly-amount"> 0 <span style={{fontSize:'20px'}}>USD</span></div>
                                    </div>
                                    <div className="per-month">$ {availableForFree?"20.00":"0.00"} {retLang('permonth')}</div>
                                </div>
                                <div className="good_remark">Evo Tools {retLang('FreeLicense')}</div>
                                {
                                    availableForFree && <div>
                                        <Button onClick={()=>setIsWaitAudited(1)} className='blueButton'>{retLang('Getitnow')}</Button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`priceItem ${pack===2?'active':''}`} onClick={() => selectPack(2)}>
                            <div className="plan-box">
                                <div className="save_remark">{retLang('Save')} {packPrice?.percentAmt}%</div>
                                <div className="plan-name">
                                    <Segmented value={checkPack} onChange={selectGoods} size="large" options={packList} />
                                </div>
                                {/* <div className="plan-name">12 Month</div> */}
                                <div className="thirty-day">{retLang('Original')} $ {packPrice?.saleAmt} USD {retLang('permonth')}</div>
                                <div className="cross-monthly-amount"><span> ${packPrice?.saleAmtTotalPrice}</span></div>
                                <div className="fenge"></div>
                                <div className="plan-amount">
                                    <div className="price">
                                        <div className="sup">$ </div>
                                        <div className="monthly-amount"> {packPrice?.disAmtTotalPrice} <span style={{fontSize:'20px'}}>USD</span></div>
                                    </div>
                                    <div className="per-month">$ {packPrice?.disAmt} {retLang('permonth')}</div>
                                </div>
                                <div className="good_remark">Evo tool {packPrice?.goodsName} {retLang('License')}</div>
                            </div>
                        </div>
                    </div>
                }
                
            </div>
            <div className="setp">
                <div className="setpNum">SETP 2</div>
                <span>{retLang('setp2Remark')}:</span>
            </div>
            <div className="selectPayType">
                {payTypeList}
            </div>
            <div className="joinNow" style={{textAlign:"right"}}>
                <Button size='large' onClick={purchasePlan}>{retLang('JoinNow')}</Button>
            </div>
            {/* <Divider style={{color:"#fff",borderColor:"#24262C"}} orientation="left" plain>
                OR
            </Divider>
            <div className="connectReseller">
                <a target='_blank' href={`https://www.${domain}/reseller`}>{retLang('Contactresellerpurchase')}</a>
            </div> */}
            <p className='refund'>{retLang('refundRemark')}</p>
            {contextHolder}
            {contextApiHolder}
        </div>
    )
}

export default Element;