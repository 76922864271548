import React, { useContext, useEffect } from 'react';
import { Divider,Button } from 'antd';
import { MyContext } from '@/utils/myContext';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"

import AfetyCom from './afetyCom'

const opercode:any = operCode;
const Element  = (props: any) => {
    let {
        retLang,
        sdkVersion,
        domain
    } = useContext(MyContext);
    const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
    const [loading,setLoading] = React.useState(false);
    const [updateRemark,setUpdateRemark] = React.useState('');
    const checkUpdate = () => {
        setLoading(true);
        setTimeout(() => {
            sendJsonMessage({"operate":"CheckSoftwareUpgrade","opercode":opercode.CheckSoftwareUpgrade});
        }, 2000);
    }

    useEffect (() => {
        let result:any =  { ...receivedMessages };
        if(result.opercode === opercode.CheckSoftwareUpgrade){
            setLoading(false);
            setUpdateRemark(result.operate_info);
        }
    }, [receivedMessages]);
    const seeAgree = () => {
        window.open(`https://www.${domain}/release-notes`);
    }
    return (
        <div className='help_box'>
            <div className="setting_list">
                <div className="setting_title">{retLang('AboutSupport')}</div>
                <Divider />
                <div className="help_item">
                    <div className="help_item_title">{retLang('Version')}</div>
                    <div className="help_item_content">
                        {/* <div className="version_item">
                            <div className="version_item_l">{retLang('EvondtVersion')}</div>
                            <div className="version_item_r">v {clientVersion}</div>
                        </div> */}
                        <div className="version_item">
                            <div className="version_item_l">{retLang('CoreVersion')}</div>
                            <div className="version_item_r">v {sdkVersion || '--'}</div>
                        </div>
                        <div className="content_item_btn" onClick={seeAgree}> <span>{retLang('Learnmore')}</span> </div>
                        <div className="text-right">
                            <span style={{marginRight:"10px"}}>{updateRemark}</span>
                            <Button loading={loading} onClick={checkUpdate}> {retLang('Checkupdate')} </Button>
                        </div>
                        
                    </div>
                </div>
                <AfetyCom />
            </div>
        </div>
    )
}

export default Element;