import React, { useContext, useEffect,useState } from 'react';
import { Button,Result,QRCode,Statistic,Alert } from 'antd';
import { LoadingOutlined,ReloadOutlined } from '@ant-design/icons';
import { MyContext } from '@/utils/myContext';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from '@/utils/axios';
import logo from '@/assets/logo_black.png';
import logoIco from '@/assets/logo_128.png';
import "./wait.scss"
const { Countdown } = Statistic;
const Element  = (props: any) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const orderNo = params.get('o');
    const [payInfo,setPayInfo] = useState(JSON.parse(localStorage.getItem('payInfo') || '{}'));
    const [payStatus, setPayStatus] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState<any>(payInfo.orderCloseTime?getTimeRemaining():null);
    const [qrStatus, setQrStatus] = useState<any>('active');
    let timer:any = null;
    const { 
        retLang,
    } = useContext(MyContext);
    const getPayStatus = () => {
        axios.get(`/client/new/order/getOrderStatus?orderNo=${orderNo}`).then((res:any) => {
            if(res.data.code === 200){
                setPayStatus(res.data.data);
                if(res.data.data === "SUCCESS" || res.data.data === "FAIL"){
                    clearInterval(timer);
                    setTimeout(() => {
                        goTools();
                    }, 5000);
                }
            }
        })
    }
    useEffect(() => {
        if(payInfo.orderCloseTime){
            setTimeRemaining(getTimeRemaining());
        }
    },[payInfo])
    const goTools = () => {
        navigate('/dashboard/index');
    }
    // 计算剩余时间的函数
    function getTimeRemaining() {
        const closeTime = new Date(payInfo.orderCloseTime).getTime();
        return closeTime;
    }
    useEffect(() => {
        timer = setInterval(() => {
            getPayStatus();
        }, 3000);
        return () => {
            clearInterval(timer);
        }
    },[])
    const orderClose = () => {
        clearInterval(timer);
        setQrStatus('expired');
    }
    const refreshOrder = () => {
        axios.post(`/client/new/order/reOrderCreate`,{
            orderNo:orderNo,
            backUrl:window.location.origin
        }).then((res:any) => {
            if(res.data.code === 200){
                setQrStatus('active');
                localStorage.setItem('payInfo',JSON.stringify(res.data.data));
                setPayInfo(res.data.data);
                navigate(`/wait?o=${res.data.data.orderNo}`);
            }
        })
    }
    const scanCodeNode = () => {
        if(payInfo.payType === 5){
            return <div className="payment_box wechatPayment">
                <img src={require('@/assets/payimg/wechatpay.png')} alt="" />
                <p>{retLang('WechattoscantheQRcode')}</p>
                <div className="goodSee">
                    <div className="gooItem"><span>{retLang('Email')}</span><b>{payInfo.recharegeAccount}</b></div>
                    <div className="gooItem"><span>{retLang('ProductName')}</span><b>{payInfo.goodsName}</b></div>
                    <div className="gooItem"><span>{retLang('Paymentamount')}</span><b>{ payInfo.currencyDesc } {payInfo.actualPayAmt}</b></div>
                </div>
                <div className="qrCode">
                    <QRCode
                        status={qrStatus}
                        icon={logoIco}
                        onRefresh={refreshOrder}
                        value={payInfo.redirectUrl} size={200} />
                </div>
                <div className="backTools qrCodeBack">
                    <Button type='text' onClick={goTools}>{retLang('Letmethinkagain')}</Button>
                </div>
            </div>
        }else if(payInfo.payType === 6){
            return <div className="payment_box aliPayment">
                <img src={require('@/assets/payimg/alipay.png')} alt="" />
                <p>{retLang('AlipaytoscantheQRcode')}</p>
                <div className="goodSee">
                    <div className="gooItem"><span>{retLang('Email')}</span><b>{payInfo.recharegeAccount}</b></div>
                    <div className="gooItem"><span>{retLang('ProductName')}</span><b>{payInfo.goodsName}</b></div>
                    <div className="gooItem"><span>{retLang('Paymentamount')}</span><b>{ payInfo.currencyDesc} {payInfo.actualPayAmt}</b></div>
                </div>
                <div className="qrCode">
                    <iframe src={payInfo.redirectUrl} style={{border:"none"}} width="205px" height="205px"></iframe>
                    {
                        qrStatus === 'expired' && 
                        <div className="qrExpired">
                            <p>{retLang('QRcodeexpired')}</p>
                            <Button onClick={refreshOrder} icon={<ReloadOutlined />} type='link'>{retLang('Clicktorefresh')}</Button>
                        </div>
                    }
                    
                </div>
                <div className="backTools qrCodeBack">
                    <Button type='text' onClick={goTools}>{retLang('Letmethinkagain')}</Button>
                </div>
            </div>
        }
    }
    return (
        <div className='sancodePayView'>
            <div className="sancodeBox">
                {
                    payInfo.orderCloseTime&&
                    <Alert 
                        message={<div
                            style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                            > 
                            <span>{retLang('Remainingtimeexpires')}</span>
                            <Countdown onFinish={orderClose} value={timeRemaining} style={{marginLeft:"10px"}} />
                        </div>} 
                        style={{padding:"0",marginBottom:"15px"}} 
                        type="warning" />
                }
                {
                    payStatus === "SUCCESS" || payStatus === "FAIL"?
                    <div className="payment_box payResult">
                        <Alert 
                        message={retLang('Autoreturntool')} 
                        style={{marginBottom:"15px"}} 
                        type="warning" />
                        {
                            payStatus === "SUCCESS"?
                            <Result
                                status="success"
                                title={retLang('PaymentSuccessful')}
                                subTitle={retLang('successfuljumping')}
                            />:
                            <Result
                                status="error"
                                title={retLang('PaymentFailed')}
                                subTitle={retLang('failedjumping')}
                            />
                        }
                        <div className="backTools">
                            <Button size='large' onClick={goTools}>{retLang('BacktoTools')}</Button>
                        </div>
                    </div>:
                    <div>
                        {
                            (payInfo.payType === 5 || payInfo.payType === 6) ? 
                            <div>
                                {scanCodeNode()}
                            </div>:
                            <div className="payment_box payWaiting">
                                <img className='codeLogo' src={logo} alt="" />
                                <h2>{retLang('Orderinquiryprogress')}</h2>
                                <p>{retLang('pleasewaitpatiently')}</p>
                                <div className="loadingIcon">
                                    <LoadingOutlined />
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* <div className="payment_box wechatPayment"></div>
                <div className="payment_box aliPayment"></div>
                <div className="payment_box payWaiting"></div>
                <div className="payment_box payResult"></div> */}
                {/* <div className="backTools">
                    <Button size='large'>Back to Tools</Button>
                </div> */}
            </div>
        </div>
    )
}

export default Element;