import React, { useState, useEffect, useRef, useContext } from 'react';
import { MyContext } from '@/utils/myContext';
import useCustomWebSocket from '@/utils/websocket';
import operCode from "@/utils/opercode.json"
import { useNavigate, useLocation } from 'react-router-dom';

import './contextMenu.scss'

const opercode:any = operCode;
export function CustomContextMenu() {
  const navigate = useNavigate();
  const pathName = useLocation();
  const {sendJsonMessage,receivedMessages} = useCustomWebSocket();
  let { retLang,isModalOpen, setIsModalOpen, connectCode, languageJson } = useContext(MyContext);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [chipStatus, setChipStatus] = useState(false);
  const menuRef = useRef<any>(null);
  const [calculatingPosition, setCalculatingPosition] = useState(false);

  const [topleft,setTopleft] = useState<any>([]);

  useEffect(() => {
    let result:any = { ...receivedMessages };
    // if(result.opercode === opercode.selectDevice && result.topleft){
    //     setTopleft(result.topleft);
    // }
  },[receivedMessages])

  useEffect(() => {
    if (connectCode === "Open") {
      setTopleft([
        {"label":"Open_devmgmt","Values":"1","name":retLang('DeviceManager')},
        {"label":"Open_Taskmgr","Values":"1","name":retLang('TaskManager')},
        {"label":"Open_commond","Values":"1","name":retLang('CommandPrompt')},
        // {"label":"TestPoint","Values":"1","name":"短接图"}
      ]);
    } else {
      setTopleft([]);
    }
  },[connectCode,languageJson])

  const menuItems = [
    { label: retLang('Clearcache'), onClick: () => {
      localStorage.clear();
      sessionStorage.clear();
      setShowMenu(false);
    }},
    { label: retLang('refresh'), onClick: () => {setShowMenu(false);location.reload();} },
    { label: topleft.length===0?"no":"line" }, 
    ...topleft.map((item:any,index:any) => {
      return(
        { label: item.name || item.label, onClick: () => deviceManager(item.label) }
      )
    }),
    // { label: pathName.pathname.includes('dashboard')?retLang('TestPoint'):"no", onClick: () => {navigate("/dashboard/test-point");setShowMenu(false)} },
    { label: "line" },
    { label: retLang('Screenshottoclipboard'), onClick: () => {setShowMenu(false);setChipStatus(true)}},
    { label: pathName.pathname.includes('dashboard')?retLang('Settings'):"no", onClick: () =>  {setIsModalOpen(true);setShowMenu(false);}},
    // ... 更多菜单项
  ];

  useEffect(() => {
    if (chipStatus) {
      deviceManager('takeScreen');
      setChipStatus(false);
    }
  }, [chipStatus]);

  useEffect(() => {
    const handleContextMenu = (event:any) => {
      const target = event.target;
      if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
        return;
      } else if (window.getSelection()?.toString()) {
        return;
      }
      event.preventDefault();

      setMenuPosition({
        top: event.clientY,
        left: event.clientX
      });
      setShowMenu(true);
      setCalculatingPosition(true);
    };

    const handleClick = (event:any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('click', handleClick);
    };
  }, []);
  useEffect(() => {
    if (calculatingPosition) {
      const menuHeight = menuRef.current?.offsetHeight || 0;
      const menuWidth = menuRef.current?.offsetWidth || 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
  
      let topPosition = menuPosition.top;
      let leftPosition = menuPosition.left;
  
      // 检查菜单是否超出了窗口的底部
      if (menuPosition.top + menuHeight > windowHeight) {
        topPosition = menuPosition.top - menuHeight;
      }

      // 检查菜单是否超出了窗口的右边
      if (menuPosition.left + menuWidth > windowWidth) {
        leftPosition = menuPosition.left - menuWidth;
      }
  
      setMenuPosition({
        top: topPosition,
        left: leftPosition
      });
  
      setCalculatingPosition(false);  // 重置标志
    }
  }, [calculatingPosition]);

  const deviceManager = (label:any) => {
    sendJsonMessage({"operate":label,"opercode":opercode.Open_topleft})
    setShowMenu(false);
  }

  return showMenu ? (
    <div
        className='contextmenu'
      ref={menuRef}
      style={{
        position: 'absolute',
        top: `${menuPosition.top}px`,
        left: `${menuPosition.left}px`,
        zIndex: 9999
      }}
    >
      {menuItems.map((item:any, index:any) => {
        if(item.label === "line"){
          return <div key={index} style={{height:'1px',backgroundColor:'#3C4043',margin:"5px 0"}}></div>
        }else if(item.label === "no"){
          return null;
        } else{
          return <div className='menu_item' key={index} onClick={item.onClick}>
            {item.label}
          </div>
        }
      })}
    </div>
  ) : null;
}

// 使用示例
// function App() {
//   return (
//     <div style={{ position: 'relative', height: '100vh' }}>
//       <CustomContextMenu />
      
//       {/* 你的其他页面内容 */}
//       <div>
//         <h1>Welcome to My Page</h1>
//         <p>Right-click anywhere on this page to see the custom context menu.</p>
//       </div>
//     </div>
//   );
// }
